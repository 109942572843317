import AppSettings from '@app-settings'
import { SECTION_TAB_TYPES } from "../config"

const { REACT_APP_PERMISSIONS_URL } = AppSettings

export const addMonths = (date, months) => {
  const newDate = new Date(date)
  newDate.setMonth(newDate.getMonth() + months)
  return newDate
}

export const subMonths = (date, months) => addMonths(date, -months)

export const addYears = (date, years) => {
  const newDate = new Date(date)
  newDate.setFullYear(newDate.getFullYear() + years)
  return newDate
}

export const subYears = (date, years) => addYears(date, -years)

const handleResetPageHistory = (history) => {
  history.replace({
    state: {
      hasNoBackHistory: history.location.state?.hasNoBackHistory
    }
  })
}

export const getInitialActiveSection = ({ history }) => {
  const activeSectionTabId = SECTION_TAB_TYPES.MY_SITES
  if (activeSectionTabId !== SECTION_TAB_TYPES.MY_SITES) {
    handleResetPageHistory(history)
  }

  return activeSectionTabId
}

export const getBuilderHomepagePermissions = (userData) => {
  const permissions = userData.appPermissions || userData[REACT_APP_PERMISSIONS_URL] || []
  return {
  hasBuilderHomepagePermission: permissions.includes('BuilderHomepage_Page'),
  hasMySitesPermission: permissions.includes('BuilderHomepage_MySites'),
  hasMyInspectionsPermission: permissions.includes('BuilderHomepage_MyInspections'),
  hasMyRequestPermission: permissions.includes('BuilderHomepage_MyRequests'),
  hasMyActionsPermission: permissions.includes('BuilderHomepage_MyActions'),
  hasMyReportsPermission: permissions.includes('BuilderHomepage_MyReports'),
  hasUnitRequestInspectionPermission: permissions.includes('BuilderHomepage_RequestInspection_Unit'),
  hasMyRequestsRequestInspectionPermission: permissions.includes('BuilderHomepage_RequestInspection_MyRequests'),
  hasMyInspectionsRequestInspectionPermission: permissions.includes('BuilderHomepage_RequestInspection_MyInspections')
}
}