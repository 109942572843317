import { LANGUAGE_CONSTANTS } from "@shared/language-constants"

const { TABLE } = LANGUAGE_CONSTANTS

export const ROW_ACTION_TYPES = {
  EDIT: "edit",
  VIEW: "view",
  CLONE: "clone",
  DELETE: "delete",
  EXPORT_DOCX: "docx",
  EXPORT_PDF: "pdf",
  CANCEL: "cancel",
  NEW_VERSION: "new_version"
}

export const defaultActionTypes = [
  ROW_ACTION_TYPES.VIEW,
  ROW_ACTION_TYPES.EDIT,
  ROW_ACTION_TYPES.DELETE
]

export const rowActionItems = [
  {
    id: ROW_ACTION_TYPES.VIEW,
    labelLocaleId: TABLE.VIEW
  },
  {
    id: ROW_ACTION_TYPES.EDIT,
    labelLocaleId: TABLE.EDIT
  },
  {
    id: ROW_ACTION_TYPES.CLONE,
    labelLocaleId: TABLE.CLONE
  },
  {
    id: ROW_ACTION_TYPES.NEW_VERSION,
    labelLocaleId: TABLE.NEW_VERSION
  },
  {
    id: ROW_ACTION_TYPES.DELETE,
    labelLocaleId: TABLE.DELETE
  },
  {
    id: ROW_ACTION_TYPES.EXPORT_DOCX,
    labelLocaleId: TABLE.EXPORT_DOCX
  },
  {
    id: ROW_ACTION_TYPES.EXPORT_PDF,
    labelLocaleId: TABLE.EXPORT_PDF
  },
  {
    id: ROW_ACTION_TYPES.CANCEL,
    labelLocaleId: TABLE.CANCEL
  }
]
