import { getActionType } from '../action-type-generator'

export const GET_BREADSCRUMB_DETAIL_REQUEST = 'GET_BREADSCRUMB_DETAIL_REQUEST'
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST'
export const GET_NOTIFICATION = getActionType('GET_NOTIFICATION')
export const GET_WELCOME_NOTIFICATION = getActionType('GET_WELCOME_NOTIFICATION')
export const GET_SITE_WEATHER_DATA_REQUEST = "GET_SITE_WEATHER_DATA_REQUEST"
export const GET_QUICK_LINK_LIST_REQUEST = 'GET_QUICK_LINK_LIST_REQUEST'
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST'
export const CLEAR_ALL_NOTIFICATION_REQUEST = 'CLEAR_ALL_NOTIFICATION_REQUEST'
export const CLEAR_NOTIFICATION_REQUEST = 'CLEAR_NOTIFICATION_REQUEST'
export const GET_HOME_SITES_LIST_REQUEST = "GET_HOME_SITES_LIST_REQUEST"
export const CLEAR_SELECTED_NOTIFICATION_REQUEST = "CLEAR_SELECTED_NOTIFICATION_REQUEST"
export const GET_DUX_IMAGE_REQUEST = "GET_DUX_IMAGE_REQUEST"
export const GET_REGION_FILTER_LIST = 'GET_REGION_FILTER_LIST'
export const GET_SUB_REGION_FILTER_LIST = 'GET_SUB_REGION_FILTER_LIST'
export const GET_COMMON_TAGS_DDL_REQUEST = 'GET_COMMON_TAGS_DDL_REQUEST'
export const UPDATE_COMMON_TAGS_REQUEST = 'UPDATE_COMMON_TAGS_REQUEST'
export const GET_COMMON_TAGS_REQUEST = 'GET_COMMON_TAGS_REQUEST'
export const UPDATE_NOTE_HEADLINE_REQUEST = 'UPDATE_NOTE_HEADLINE_REQUEST'
export const GET_NOTE_HEADLINE_REQUEST = 'GET_NOTE_HEADLINE_REQUEST'
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST'
export const GET_CCCONTACT_LIST_REQUEST = 'GET_CCCONTACT_LIST_REQUEST'
export const UPSERT_CCCONTACTS_REQUEST = 'UPSERT_CCCONTACTS_REQUEST'
export const GET_EMAIL_ADDRESS_LIST_REQUEST = 'GET_EMAIL_ADDRESS_LIST_REQUEST'
export const GET_PHONE_NUMBER_LIST_REQUEST = 'GET_PHONE_NUMBER_LIST_REQUEST'

/****************************************************/
/** GET_BREADSCRUMB_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getBreadscrumbDetailRequest : To get breadscrumb detail
 *
 */
export const getBreadscrumbDetailRequest = (payload, callback) => {
  return {
    type: GET_BREADSCRUMB_DETAIL_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** GET_SITE_WEATHER_DATA_REQUEST **/
/****************************************************/

/**
 * @method getSiteWeatherDataRequest : To get site weather data
 *
 */
export const getSiteWeatherDataRequest = (payload, callback) => {
  return {
    type: GET_SITE_WEATHER_DATA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_QUICK_LINK_LIST_REQUEST **/
/****************************************************/

/**
 * @method getQuickLinkListRequest : To get quick links list
 *
 */
export const getQuickLinkListRequest = (payload, callback) => {
  return {
    type: GET_QUICK_LINK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_NOTIFICATION_REQUEST **/
/****************************************************/

/**
 * @method getNotificationRequest : To get notification request
 *
 */
export const getNotificationRequest = (payload) => {
  return {
    type: GET_NOTIFICATION.REQUEST,
    payload
  }
}

/**
 * @method getNotificationSuccess : To handle notification success
 *
 */
export const getNotificationSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION.SUCCESS,
    payload
  }
}

/**
 * @method getNotificationFailure : To handle notification failure
 *
 */
export const getNotificationFailure = (payload) => {
  return {
    type: GET_NOTIFICATION.FAILURE,
    payload
  }
}

/****************************************************/
/** GET_WELCOME_NOTIFICATION_REQUEST **/
/****************************************************/

export const getWelcomeNotificationRequest = (payload) => {
  return {
    type: GET_WELCOME_NOTIFICATION.REQUEST,
    payload
  }
}

/**
 * @method getWelcomeNotificationSuccess : To handle welcome notification success
 *
 */
export const getWelcomeNotificationSuccess = (payload) => {
  return {
    type: GET_WELCOME_NOTIFICATION.SUCCESS,
    payload
  }
}

/**
 * @method getWelcomeNotificationFailure : To handle welcome notification failure
 *
 */
export const getWelcomeNotificationFailure = (payload) => {
  return {
    type: GET_WELCOME_NOTIFICATION.FAILURE,
    payload
  }
}

/**
 * @method updateNotificationRequest : To update notification
 *
 */
export const updateNotificationRequest = (payload, callback) => {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CLEAR_ALL_NOTIFICATION_REQUEST **/
/****************************************************/

/**
 * @method clearAllNotificationRequest : To clear all notifications 
 *
 */
export const clearAllNotificationRequest = (callback) => {
  return {
    type: CLEAR_ALL_NOTIFICATION_REQUEST,
    callback
  }
}

/****************************************************/
/** CLEAR_NOTIFICATION_REQUEST **/
/****************************************************/

/**
 * @method clearNotificationRequest : To clear notification
 *
 */
export const clearNotificationRequest = (payload, callback) => {
  return {
    type: CLEAR_NOTIFICATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_HOME_SITES_LIST_REQUEST **/
/****************************************************/

/**
 * @method getHomeSitesListRequest : To get sites for home page
 *
 */
export const getHomeSitesListRequest = (payload, callback) => {
  return {
    type: GET_HOME_SITES_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CLEAR_SELECTED_NOTIFICATION_REQUEST **/
/****************************************************/

/**
 * @method clearSelectedNotificationRequest : To clear selected notification
 *
 */
export const clearSelectedNotificationRequest = (payload, callback) => {
  return {
    type: CLEAR_SELECTED_NOTIFICATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_DUX_IMAGE_REQUEST **/
/****************************************************/

/**
 * @method getDuxImageRequest : To import image using some external ref id from Dux
 *
 */
export const getDuxImageRequest = (payload, callback) => {
  return {
    type: GET_DUX_IMAGE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REGION_FILTER_LIST **/
/****************************************************/

/**
 * @method getRegionFilterListRequest :  To get region filter list
 *
 */
export const getRegionFilterListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REGION_FILTER_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SUB_REGION_FILTER_LIST **/
/****************************************************/

/**
 * @method getSubRegionFilterListRequest :  To get sub-region filter list
 *
 */
export const getSubRegionFilterListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SUB_REGION_FILTER_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_COMMON_TAGS_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCommonTagsDDLRequest : To Get Paginated common tags DDL for the sites, workflow and contact
 *
 */
export const getCommonTagsDDLRequest = (payload, callback) => {
  return {
    type: GET_COMMON_TAGS_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_COMMON_TAGS_REQUEST **/
/****************************************************/

/**
 * @method updateCommonTagRequest : To update common tags in site, workflow and contacts
 *
 */
export const updateCommonTagRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_COMMON_TAGS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_COMMON_TAGS_REQUEST **/
/****************************************************/

/**
 * @method getCommonTagsRequest : To get the details of the tags updated
 *
 */
export const getCommonTagsRequest = (payload, callback) => {
  return {
    type: GET_COMMON_TAGS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_NOTE_HEADLINE_REQUEST **/
/****************************************************/

/**
 * @method updateNoteHeadlineRequest : To update note headers for user notes
 *
 */
export const updateNoteHeadlineRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_NOTE_HEADLINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_NOTE_HEADLINE_REQUEST **/
/****************************************************/

/**
 * @method getNoteHeadlineRequest : To get note headers for user notes
 *
 */
export const getNoteHeadlineRequest = (payload, callback = () => { }) => {
  return {
    type: GET_NOTE_HEADLINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SEND_EMAIL_REQUEST **/
/****************************************************/

/**
 * @method sendEmailRequest : To send emails
 *
 */
export const sendEmailRequest = (payload, callback) => {
  return {
    type: SEND_EMAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CCCONTACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCCContactListRequest : To get list of cc contacts
 *
 */
export const getCCContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CCCONTACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPSERT_CCCONTACTS_REQUEST **/
/****************************************************/

/**
 * @method upsertCCContactsRequest : To save cc contacts
 *
 */
export const upsertCCContactsRequest = (payload, callback = () => {}) => {
  return {
    type: UPSERT_CCCONTACTS_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** GET_EMAIL_ADDRESS_LIST_REQUEST **/
/****************************************************/

/**
 * @method getEmailAddressListBySiteIdRequest :  To get email address list
 *
 */
export const getEmailAddressListBySiteIdRequest = (payload, callback = () => { }) => {
  return {
    type: GET_EMAIL_ADDRESS_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PHONE_NUMBER_LIST_REQUEST **/
/****************************************************/

/**
 * @method getPhoneNumberListBySiteIdRequest :  To get phone number list
 *
 */
export const getPhoneNumberListBySiteIdRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PHONE_NUMBER_LIST_REQUEST,
    payload,
    callback
  }
}
