import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import { Button } from "reactstrap"
import { useDispatch } from "react-redux"

import { TABLE_LIST_KEYS } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage, PurchaseOrderModal, MessageCard } from '@views/components'
import AdditionalPurchaseOrderList from '@views/components/purchase-order-modal/additional-purchase-order-list'
import { getFormattedPurchaseOrderDetails } from '@views/components/purchase-order-modal/helpers'

const { SCHEDULING, OVERVIEW_PAGE_SECTIONS } = LANGUAGE_CONSTANTS

const ExpandableContractsOrderDetailsList = ({ data }) => {

  const dispatch = useDispatch()
  const [isOpenPurchaseOrderDetailsModal, setIsOpenPurchaseOrderDetailsModal] = useState(false)
  const [purchaseOrderList, setPurchaseOrderList] = useState([])
  const [primaryPurchaseOrderNumber] = useState(data.primaryPurchaseOrderNumber)
  const [orderDetailId, setOrderDetailId] = useState("")
  const [isEditMode, setEditMode] = useState(false)

  const handleTogglePurchaseOrderDetailsModal = useCallback((isEdit = false) => {
    setEditMode(false)
    if (isEdit) {
      setEditMode(true)
    }
    setIsOpenPurchaseOrderDetailsModal(prev => !prev)
    setOrderDetailId("")
  }, [])

  const handleRefreshOrderDetailsPurchaseOrderList = useCallback(() => {
    dispatch(
      actions.refreshTableList({
        listKey: TABLE_LIST_KEYS.SCHEDULING_CONTRACT_ORDER_DETAILS_LIST_KEY,
        value: true
      }))
  }, [])

  const handleFetchAdditionalPurchaseOrderList = useCallback(() => {
    const pOList = getFormattedPurchaseOrderDetails(data?.additionalPurchaseOrders)
    setPurchaseOrderList(pOList)
  }, [data.observationTaskId])

  useEffect(() => {
    if (data?.observationTaskId) {
      handleFetchAdditionalPurchaseOrderList()
    }
  }, [data?.observationTaskId, handleFetchAdditionalPurchaseOrderList])

  const handleDelete = useCallback((id) => {
    if (!!id) {
      dispatch(actions.deleteAdditionalPurchaseOrderRequest({ additionalPurchaseOrderId: id }, (res) => {
        if (res) {
          handleRefreshOrderDetailsPurchaseOrderList()
        }
      }))
    }
  }, [handleRefreshOrderDetailsPurchaseOrderList])

  const handleEditOrderDetail = useCallback(({ additionalPOId }) => {
    setIsOpenPurchaseOrderDetailsModal(true)
    setOrderDetailId(additionalPOId)
    setEditMode(true)
  }, [])

  return (
    <>
      {!!data?.additionalPurchaseOrders?.length ? <div className='contract-order-details'> <div className="order-details">
        <div className="order-details-content">
          <span className="order-details-title"><LocaleMessage id={SCHEDULING.MANAGE_ADDITIONAL_PURCHASE_ORDERS} /></span>
          <span className='order-details-edit' onClick={() => handleTogglePurchaseOrderDetailsModal(true)}><LocaleMessage id={SCHEDULING.EDIT_PURCHASE_ORDER} /></span>
        </div>
      </div>
        <AdditionalPurchaseOrderList purchaseOrderList={purchaseOrderList} onDelete={handleDelete} onEditMode={handleEditOrderDetail} />
      </div> :
        <div className='no-data-purchase-order'>
          <MessageCard messageText={<LocaleMessage id={SCHEDULING.NO_DATA_MSG} />} />
          <Button
            className="common-button primary-solid"
            onClick={() => handleTogglePurchaseOrderDetailsModal(false)}><LocaleMessage id={SCHEDULING.ADD_PURCHASE_ORDER} /></Button>
        </div>}
      {isOpenPurchaseOrderDetailsModal && <PurchaseOrderModal
        data={data}
        isSubGrid
        isManagePurchaseOrder
        isEditPurchaseOrder={isEditMode}
        isUpdatingOrderDetail={!!orderDetailId}
        orderId={orderDetailId}
        primaryPurchaseOrderNumber={primaryPurchaseOrderNumber}
        modalTitle={OVERVIEW_PAGE_SECTIONS.TASKS_SECTION.MANAGE_PURCHASE_ORDERS}
        onToggleModal={handleTogglePurchaseOrderDetailsModal}
      />}
    </>
  )
}

ExpandableContractsOrderDetailsList.propTypes = {
  data: PropTypes.object
}

export default ExpandableContractsOrderDetailsList
