import config from "./APIConfig"
import { getDataApi } from "../../index"

/**
 * @method getCampaignContractDetail : Get contract detail
 */
export const getCampaignContractDetail = async (data) => {
  const { url } = config.getCampaignContractDetail
  return await getDataApi(url(data))
}

export const getCampaignContractList = async (data) => {
  const { url } = config.getCampaignContractList
  return await getDataApi(url(data))
}

/**
 * @method getCampaignContractProductList: To get Products for Contract using product id
 */
export const getCampaignContractProductList = async (data) => {
  const { url } = config.getCampaignContractProductList
  return await getDataApi(url(data))
}

/**
 * @method getCampaignContractVM : To return VM for Contract
 */
export const getCampaignContractVM = async (data) => {
  const { url } = config.getCampaignContractVM
  return await getDataApi(url(data))
}

export const getCampaignContractVersionsListDDL = async (data) => {
  const { url } = config.getCampaignContractVersionsListDDL
  return await getDataApi(url(data))
}

export const getCampaignContractVersionDetails = async (data) => {
  const { url } = config.getCampaignContractVersionDetails
  return await getDataApi(url(data))
}
