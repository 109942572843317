import config from "./APIConfig"
import { postDataApi } from '../index'

/**
* @method createCampaignChecklist
* @param {object} data
* 
* {
*  wfCampaignSetupId: "",
*  checklistName: "",
*  isTrainingWFTemplate: false,
*  isReviewActivity: false
* }
* 
* API payload:-
* 
{
   "wfCampaignSetupId": 0,
   "checkListName": "string",
   isReviewActivity: false
}
*/

export const createCampaignChecklist = (data) => {
  const { url } = config.createCampaignChecklist
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    checkListName: data.checklistName,
    isReviewActivity: data.isReviewActivity
  }

  return postDataApi(url(data.wfCampaignSetupId), payload)
}

export default createCampaignChecklist
