import * as baseUrl from "../base-url"
import { getSearchQuery } from "../config"

const apiConfig = {
  getGlobalSearchList: {
    method: "GET",
    url: ({ itemName = '', options = [], pageNumber = 1 }) => {
      const optionsSearch = options
        .map((option) => getSearchQuery("options", option))
        .join("")
      const searchString = [
        getSearchQuery("itemName", itemName, true, true),
        getSearchQuery("pageindex", pageNumber),
        optionsSearch
      ].join('')
      return `api/Common/GlobalSearch${searchString}`
    }
  },
  getBreadscrumbDetail: {
    method: 'GET',
    url: ({
      programId = 0,
      campaignId = 0,
      workflowId = 0,
      siteId = 0,
      taskId = 0,
      siteUnitId = 0,
      organizationId = 0,
      actionId = 0,
      relatedTaskId = 0
    }) => {
      let searchString = [
        getSearchQuery("ProgramId", programId),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("WFCampaignSetupId", workflowId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("TaskId", taskId),
        getSearchQuery("SiteUnitId", siteUnitId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ActionId", actionId),
        getSearchQuery("RelatedTaskId", relatedTaskId)
      ].join('')
      searchString = searchString.replace('&', '?')

      return `api/Common/GetBreadscrumbDetail${searchString}`
    }
  },
  getSiteWeatherData: {
    method: "GET",
    url: ({ query }) => {
      const searchString = [
        getSearchQuery("appid", baseUrl.OPEN_WEATHER_MAP_API_ID, true),
        getSearchQuery("q", query),
        `&cnt=24&units=imperial`
      ].join('')

      return `data/2.5/forecast${searchString}`
    }
  },
  getNotification: {
    method: "GET",
    url: ({ pageNumber = 0, pageSize = 0 }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      searchString = searchString.replace('&', '?')

      return `api/Notification${searchString}`
    }
  },
  getWelcomeNotification: {
    method: "GET",
    url: "api/Notification/Welcome"
  },
  getQuickLinkList: {
    method: "GET",
    url: ({ pageNumber = 0, pageSize = 0 }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      searchString = searchString.replace('&', '?')
      return `api/Project/QuickLinkList${searchString}`
    }
  },
  updateNotification: {
    method: "PUT",
    url: ({ notificationId }) => `api/Notification/${notificationId}`
  },
  clearAllNotifications: {
    method: "PUT",
    url: `api/Notification/Clear`
  },
  clearNotification: {
    method: "PUT",
    url: ({ notificationId }) => `api/Notification/Clear/${notificationId}`
  },
  clearSelectedNotification: {
    method: "PUT",
    url: 'api/Notification/Clear/List'
  },
  getHomeSitesList: {
    method: "GET",
    url: ({ pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      return `api/Project/Site/Home/${searchString}`
    }
  },
  getDuxImage: {
    method: 'GET',
    url: ({
      externalRefId
    }) => {
      return `api/Checkpoint/GetDuxImage/${externalRefId}`
    }
  },
  getRegionFilterlist: {
    method: 'GET',
    url: ({
      isCalendarPage = false,
      sites = [],
      stateCode,
      pageNumber,
      pageSize,
      RegionName
    }) => {
      const sitesList = sites.map((site) => getSearchQuery("SiteIds", site)).join("")

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("StateCode", stateCode, false, true),
        getSearchQuery("RegionName", RegionName, false, true),
        sitesList
      ].join('')

      if (isCalendarPage) {
        return `api/Scheduling/Calendar/DDL/Regions${searchString}`
      }
      return `api/Common/GetRegionFilterList${searchString}`
    }
  },
  getGlobalSearchVM: {
    method: 'GET',
    url: 'api/Common/GlobalSearch/DDL'
  },
  getSubRegionFilterlist: {
    method: 'GET',
    url: ({
      region = [],
      regionId,
      isCalendarPage = false,
      pageNumber,
      pageSize,
      subRegionName
    }) => {
      const regionList = region.map((region) => getSearchQuery(isCalendarPage ? "RegionIds" : "RegionId", region.value)).join("")

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("RegionId", regionId),
        getSearchQuery("SubRegionName", subRegionName, false, true),
        regionList
      ].join('')

      if (isCalendarPage) {
        return `api/Scheduling/Calendar/DDL/SubRegions${searchString}`
      }
      return `api/Common/GetSubRegionFilterList${searchString}`
    }
  },
  getCommonTagsDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize, tagName, tagSourceId }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("TagName", tagName, false, true),
        getSearchQuery("TagSourceId", tagSourceId)
      ].join('')
      return `api/Common/DDL/Tag${searchString}`
    }
  },
  updateCommonTag: {
    method: "POST",
    url: ({ tagSourceId, tagSourceKeyId }) => `api/Common/Tag/${tagSourceId}/${tagSourceKeyId}/collection`
  },
  getCommonTag: {
    method: 'GET',
    url: ({ pageNumber, pageSize, tagSourceKeyId, tagSourceId }) => {
      const searchString = [
        getSearchQuery("TagSourceId", tagSourceId, true),
        getSearchQuery("TagSourceKeyId", tagSourceKeyId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Common/Tag/${tagSourceId}/${tagSourceKeyId}/collection${searchString}`
    }
  },
  updateNoteHeadline: {
    method: "PUT",
    url: ({ siteId, organizationId, unitId, noteDetail }) => {
      const searchString = getSearchQuery("note", noteDetail, true, true)

      if (!!siteId && !unitId) {
        return `api/Common/Note/Site/${siteId}${searchString}`
      }
      if (!!organizationId) {
        return `api/Common/Note/Organization/${organizationId}${searchString}`
      }
      if (!!unitId) {
        return `api/Common/Note/Unit/${unitId}${searchString}`
      }
    }
  },
  getNoteHeadline: {
    method: "GET",
    url: ({ siteId, organizationId, unitId }) => {

      if (!!siteId && !unitId) {
        return `api/Common/Note/Site/${siteId}`
      }
      if (!!organizationId) {
        return `api/Common/Note/Organization/${organizationId}`
      }
      if (!!unitId) {
        return `api/Common/Note/Unit/${unitId}`
      }
    }
  },
  sendEmail: {
    method: "POST",
    url: "api/Common/SendEmail"
  },
  getCCContactList: {
    method: "GET",
    url: ({ sourceType, sourceId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("SourceType", sourceType, true),
        getSearchQuery("SourceId", sourceId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Common/CCContact/${sourceType}/${sourceId}${searchString}`
    }
  },
  upsertCCContacts: {
    method: "POST",
    url: ({ sourceType, sourceId }) => {
      return `api/Common/CCContact/${sourceType}/${sourceId}`
    }
  },
  getEmailAddressListBySiteId: {
    method: "GET",
    url: ({ siteId, emailAddress, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("EmailAddress", emailAddress),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Common/filter/EmailAddressBySiteId/${siteId}${searchString}`
    }
  },
  getPhoneNumberListBySiteId: {
    method: "GET",
    url: ({ siteId, phoneNumber, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("PhoneNumber", phoneNumber),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Common/filter/PhoneNumberBySiteId/${siteId}${searchString}`
    }
  }
}

export default apiConfig
