import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateSchedulingPendingAssignment = (data) => {

  const { url } = config.updateSchedulingPendingAssignment
  return putDataApi(url, data)
}

export default updateSchedulingPendingAssignment
