import { CHECKPOINT_NUMERIC_TYPES, LANGUAGE_TYPE, CHECKPOINT_NUMERIC_VALIDATION_TYPES } from '@shared/constants'
import { getUniqueUuid, validateUuid } from '@utils'

import { 
  RESPONSE_CHOICE_TYPES, 
  initialResponseDetail as initialChoiceResponseDetail, 
  OTHER_CHOICE, 
  QA_STANDARD_QUESTION,
  QA_STANDARD_QUESTION_OPTIONS,
  BEAZER_QA_STANDARD_QUESTION,
  BEAZER_QA_STANDARD_QUESTION_OPTIONS,
  ESTAR_STANDARD_WITH_BV_QUESTION,
  ESTAR_STANDARD_WITH_BV_QUESTION_OPTIONS,
  ESTAR_STANDARD_WITHOUT_BV_QUESTION,
  ESTAR_STANDARD_WITHOUT_BV_QUESTION_OPTIONS,
  PASS_FAIL_QUESTION,
  PASS_FAIL_QUESTION_OPTIONS
} from '../choice-response'
import { getGivenTextLanguageList, getLanguageText } from '../Config'

function validateResponseName({ responseName, isPrimary, activeLocaleTab }) {
  const errors = {}

  if (!responseName && !isPrimary) {
    errors['responseName'] = getLanguageText(activeLocaleTab, 'RESPONSE_NAME_ERROR')
  }

  return errors
}

function validateResponseValue({ responseName, activeLocaleTab }) {
  const errors = {}

  if (!responseName) {
    errors['responseName'] = getLanguageText(activeLocaleTab, 'RESPONSE_VALUE_ERROR')
  }

  return errors
}

export const validateCheckpoint = ({ activityName, checkpointVersionId, checkpointResponses, isEditCheckpointMode }, activeLocaleTab) => {
  const errors = {}

  const checkPointName = activityName.find(activity => activity.language === LANGUAGE_TYPE.ENGLISH)?.languageValue || ''

  if (!checkPointName.trim()) {
    errors['checkPointName'] = getLanguageText(activeLocaleTab, 'ACTIVITY_ERROR')
  }

  const htmlRegex = /^(?:<(\w+)(?:(?:\s+\w+(?:\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)>[^<>]*<\/\1+\s*>|<\w+(?:(?:\s+\w+(?:\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/>|<!--.*?-->|[^<>]+)*$/

  if (!htmlRegex.test(checkPointName)) {
    errors['checkPointName'] = getLanguageText(activeLocaleTab, 'PLEASE_ENTER_VALID_ACTIVITY_HTML')
  }

  if (isEditCheckpointMode && !checkpointVersionId) {
    errors['checkpointVersionId'] = getLanguageText(activeLocaleTab, 'VERSION_ID')
  }

  if (!checkpointResponses.length) {
    errors['checkpointResponses'] = getLanguageText(activeLocaleTab, 'ACTIVITY_RESPONSE')
  }

  return errors
}

export const validateBooleanResponse = ({ responseName, checkpointResponseQuestions }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  const quesList = checkpointResponseQuestions?.length ? checkpointResponseQuestions : []

  quesList.forEach((question, index) => {
    if (!question.options) {
      errors[`responses_${index}`] = getLanguageText(activeLocaleTab, 'OPTION_ERROR')
    }
  })

  return errors
}

export const validateButtonResponse = ({ responseName, checkpointResponseQuestions }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }
  checkpointResponseQuestions.forEach((item, index) => {
     if (!item.options) {
      errors[`name_${index}`] = getLanguageText(activeLocaleTab, 'PLEASE_ENTER_BUTTON_NAME')
    }
    if (!item.buttonColor) {
      errors[`button_color_${index}`] = getLanguageText(activeLocaleTab, 'PLEASE_SELECT_BUTTON_COLOR')
    }
  })
  return errors
}

export const validateHiddenResponse = ({ responseName }, activeLocaleTab) => {
  const errors = { ...validateResponseValue({ responseName, activeLocaleTab }) }
  return errors
}

export const validateChoiceResponse = ({ responseName, checkpointResponseQuestions }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  const quesList = checkpointResponseQuestions?.length ? checkpointResponseQuestions : []

  const isOtherOption = quesList.find(option => option.options === OTHER_CHOICE[activeLocaleTab])

  quesList.forEach((question, index) => {
    if (!question.options) {
      errors[`responses_${index}`] = getLanguageText(activeLocaleTab, 'CHOICE')
    } else {
      if (index < quesList.length - 1 && !!isOtherOption) {
        if (question.options === isOtherOption.options) {
          errors[`responses_${index}`] = getLanguageText(activeLocaleTab, 'DUPLICATE_OTHER_OPTION')
        }
      }
    }
  })

  return errors
}

export const validateDateResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const validateFreeTextResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const validateNumericResponse = (
  {
    responseName,
    checkpointResponseNumericTypeId,
    checkpointResponseNumericValidationId,
    numericMin,
    numericMax
  },
  activeLocaleTab,
  isPrimary
) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  if (!checkpointResponseNumericTypeId) {
    errors['checkpointResponseNumericTypeId'] = getLanguageText(activeLocaleTab, 'NUMERIC_TYPE_ERROR')
  } else {
    if (checkpointResponseNumericTypeId === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
      if (!numericMin) {
        errors['numericMin'] = getLanguageText(activeLocaleTab, 'NUMERIC_MIN')
      } else if (Number(numericMin) < 0) {
        errors['numericMin'] = getLanguageText(activeLocaleTab, 'VALIDATE_MIN_NEGATIVE')
      } else if (numericMax && Number(numericMin) > Number(numericMax)) {
        errors['numericMin'] = getLanguageText(activeLocaleTab, 'VALIDATE_NUMERIC')
      }

      if (!numericMax) {
        errors['numericMax'] = getLanguageText(activeLocaleTab, 'NUMERIC_MAX')
      } else if (Number(numericMax) < 0) {
        errors['numericMax'] = getLanguageText(activeLocaleTab, 'VALIDATE_MAX_NEGATIVE')
      }
    }
  }

  if (!checkpointResponseNumericValidationId) {
    errors['checkpointResponseNumericValidationId'] = getLanguageText(activeLocaleTab, 'UNIT_TYPE')
  }

  if (Number(checkpointResponseNumericValidationId) === CHECKPOINT_NUMERIC_VALIDATION_TYPES.WHOLE_NUMBER) {
    const Regex = /^[-+]?[0-9]+\.[0-9]+$/
    if (Regex.test(Number(numericMin))) {
      errors['numericMin'] = getLanguageText(activeLocaleTab, 'NUMERIC_MIN_DECIMAL')
    } else if (Regex.test(Number(numericMax))) {
      errors['numericMax'] = getLanguageText(activeLocaleTab, 'NUMERIC_MAX_DECIMAL')
    }
  }

  return errors
}

export const validatePhotoResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const validateScanResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const validateResponseGotoCondition = ({ gotoConditions, isPrimary = false, primaryResponse, activeLocaleTab }) => {
  const errors = {}

  if (isPrimary) {
    return errors
  }

  const { gotoAnswer, operator } = gotoConditions
  const responseTypeId = String(primaryResponse.checkpointResponseTypeId)
  if (responseTypeId === RESPONSE_CHOICE_TYPES.NUMERIC) {
    if (String(primaryResponse.checkpointResponseNumericTypeId) === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
      const primaryNumericMax = parseInt(primaryResponse.numericMax) || 0,
        primaryNumericMin = parseInt(primaryResponse.numericMin) || 0
      const { min = '', max = '' } = gotoAnswer || {}
      const numericMin = min === '' ? '' : parseInt(min)
      const numericMax = max === '' ? '' : parseInt(max)
      if (numericMin === '' || numericMax === '') {
        if (numericMin === '') {
          errors['numericMinResponseCondition'] = getLanguageText(activeLocaleTab, 'NUMERIC_MIN')
        }
        if (numericMax === '') {
          errors['numericMaxResponseCondition'] = getLanguageText(activeLocaleTab, 'NUMERIC_MAX')
        }
      } else {
        const isValidFirst = !(numericMin >= primaryNumericMin && numericMin <= primaryNumericMax)
        const isValidSec = !(numericMax >= primaryNumericMin && numericMax <= primaryNumericMax)
        if (isValidFirst || isValidSec) {
          if (isValidFirst) {
            errors['numericMinResponseCondition'] = `${getLanguageText(activeLocaleTab, 'MIN_IN_RANGE')}[${primaryNumericMin}, ${primaryNumericMax}]`
          }
          if (isValidSec) {
            errors['numericMaxResponseCondition'] = `${getLanguageText(activeLocaleTab, 'MAX_IN_RANGE')}[${primaryNumericMin}, ${primaryNumericMax}]`
          }
        } else {
          if (numericMax !== '' && numericMin > numericMax) {
            errors['numericMinResponseCondition'] = getLanguageText(activeLocaleTab, 'VALIDATE_NUMERIC')
          } else if (!!numericMin && !numericMax) {
            errors['numericMaxResponseCondition'] = getLanguageText(activeLocaleTab, 'NUMERIC_MAX')
          } else if (!!numericMax && !numericMin) {
            errors['numericMinResponseCondition'] = getLanguageText(activeLocaleTab, 'NUMERIC_MIN')
          }
        }
      }
    } else if (gotoAnswer === '') {
      errors['numericResponseCondition'] = getLanguageText(activeLocaleTab, 'NUMERIC_RESPONSE_CONDITION')
    }
  } else if (responseTypeId === RESPONSE_CHOICE_TYPES.DATE && !gotoAnswer) {
    errors['dateResponseCondition'] = getLanguageText(activeLocaleTab, 'PLEASE_SELECT_DATE')
  }

  if (!(operator && (operator.text !== "— Select —" || operator.value))) {
    errors['responseCondition'] = getLanguageText(activeLocaleTab, 'RESPONSE_CONDITION')
  }

  return errors
}

export const getStandardResponseQuestion = (responseName = '', checkpointResponseTypeId = '') => {
  let defaultResponseQuestion = Object.assign({}, initialChoiceResponseDetail)

  let checkpointResponseQuestions = []
  if (checkpointResponseTypeId === RESPONSE_CHOICE_TYPES.QA_STANDARD) {
    checkpointResponseQuestions = QA_STANDARD_QUESTION_OPTIONS
  } else if (checkpointResponseTypeId === RESPONSE_CHOICE_TYPES.BEAZER_QA_STANDARD) {
    checkpointResponseQuestions = BEAZER_QA_STANDARD_QUESTION_OPTIONS
  } else if (checkpointResponseTypeId === RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITH_BV) {
    checkpointResponseQuestions = ESTAR_STANDARD_WITH_BV_QUESTION_OPTIONS
  }  else if (checkpointResponseTypeId === RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITHOUT_BV) {
    checkpointResponseQuestions = ESTAR_STANDARD_WITHOUT_BV_QUESTION_OPTIONS
  } else if (checkpointResponseTypeId === RESPONSE_CHOICE_TYPES.PASS_FAIL) {
    checkpointResponseQuestions = PASS_FAIL_QUESTION_OPTIONS
  } 

  defaultResponseQuestion = {
    ...defaultResponseQuestion,
    responseId: getUniqueUuid(),
    checkpointResponseTypeId,
    responseName: getGivenTextLanguageList(responseName),
    reportTag: '',
    defaultValue: '',
    resultValue: '',
    isStandardResponse: true,
    isPrimary: true,
    orderBy: 1, //To set order index 1 for primary response
    checkpointResponseQuestions: checkpointResponseQuestions.map((op, index) => ({
      questionId: getUniqueUuid(),
      orderBy: index + 1,
      isDeleted: false,
      options: getGivenTextLanguageList(op)
    }))
  }

  return defaultResponseQuestion
}

export const getDefaultResponseQuestion = () => {
  return getStandardResponseQuestion(QA_STANDARD_QUESTION, RESPONSE_CHOICE_TYPES.QA_STANDARD)
}

export const gotoConditionsFormatter = ({ gotoConditions = [], responseTypeId, responseNumericTypeId }) => {
  const list = gotoConditions?.length ? gotoConditions : []
  if (RESPONSE_CHOICE_TYPES.NUMERIC === String(responseTypeId) && CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE === String(responseNumericTypeId)) {
    return list.map(cond => {
      const gotoAnswer = cond.gotoAnswer.split(',')
      return {
        ...cond,
        gotoAnswer: {
          min: parseInt(gotoAnswer[0]),
          max: parseInt(gotoAnswer[1])
        }
      }
    })
  } else {
    return list
  }
}

export const convertApiResToLocalFormat = (data, conditionList) => {
  const { checkPointId, checkpointVersionId, originatedFrom, originatedFromId, activityName, responses } = data
  const activity = {
    checkpointId: checkPointId,
    activityName,
    originatedFrom,
    originatedFromId,
    checkpointVersionId: String(checkpointVersionId || '')
  }
  if (!!(responses?.length)) {
    const primaryQuestion = responses.find(res => res?.isPrimary) || {}
    const gotoConditions = gotoConditionsFormatter(primaryQuestion)
    const checkpointResponses = []
    responses.forEach((res) => {
      const {
        responseId,
        responseName,
        reportTag,
        isAllResponse,
        responseOptions,
        responseTypeId,
        responseNumericTypeId,
        responseNumericValidationId,
        isPrimary,
        numericMin,
        numericMax,
        required,
        isAllowedNA,
        otherOptions,
        orderIndex,
        responseCustomTypeId,
        defaultValue,
        resultValue
      } = res
      const resData = {
        responseId,
        checkpointResponseTypeId: responseTypeId,
        responseName,
        reportTag,
        isAllResponse,
        resultValue,
        defaultValue,
        isStandardResponse: responseTypeId === '',
        isPrimary,
        required,
        isAllowedNA,
        otherOptions,
        orderBy: orderIndex,
        responseCustomTypeId,
        ...(RESPONSE_CHOICE_TYPES.NUMERIC === String(responseTypeId)
          ? {
            numericMin,
            numericMax,
            checkpointResponseNumericTypeId: responseNumericTypeId,
            checkpointResponseNumericValidationId: responseNumericValidationId
          } : {})
      }
      if (!isPrimary) {
        const gotoCond = gotoConditions.find(cond => cond.goto?.indexOf(responseId) > -1) || {}
        const { operator, gotoAnswer } = gotoCond
        if (operator && gotoAnswer) {
          const selectedGotoCond = conditionList.find(cond => cond.text === operator)
          resData.gotoConditions = {
            gotoAnswer: validateUuid(gotoAnswer) ? selectedGotoCond.value : gotoAnswer,
            operator: {
              text: operator,
              value: selectedGotoCond.value || ''
            }
          }
        }
      } else {
        resData.gotoConditions = gotoConditions
      }

      if (!!(responseOptions?.length)) {
        const quesList = []
        responseOptions.forEach((ques) => {
          const { responseOptionId, orderIndex, options, buttonColor, fontColor } = ques
          const quesData = {
            questionId: responseOptionId,
            orderBy: orderIndex,
            options
          }
          if (buttonColor) {
            quesData.buttonColor = buttonColor
          }
          if (fontColor) {
            quesData.fontColor = fontColor
          }
          quesList.push(quesData)
        })
        resData.checkpointResponseQuestions = quesList
      }
      checkpointResponses.push(resData)
    })
    activity.checkpointResponses = checkpointResponses
  }
  return activity
}

export const isNotResponseCondition = (primaryResponseTypeId) => {
  const typeId = String(primaryResponseTypeId)
  switch (typeId) {
    case RESPONSE_CHOICE_TYPES.NUMERIC:
    case RESPONSE_CHOICE_TYPES.DATE:
      return false
    default:
      return true
  }
}

export const isCustomOptions = (primaryResponseTypeId) => {
  return [
    RESPONSE_CHOICE_TYPES.NUMERIC,
    // RESPONSE_CHOICE_TYPES.PHOTO,
    RESPONSE_CHOICE_TYPES.DATE
    // RESPONSE_CHOICE_TYPES.FREE_TEXT
  ].indexOf(String(primaryResponseTypeId)) > -1
}

export const validateDDLCustomSingleSelectResponse = ({ responseName, checkpointResponseQuestions }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  const quesList = checkpointResponseQuestions?.length ? checkpointResponseQuestions : []

  quesList.forEach((question, index) => {
    if (!question.options) {
      errors[`responses_${index}`] = getLanguageText(activeLocaleTab, 'OPTION_ERROR')
    }
  })

  return errors
}

export const validateCalculationResponse = ({ responseName, checkpointResponseQuestions }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  const quesList = checkpointResponseQuestions?.length ? checkpointResponseQuestions : []

  quesList.forEach((question, index) => {
    if (!question.options) {
      errors[`responses_${index}`] = getLanguageText(activeLocaleTab, 'OPTION_ERROR')
    }
  })

  return errors
}

export const validateSignatureResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const validateFileResponse = ({ responseName }, activeLocaleTab, isPrimary) => {
  const errors = { ...validateResponseName({ responseName, isPrimary, activeLocaleTab }) }

  return errors
}

export const getFormattedPrimaryResponseDetails = (checkpointDetail) => {
  let primaryResponseReportTag = checkpointDetail.reportTag || ""
  let primaryResponseDefaultValue = checkpointDetail.defaultValue || ""
  let primaryResponseResultValue = checkpointDetail.resultValue || ""

  const responseDetails = checkpointDetail.checkpointResponses?.find(res => res.isPrimary)
  
  if (responseDetails) {
    primaryResponseReportTag = responseDetails.reportTag
    primaryResponseDefaultValue = responseDetails.defaultValue
    primaryResponseResultValue = responseDetails.resultValue
  }

  return {
    primaryResponseReportTag,
    primaryResponseDefaultValue,
    primaryResponseResultValue
  }
}

export const checkIfStandardChoiceResponse = (responseTypeId) => {
  return [
    RESPONSE_CHOICE_TYPES.QA_STANDARD, 
    RESPONSE_CHOICE_TYPES.BEAZER_QA_STANDARD, 
    RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITHOUT_BV, 
    RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITH_BV, 
    RESPONSE_CHOICE_TYPES.PASS_FAIL
   ].includes(responseTypeId)
}

export const getStandardChoiceResponseName = (responseTypeId) => {
  let responseName = ""

  switch (responseTypeId) {
    case RESPONSE_CHOICE_TYPES.QA_STANDARD: 
      responseName = QA_STANDARD_QUESTION 
      break
    case RESPONSE_CHOICE_TYPES.BEAZER_QA_STANDARD: 
      responseName = BEAZER_QA_STANDARD_QUESTION 
      break
    case RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITH_BV: 
      responseName = ESTAR_STANDARD_WITH_BV_QUESTION 
      break
    case RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITHOUT_BV: 
      responseName = ESTAR_STANDARD_WITHOUT_BV_QUESTION 
      break
    case RESPONSE_CHOICE_TYPES.PASS_FAIL: 
      responseName = PASS_FAIL_QUESTION 
      break
    default: 
  }

  return responseName
}
