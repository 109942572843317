import config from './APIConfig'
import { deleteDataApi } from '../../index'

export const deleteCampaignContract = (data) => {
  const { url } = config.deleteCampaignContract
  return deleteDataApi(url(data))
}

export const deleteCampaignContractVersion = (data) => {
  const { url } = config.deleteCampaignContractVersion
  return deleteDataApi(url(data), null, data)
}
