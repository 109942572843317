import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteOrganization = (data) => {
  const { url } = config.deleteOrganization
  return deleteDataApi(url({ organizationId: data.organizationId }))
}

export const deleteOrganizationAddress = (data) => {
  const { url } = config.deleteOrganizationAddress
  return deleteDataApi(url({ organizationAddressId: data.organizationAddressId }))
}

export const deleteOrganizationContact = (data) => {
  const { url } = config.deleteOrganizationContact
  return deleteDataApi(url({ contactId: data?.contactId, organizationId: data?.organizationId }))
}

export const deleteOrganizationRoleContact = (data) => {
  const { url } = config.deleteOrganizationRoleContact
  return deleteDataApi(url(data))
}

export const deleteOrganizationProctor = (data) => {
  const { url } = config.deleteOrganizationProctor
  return deleteDataApi(url(data))
}
