import React, { useCallback, useMemo, useState } from "react"
import { X } from "react-feather"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

import { EXPORT_TYPES } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const ExportListOptions = ({
    intl,
    tableComponentProps = { },
    onExportList = () => { },
    onExportWfTaskList = () => { }
}) => {
    const [isShowExportDropdown, setShowExportDropdown] = useState(false)

    const handleToggleExportDropdown = useCallback(() => {
        setShowExportDropdown((prevState) => !prevState)
    }, [])

    const exportTypesList = useMemo(() => {
      const exportList = []
      if (tableComponentProps.isExportToCSVRequired) {
        exportList.push(EXPORT_TYPES.CSV)
      }
      if (tableComponentProps.isExportToDOCXRequired) {
        tableComponentProps.isTaskWinwardExport ? exportList.push(EXPORT_TYPES.TASK_RESULT_EXPORT) : exportList.push(EXPORT_TYPES.DOCX)
      }
      if (tableComponentProps.isExportToPDFRequired) {
        tableComponentProps.isTaskWinwardExport ? exportList.push(EXPORT_TYPES.FAILED_INSPECTION_EXPORT) : exportList.push(EXPORT_TYPES.PDF) 
      }
      if (tableComponentProps.isAgingReport) {
        exportList.push(EXPORT_TYPES.AGING_REPORT)
      }
      if (tableComponentProps.isFitAndFinishReport) {
        exportList.push(EXPORT_TYPES.FIT_AND_FINISH_PERFORMANCE, EXPORT_TYPES.FIT_AND_FINISH_CLOSEOUT)
      }
      if (tableComponentProps.isInspectionReport) {
        exportList.push(EXPORT_TYPES.INSPECTION_REPORT)
      }
      if (tableComponentProps.isOpenActionAgingByUnit) {
        exportList.push(EXPORT_TYPES.OPEN_ACTIONS_AGING_BY_UNIT)
      }
      if (tableComponentProps.isSiteVisitReport) {
        exportList.push(EXPORT_TYPES.SITE_VISIT_REPORT)
      }
      if (tableComponentProps.isRegionalSchedulingReport) {
        exportList.push(EXPORT_TYPES.REGIONAL_SCHEDULING_REPORT)
      }
      if (tableComponentProps.wfTaskReports) {
        exportList.push(...tableComponentProps.wfTaskReports)
      }
      if (tableComponentProps.isTwiningUnitStatusReport) {
        exportList.push(EXPORT_TYPES.UNIT_STATUS_TWINING_REPORT)
      }
      return exportList
    }, [tableComponentProps])

    const handleExportType = useCallback(
      (event) => {
        if (
          tableComponentProps.isTaskWinwardExport ||
          tableComponentProps.isAgingReport ||
          tableComponentProps.isFitAndFinishReport ||
          tableComponentProps.isInspectionReport ||
          tableComponentProps.isOpenActionAgingByUnit
        ) {
          onExportList(EXPORT_TYPES.PDF, event.target.id)
        } else {
          onExportList(event.target.id)
        }
      },
      [onExportList, tableComponentProps]
    )

    const handleWFExportType = useCallback((item) => {
      onExportWfTaskList(item)
    }, [onExportWfTaskList])

  return (
    <div className="page-size-filter">
    <Dropdown
    direction={'left'}
    isOpen={isShowExportDropdown}
    toggle={handleToggleExportDropdown}
    >
      <DropdownToggle className="selected-page-size">
        {getLocaleMessage(intl, LANGUAGE_CONSTANTS.EXPORT)}
      </DropdownToggle>
      <DropdownMenu center="true" className="page-size-menu">
        <div className="page-size-menu-header">
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.EXPORT_AS)}
          <X size={15} onClick={handleToggleExportDropdown}/>
        </div>
        {
          exportTypesList.map((item) => {
            const isObjectType = typeof item === 'object'
            const handleClick = isObjectType ? (e) => handleWFExportType(item) : (e) => handleExportType(e)
            return (
                <DropdownItem
                  tag="a"
                  className="w-100"
                  key={!isObjectType ? item : item.wfReportName}
                  onClick={handleClick}
                  id={!isObjectType ? item : item.wfReportName}
                >
                  <span className="align-middle ml-50" id={!isObjectType ? item : item.wfReportName}>{!isObjectType ? getLocaleMessage(intl, LANGUAGE_CONSTANTS[item]) : item.wfReportName}</span>
                </DropdownItem>
            )
          })
        }
      </DropdownMenu>
    </Dropdown>
  </div>
  )
}

export default localeMessageWrapper(ExportListOptions)