export const UPLOADER_TAB_TYPES = {
  UPLAOD: "1",
  FILES: "2"
}

export const ACTION_TYPE = {
  UPLOAD: 'UPLOAD',
  REPLACE: 'REPLACE'
}

export const FILE_UPLOAD_ERROR_TYPES = {
  FILE_TOO_LARGE: 'file-too-large',
  INVALID_FILE_FORMAT: 'invalid-file-format'
}

export const ACCEPTED_FILE_TYPES = "image/jpeg, image/png, .pdf, .txt, .ppt, .pptx, .doc, .docx, xls, .xlsx, .enb, .xml"

export const ACCEPTED_IMAGE_FILE_TYPES = "image/jpeg, image/png"

export const MAX_NAME_LENGTH = 255

export const MAX_FILE_SIZE = 100 //100MB

export const RESOURCE_CENTER_SOURCE_TYPES = {
  ORGANIZATIONS: "1",
  PROJECTS: "2",
  PROGRAMS: "3",
  CAMPAIGN: "4",
  CAMPAIGN_SERVICES: "5",
  TASKS: "6",
  ACTIVITIES: "7",
  SITES: "8",
  SITE_UNITS: "9",
  PROGRAM_REQUIREMENT: "10",
  ENERGY_STAR_PROGRAM: "15",
  BLUEPRINT: "28",
  ENERGY_MODEL: "30",
  PRIMARY_PURCHASE_ORDER: "31",
  ADDITIONAL_PURCHASE_ORDER: "32",
  REQUEST_INSPECTION: "34",
  ACTIONS: "actions", //todo
  PROCTORS: "36"
}

export const RESOURCE_CENTER_SOURCE_TYPE_VM = {
  [RESOURCE_CENTER_SOURCE_TYPES.ORGANIZATIONS]: {
    value: "1",
    sourceKeyName: "OrganizationId",
    text: "Organizations"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.PROJECTS]: {
    value: "2",
    sourceKeyName: "ProjectId",
    text: "Projects"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.PROGRAMS]: {
    value: "3",
    sourceKeyName: "ProgramId",
    text: "Programs"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.CAMPAIGN]: {
    value: "4",
    sourceKeyName: "CampaignId",
    text: "Campaigns"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.CAMPAIGN_SERVICES]: {
    value: "5",
    sourceKeyName: "CampaignServiceId",
    text: "Campaign Services"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.TASKS]: {
    value: "6",
    sourceKeyName: "observationTaskId",
    text: "Tasks"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.ACTIVITIES]: {
    value: "7",
    sourceKeyName: "ActivityId",
    text: "Activities"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.SITES]: {
    value: "8",
    sourceKeyName: "SiteId",
    text: "Sites"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.SITE_UNITS]: {
    value: "9",
    sourceKeyName: "SiteUnitId",
    text: "SiteUnits"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.PROGRAM_REQUIREMENT]: {
    value: "10",
    sourceKeyName: "ProgramRequirementId",
    text: "ProgramRequirements"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.ENERGY_STAR_PROGRAM]: {
    value: "15",
    sourceKeyName: "ProgramId",
    text: "ProgramEnergyStar"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.PRIMARY_PURCHASE_ORDER]: {
    value: "31",
    sourceKeyName: "ObservationTaskId"
  },
  [RESOURCE_CENTER_SOURCE_TYPES.ADDITIONAL_PURCHASE_ORDER]: {
    value: "32",
    sourceKeyName: "ObservationTaskId"
  }
}

export const DEFAULT_BUILDER_PORTAL_PO = '8'