import { getSearchQuery } from '../../config'

const apiConfig = {
  getCampaignContractList: {

    method: 'GET',
    url: ({
      campaignId = 0,
      wfCampaignSetupId = 0,
      productTypeId,
      pageNumber,
      pageSize,
      genericSearch = "",
      orderColumn,
      orderDirections,
      contractVersionId
    }) => {
      const searchString = [
        getSearchQuery("CampaignId", campaignId, true),
        getSearchQuery("ProductTypeId", productTypeId),
        getSearchQuery("WFCampaignSetupId", wfCampaignSetupId),
        getSearchQuery("ContractVersionId", contractVersionId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`
      ].join('')

      return `api/Campaign/CampaignContract/${campaignId}${searchString}`
    }
  },
  addCampaignContractDetail: {
    method: 'POST',
    url: ({ productTypeId, campaignId }) => `api/Campaign/CampaignContract/${productTypeId}/${campaignId}`
  },
  getCampaignContractDetail: {
    method: 'GET',
    url: ({ contractId, campaignId }) => `api/Campaign/CampaignContract/Contract/${contractId}/${campaignId}`
  },
  updateCampaignContractDetail: {
    method: 'PUT',
    url: ({ contractId }) => `api/Campaign/CampaignContract/${contractId}`
  },
  getCampaignContractProductList: {
    method: 'GET',
    url: ({
      campaignId = 0,
      productNumber,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("CampaignId", campaignId, true),
        getSearchQuery("productNumber", productNumber),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      return `api/Campaign/CampaignContract/Products/${campaignId}${searchString}`
    }
  },
  getCampaignContractVM: {
    method: 'GET',
    url: ({
      campaignId = 0
    }) => {
      const searchString = getSearchQuery("CampaignId", campaignId, true)

      return `api/Campaign/CampaignContractVM/${campaignId}${searchString}`
    }
  },
  deleteCampaignContract: {
    method: 'DELETE',
    url: ({ contractId, campaignId, wfCampaignSetupId }) => `api/Campaign/CampaignContract/${contractId}/${campaignId}/${wfCampaignSetupId}`
  },
  getCampaignContractVersionDetails: {
    method: 'GET',
    url: ({ campaignId = 0, contractVersionId = 0 }) => `api/Campaign/CampaignContract/Version/${campaignId}/${contractVersionId}`
  },
  getCampaignContractVersionsListDDL: {
    method: 'GET',
    url: ({ campaignId, pageNumber, pageSize, campaignVersionName = "" }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("campaignVersionName", campaignVersionName, false, true)
      ].join('')

      return `api/Campaign/CampaignContract/Version/DDL/${campaignId}${searchString}`
    }
  },
  deleteCampaignContractVersion: {
    method: 'DELETE',
    url: ({ campaignId, contractVersionId }) => `api/Campaign/CampaignContract/Version/${campaignId}/${contractVersionId}`
  },
  createCampaignContractVersion: {
    method: 'POST',
    url: ({campaignId}) => `api/Campaign/CampaignContract/Version/${campaignId}`
  },
  updateCampaignContractVersion: {
    method: 'PUT',
    url: ({campaignId, contractVersionId}) => `api/Campaign/CampaignContract/Version/${campaignId}/${contractVersionId}`
  }
}

export default apiConfig