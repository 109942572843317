import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { getReactSelectPositionProps } from "@views/helpers"

const { CONTACTS: { EMAIL } } = LANGUAGE_CONSTANTS

const EmailAddressSearch = ({
  intl,
  id = "emailAddress",
  name = "emailAddress",
  value = "",
  siteId = "",
  isDisabled = false,
  placeholder = "",
  labelRequired = true,
  customSelectedLable,
  customComponents,
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
      }, [100])
    }
    fn()
  }, [siteId])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (others.isReset && !prevOptions.length && options.length) {
        resolve({
          optionList: options,
          hasMore: true
        })
        return
      }
      const params = {
        pageNumber: page,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        emailAddress: search,
        siteId
      }
      dispatch(
        actions.getEmailAddressListBySiteIdRequest(params, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            resolve({
              optionList: items,
              hasMore: paginationData.hasNextPage
            })
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...response.items] : response.items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
      )
    })
  }, [options, value, siteId])

  const handleSelect = useCallback(
    (selected) => {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      if (!!lastElement && lastElement.emailAddress === '— Select —') {
        onSelect(id, [])
      } else {
        onSelect(id, selected)
      }
    },
    [onSelect, id]
  )

  const handleRefreshOptions = useCallback(() => {
    setOptions([...value])   
    setResetCache(prevState => !prevState)
  }, [value])

  const reactSelectPositionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  return (
    <>
      {labelRequired && <CustomLabel title={getLocaleMessage(intl, EMAIL)} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        isDisabled={isDisabled}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder || getLocaleMessage(intl, EMAIL)}
        options={options}
        lableType={'emailAddress'}
        valueType={'emailAddress'}
        defaultOptions={options}
        value={value}
        isMulti
        isShowCustomAction={false}
        hideSelectedOptions={false}
        customSelectedLable={customSelectedLable}
        customComponents={customComponents}
        getOptionLabel={(option) => option.emailAddress}
        getOptionValue={(option) => option.emailAddress}
        onLoadOptions={handleLoadMore}
        onRefreshOptions={handleRefreshOptions}
        onSelect={handleSelect}
        {...reactSelectPositionProps}
      />
    </>
  )
}

export default localeMessageWrapper(EmailAddressSearch)
