import axios from "axios"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

import * as baseUrl from "./base-url"
import { store } from '../../store'

export { default as forgotPassword } from "./forgot-password"
export { default as getContactTraininglogList } from './get-contact-training-log'
export { default as login } from "./login"
export { default as signup } from "./signup"
export * from './actions-page'
export * from './address'
export * from './campaigns'
export * from './common'
export * from './organization'
export * from './programs'
export * from './projects'
export * from './reports'
export * from './resources'
export * from './scorm'
export * from './scheduling'
export * from './task-data-collection'
export * from './template-training-activity'
export * from './templates'
export * from './user-management'
export * from './user-management-contacts'
export * from './user-management-permission'
export * from './workflow'
export * from './program-quality-control'
export * from './data-retention-policies'
export * from './overview-pages'
export * from './program-energy-star'
export * from './sampling'
export * from './contact'
export * from './scheduling-calendar'
export * from './audit-trail'
export * from './activities'
export * from './contact-group'
export * from './cords'
export * from './communication-log'
export * from './intercom'
export * from "./builder-home"

const { API_ERRORS } = LANGUAGE_CONSTANTS

const getBaseUrl = (path, config = {}) => {
  if (config.isCheckpointInspectionService) {
    return baseUrl.CHECKPOINT_INSPECTION_API_URL
  }
  if (path.startsWith("api/Report/GetReportDetail") || 
      path.startsWith("api/Cord")) {
    return baseUrl.SYSTEM_INTEGRATION_SERVICE_API_URL
  }
  if (config.isExportReports) {
    return baseUrl.SYSTEM_INTEGRATION_SERVICE_API_URL
  }
  if (path.startsWith('data/2.5/forecast')) {
    return baseUrl.OPEN_WEATHER_MAP_API_URL
  } else if (config.isScormCloudApi) {
    return baseUrl.SCORM_CLOUD_API_URL
  } else if (path.startsWith('api/UserManagement')
    || path.startsWith('api/Common/powerbi/token')
    || path.startsWith('api/Contact')
    || path.startsWith('api/UserContact')) {
    return baseUrl.USER_MANAGEMENT_API_URL
  } else if (path.startsWith('api/Template') ||
    path.startsWith('api/Checkpoint') ||
    path.startsWith('checkpoint') ||
    path.startsWith('api/Campaign') ||
    path.startsWith('Campaign') ||
    path.startsWith('CheckList') ||
    path.startsWith('api/Project') ||
    path.startsWith('api/Training') ||
    path.startsWith('api/Common') ||
    path.startsWith('api/Scheduling') ||
    path.startsWith('api/Program') ||
    path.startsWith('api/ResourceCenter') ||
    path.startsWith('api/program') ||
    path.startsWith('api/Program/ProgramSafetyRequirments') ||
    path.startsWith('api/WorkFlow') ||
    path.startsWith('api/Report') ||
    path.startsWith('api/Notification') ||
    path.startsWith('api/Sampling') ||
    path.startsWith('api/Organization/ContactGroup') ||
    path.startsWith('api/Organization/DDL/EntityContacts') || 
    path.startsWith('api/Organization/DDL/Organizations') ||
    path.startsWith('api/Organization/Organization/Proctor')
  ) {
    return baseUrl.CHECKPOINT_INSPECTION_API_URL
  } else if (path.startsWith('api/Scorm') || path.startsWith('api/Security')) {
    return baseUrl.SYSTEM_INTEGRATION_SERVICE_API_URL
  } else {
    return baseUrl.PROJECT_SETUP_API_URL
  }
}

const getApiUrl = (path = '', config) => {
  const { auth0Api } = config || {}
  if (auth0Api) {
    return `${baseUrl.AUTH0_API_URL}/${path}`
  }

  return `${getBaseUrl(path, config)}${path}`
}

const checkIfMessageContainsHtml = (string) => {
  const htmlRegex = /<[^>]*>/
  return htmlRegex.test(string)
}

const getHTMLMessageComponent = (message) => () => (<span dangerouslySetInnerHTML={{ __html: message }} />)

const getBulletedMessageComponent = (array) => () => (<ul>{array.map((item) => <li>{
  checkIfMessageContainsHtml(item) ? <span dangerouslySetInnerHTML={{ __html: item }} /> : item
  }</li>)}</ul>)

const handleErrorMessage = (error, requestType) => {
  const errorStatusCode = error?.response?.status
  let errorMessage = ""
  let status = 400
  let customHTMLMessage = null
  const errorMessageArray = Object.values(error?.response?.data?.errors || {})[0]

  switch (errorStatusCode) {
    case 400:
      if (errorMessageArray.length > 1) {
        customHTMLMessage = getBulletedMessageComponent(errorMessageArray)
      } else if (!!errorMessageArray.length && checkIfMessageContainsHtml(errorMessageArray[0])) {
        customHTMLMessage = getHTMLMessageComponent(errorMessageArray[0])
      }
      errorMessage = (errorMessageArray && errorMessageArray[0]) || error.message || ""
      status = 304
      break
    case 403:
      if (requestType === "GET") {
        errorMessage = API_ERRORS.NO_ACCESS_TO_RESOURCE
      } else if (requestType === "PUT") {
        errorMessage = API_ERRORS.NO_ACCESS_TO_UPDATE
      } else if (requestType === "POST") {
        errorMessage = API_ERRORS.NO_ACCESS_TO_CREATE
      } else if (requestType === "DELETE") {
        errorMessage = API_ERRORS.NO_ACCESS_TO_DELETE
      }
      status = 304
      break
    case 500:
      errorMessage = (errorMessageArray && errorMessageArray[0]) || error.message || ""
      break
    case 503:
      errorMessage = API_ERRORS.SERVICES_DOWN
      break
    default:
      errorMessage = (errorMessageArray && errorMessageArray[0]) || error.message || ""
      break
  }

  Notification({ status, message: errorMessage, customHTMLMessage })
  return errorMessage
}

export const handleUnauthorizedResponse = (error) => {
  // set user access denied as false when getting status 401
  const { response } = error
  if (error && (
    (error.status === 401) ||
    (error.statusCode === 401) ||
    (error.response?.data?.statusCode === 401) ||
    (response?.status === 401))
  ) {
    const state = store.getState()
    if (!state.auth.isAccessDenied) {
      store.dispatch(actions.setAccessDenied())
      store.dispatch(actions.setIsLoading(false))
    }
  } else if (response?.status === 500) {
    store.dispatch(actions.setIsLoading(false))
  }
}

export const handleNetworkError = (error, callback) => {
  if (error?.message?.toLowerCase().includes('network')) {
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    setTimeout(() => {
      callback()
    }, 1000)
  } else {
    callback()
  }
}

export const handleForbiddenError = (error) => {
  const { response } = error
  if (response?.status === 403) {
    const appInfo = JSON.parse(sessionStorage.getItem('apc_user'))
    if (appInfo && appInfo?._lastPageUrl !== '') {
      window.history.back()
    } else {
      window.location.href = window.location.origin
    }
  }
}

export const getHeaders = (accessToken, tokenType = 'Bearer') => ({
  accept: "text/plain",
  Authorization: `${tokenType} ${accessToken}`,
  'Ocp-Apim-Subscription-Key': baseUrl.API_SUBSCRIPTION_KEY
})

export const cancelApiRequest = (source) => {
  return source.cancel('Operation canceled by the user.')
}

export const getCancelTokenSource = () => {
  return axios.CancelToken.source()
}

// Array to store cancellation functions
let cancelArray = []
export const cancelAllPendingApiRequest = () => {
  // Iterate through the cancel token array and cancel each request
  cancelArray.forEach(apiRequest => {
    if (apiRequest) {
      cancelApiRequest(apiRequest)
    }
  })
  cancelArray = [] // Clear the cancel token array
}

export const getDataApi = (
  path = 'no-path-provided',
  headers = {
    accept: "text/plain"
  },
  config = {}
) => {
  try {
    const { pathConfig, preventTokenOverride, cancelToken = '', ...otherConfig } = config || {}

    // Create a cancellation source
    const source = getCancelTokenSource()
    cancelArray.push(source)

    const requestConfig = {
      ...otherConfig,
      headers,
      preventTokenOverride,
      cancelToken: !!cancelToken ? cancelToken : source.token // Associate the CancelToken with the request
    }

    return new Promise((resolve, reject) => {
      return axios
        .get(`${getApiUrl(path, pathConfig)}`, requestConfig)
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            let errorMessage = ""
            if (error?.response?.status === 403) {
              store.dispatch(actions.setIsLoading(false))
              errorMessage = handleErrorMessage(error, "GET")
            }
            handleForbiddenError(error)
            handleNetworkError(error, () => {
              handleUnauthorizedResponse(error)
              reject(error)
            })
          }
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}

export const postDataApi = (
  path = 'no-path-provided',
  data,
  headers = {
    accept: "text/plain"
  },
  config = { pathConfig: {} }
) => {
  try {
    // isApiNeedsToHandleErrors is used to check if api error messages are handled from other components or not
    const { pathConfig, isApiNeedsToHandleErrors = true, ...otherConfig } = config || {}

    return new Promise((resolve, reject) => {
      axios
        .post(`${getApiUrl(path, pathConfig)}`, data, { headers, preventTokenOverride: otherConfig.preventTokenOverride })
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {
          let errorMessage = ""
          if (isApiNeedsToHandleErrors) {
            errorMessage = handleErrorMessage(error, "POST")
          }
          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject({ ...error, message: error.response?.data?.error || error.message, isApiLevelErrorHandling: !!errorMessage })
          })
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}

export const putDataApi = (
  path = 'no-path-provided',
  data,
  headers = {
    accept: "text/plain"
  },
  config = {}
) => {
  try {
    // isApiNeedsToHandleErrors is used to check if api error messages are handled from other components or not
    const { pathConfig, isApiNeedsToHandleErrors = true } = config || {}
    return new Promise((resolve, reject) => {
      axios
        .put(`${getApiUrl(path, pathConfig)}`, data, { headers })
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {
          let errorMessage = ""
          if (isApiNeedsToHandleErrors) {
            errorMessage = handleErrorMessage(error, "PUT")
          }
          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject({ ...error, isApiLevelErrorHandling: !!errorMessage })
          })
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}

export const deleteDataApi = (
  path = 'no-path-provided',
  headers = {
    accept: "text/plain"
  },
  data = null
) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${getApiUrl(path)}`, { headers, data })
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {
          const errorMessage = handleErrorMessage(error, "DELETE")
          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject({ ...error, isApiLevelErrorHandling: !!errorMessage })
          })
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}
