import config from "./APIConfig"
import { putDataApi } from '../index'

export const createTasksOnDemand = (data) => {
  const { url } = config.createTasksOnDemand

  return putDataApi(url(data), data)
}

export default createTasksOnDemand
