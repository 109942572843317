import { put, call, takeLatest, take } from 'redux-saga/effects'

import {
  deleteAssignedTask,
  getContactTaskList,
  getScheduleVM,
  getScheduleAssignVM,
  getSchedulingList,
  getSchedulingListTasks,
  getSchedulingContactCalendarList,
  getTaskHistoryDetail,
  getTaskHistoryList,
  getTaskHistoryPendingUnitList,
  scheduleTask,
  updateTaskStatus,
  getAssignedTaskContactVM,
  getAssignedTaskContactListDDL,
  getAssignmentObservationTaskList,
  updateAssignmentActivities,
  getTaskAssignmentDownload,
  getAssignedContactsDDL,
  getAssignedTaskDetail,
  getAssignedContactList,
  getObservationTaskListSchedule,
  getAssignmentUnitsList,
  getAssignmentObservationTasksList,
  getAssignmentOrganizationsList,
  getAssignmentSitesList,
  getAssignmentRolesList,
  getSchedulingContactList,
  getReadyStatusCountWidget,
  getAssignedTaskCountWidget,
  getRescheduleTaskCountWidget,
  getUnitSitePlansVM,
  getSitePlansWithVersionVM,
  getNotReadyReasonVM,
  getWorkOrder,
  setWorkOrder,
  updateWorkOrder,
  getIsWorkOrderNeeded,
  setSchedulePendingAssignment,
  getTaskContractList,
  getTaskContractMissingItemsCount,
  getPurchaseOrderList,
  getPurchaseOrderVM,
  getCostCategoryDDL,
  addAdditionalPurchaseOrder,
  updateAdditionalPurchaseOrder,
  getAdditionalPurchaseOrder,
  deleteAdditionalPurchaseOrder,
  updatePrimaryPurchaseOrder,
  removeAssignment,
  removeScheduleDateTime,
  getOnHoldDependencyDetail,
  updateOnHoldRecheck,
  updateOnHoldCancel,
  updateOnHoldOverride,
  getOnHoldTaskList,
  reopenTask,
  reassignTaskReviewer,
  getScheduledEarliestTime,
  getSchedulingRequiredSkillsMarkets,
  updateCloseTaskManually,
  updateSchedulingPendingAssignment,
  getSchedulingPendingAssignment
} from '@services/api'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from '@store/actions'

const { SCHEDULING_SAGA } = LANGUAGE_CONSTANTS

/** getScheduleVM */
function* getScheduleVMRequest(action) {
  try {
    const response = yield call(getScheduleVM, action.payload)
    if (response?.data) {
      yield put(actions.getScheduleVMSuccess(response.data))
    } else {
      yield put(actions.getScheduleVMFailure('Unable to load schedule VM'))
    }
  } catch (error) {
    yield put(actions.getScheduleVMFailure(error.message))
  }
}

/** getScheduleAssignVM */
function* getScheduleAssignVMRequest(action) {
  try {
    const response = yield call(getScheduleAssignVM, action.payload)
    if (response?.data) {
      yield put(actions.getScheduleAssignVMSuccess(response.data))
    } else {
      yield put(actions.getScheduleAssignVMFailure('Unable to load schedule assign VM'))
    }
  } catch (error) {
    yield put(actions.getScheduleAssignVMFailure(error.message))
  }
}

/** getSchedulingList */
function* getSchedulingListRequest(action) {
  try {
    const response = yield call(getSchedulingList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getSchedulingList */
function* getSchedulingListTasksRequest(action) {
  try {
    const response = yield call(getSchedulingListTasks, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}

/** getSchedulingContactCalendarList */
function* getSchedulingContactCalendarListRequest(action) {
  try {
    const response = yield call(getSchedulingContactCalendarList, action.payload)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback()
  }
}

/** deleteAssignedTask API **/
function* deleteAssignedTaskRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteAssignedTask, action.payload)
    yield put(actions.setIsLoading(false))
    action.callback(response)
    if (response?.status === 200) {
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_UNASSIGED_SUCCESS })
    } else {
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UNASSIGNING_TASK })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getTaskHistoryDetail API **/
function* getTaskHistoryDetailRequest(action) {
  try {
    const response = yield call(getTaskHistoryDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskHistoryList API **/
function* getTaskHistoryListRequest(action) {
  try {
    const response = yield call(getTaskHistoryList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getTaskHistoryPendingUnitList API **/
function* getTaskHistoryPendingUnitListRequest(action) {
  try {
    const response = yield call(getTaskHistoryPendingUnitList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateTaskStatus API **/
function* updateTaskStatusRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(updateTaskStatus, payload)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    if (response?.status === 200) {
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_STATUS_UPDATED_SUCCESS })
      action.callback(response?.data)
    } else {
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_TASK_STATUS })
      action.callback()
    }
  } catch (error) {
    if (action.payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

/** scheduleTask API **/
function* scheduleTaskRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(scheduleTask, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      action.callback(response?.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_SCHEDULED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_SCHEDULING_TASK })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getContactTaskList API **/
function* getContactTaskListRequest(action) {
  try {
    const response = yield call(getContactTaskList, action.payload)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback()
  }
}

/** getAssignedTaskContactVM API **/
function* getAssignedTaskContactVMRequest(action) {
  try {
    const response = yield call(getAssignedTaskContactVM, action.payload)
    action.callback(response?.data?.assignedContact)
  } catch (error) {
    action.callback()
  }
}

/** getAssignedTaskContactListDDL API **/
function* getAssignedTaskContactListDDLRequest(action) {
  try {
    const response = yield call(getAssignedTaskContactListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentObservationTaskList API **/
function* getAssignmentObservationTaskListRequest(action) {
  try {
    const response = yield call(getAssignmentObservationTaskList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateAssignmentActivities API **/
function* updateAssignmentActivitiesRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateAssignmentActivities, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.ASSIGNMENT_ACTIVITIES_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ASSIGNMENT_ACTIVITIES_UPDATE_ERROR })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** downloadAssignmentActivitiesRequest API **/
function* downloadAssignmentActivitiesRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getTaskAssignmentDownload, action.payload)
    if (response) {
      action.callback(response)
    } else {
      Notification({ status: 400, message: SCHEDULING_SAGA.TASK_ASSIGNMENT_DOWNLOAD_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getAssignedContactsDDL API **/
function* getAssignedContactsDDLRequest(action) {
  try {
    const response = yield call(getAssignedContactsDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getAssignedTaskDetail API **/
function* getAssignedTaskDetailRequest(action) {
  try {
    const response = yield call(getAssignedTaskDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getAssignedContactList API **/
function* getAssignedContactListRequest(action) {
  try {
    const response = yield call(getAssignedContactList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getObservationTaskListSchedule */
function* getObservationTaskListScheduleRequest(action) {
  try {
    const response = yield call(getObservationTaskListSchedule, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentUnitsList */
function* getAssignmentUnitsListRequest(action) {
  try {
    const response = yield call(getAssignmentUnitsList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentObservationTasksList */
function* getAssignmentObservationTasksListRequest(action) {
  try {
    const response = yield call(getAssignmentObservationTasksList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentOrganizationsList */
function* getAssignmentOrganizationsListRequest(action) {
  try {
    const response = yield call(getAssignmentOrganizationsList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentSitesList */
function* getAssignmentSitesListRequest(action) {
  try {
    const response = yield call(getAssignmentSitesList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignmentRolesList */
function* getAssignmentRolesListRequest(action) {
  try {
    const response = yield call(getAssignmentRolesList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getSchedulingContactList */
function* getSchedulingContactListRequest(action) {
  try {
    const response = yield call(getSchedulingContactList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getReadyStatusCountWidget */
function* getReadyStatusCountWidgetRequest(action) {
  try {
    const response = yield call(getReadyStatusCountWidget, action.payload)
    if (response?.data) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getAssignedTaskCountWidget */
function* getAssignedTaskCountWidgetRequest(action) {
  try {
    const response = yield call(getAssignedTaskCountWidget, action.payload)
    if (response?.data) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getRescheduleTaskCountWidget */
function* getRescheduleTaskCountWidgetRequest(action) {
  try {
    const response = yield call(getRescheduleTaskCountWidget, action.payload)
    if (response?.data) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getUnitSitePlansVM */
function* getUnitSitePlansVMRequest(action) {
  try {
    const response = yield call(getUnitSitePlansVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSitePlansWithVersionVM */
function* getSitePlansWithVersionVMRequest(action) {
  try {
    const response = yield call(getSitePlansWithVersionVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getNotReadyReasonVM API **/
function* getNotReadyReasonVMRequest(action) {
  try {
    const response = yield call(getNotReadyReasonVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getWorkOrder */
function* getWorkOrderRequest(action) {
  try {
    const response = yield call(getWorkOrder, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** setWorkOrder API */
function* setWorkOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(setWorkOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.WORK_ORDER_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_ADDING_WORK_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateWorkOrder API **/
function* updateWorkOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateWorkOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.WORK_ORDER_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_WORK_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getIsWorkOrderNeeded */
function* getIsWorkOrderNeededRequest() {
  try {
    const response = yield call(getIsWorkOrderNeeded)
    yield put(actions.getIsWorkOrderNeededSuccess(response?.data))
  } catch (error) {

  }
}

/** setSchedulePendingAssignment API */
function* setSchedulePendingAssignmentRequest(action) {
  const {isEdit, ...payload} = action.payload
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(setSchedulePendingAssignment, payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      action.callback(response?.data)
      if (isEdit) {
        Notification({ status: 200, message: SCHEDULING_SAGA.SCHEDULE_PENDING_ASSIGNMENT_UPDATE_SUCCESS })
      } else {
        Notification({ status: 200, message: SCHEDULING_SAGA.SCHEDULE_PENDING_ASSIGNMENT_SUCCESS })
      }
    } else {
      action.callback(false)
      if (isEdit) {
        Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_SCHEDULE_PENDING_ASSIGNMENT })
      } else {
        Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_ADDING_SCHEDULE_PENDING_ASSIGNMENT })
      }
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getTaskContractList */
function* getTaskContractListRequest(action) {
  try {
    const response = yield call(getTaskContractList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskContractMissingItemsCount */
function* getTaskContractMissingItemsCountRequest(action) {
  try {
    const response = yield call(getTaskContractMissingItemsCount, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getPurchaseOrderList API **/
function* getPurchaseOrderListRequest(action) {
  try {
    const response = yield call(getPurchaseOrderList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getPurchaseOrderVM API **/
function* getPurchaseOrderVMRequest(action) {
  try {
    const response = yield call(getPurchaseOrderVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCostCategoryDDL API **/
function* getCostCategoryDDLRequest(action) {
  try {
    const response = yield call(getCostCategoryDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** addAdditionalPurchaseOrder API */
function* addAdditionalPurchaseOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addAdditionalPurchaseOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.ADDITIONAL_PURCHASE_ORDER_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_ADDING_ADDITIONAL_PURCHASE_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateAdditionalPurchaseOrder API **/
function* updateAdditionalPurchaseOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateAdditionalPurchaseOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.ADDITIONAL_PURCHASE_ORDER_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_ADDITIONAL_PURCHASE_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getAdditionalPurchaseOrder API **/
function* getAdditionalPurchaseOrderRequest(action) {
  try {
    const response = yield call(getAdditionalPurchaseOrder, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** deleteAdditionalPurchaseOrder API **/
function* deleteAdditionalPurchaseOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteAdditionalPurchaseOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(response)
      Notification({ status: 200, message: SCHEDULING_SAGA.ADDITIONAL_PURCHASE_ORDER_DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_DELETING_ADDITIONAL_PURCHASE_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updatePrimaryPurchaseOrder API **/
function* updatePrimaryPurchaseOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updatePrimaryPurchaseOrder, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.PRIMARY_PURCHASE_ORDER_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_PRIMARY_PURCHASE_ORDER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** removeAssignment API **/
function* removeAssignmentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(removeAssignment, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.REMOVE_ASSIGNMENT_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_REMOVEING_ASSIGNMENT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** removeScheduleDateTime API **/
function* removeScheduleDateTimeRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(removeScheduleDateTime, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.REMOVE_SCHEDULE_DATE_TIME_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_REMOVING_SCHEDULE_DATE_TIME })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getOnHoldTaskList API **/
function* getOnHoldTaskListRequest(action) {
  try {
    const response = yield call(getOnHoldTaskList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getOnHoldDependencyDetail API **/
function* getOnHoldDependencyDetailRequest(action) {
  try {
    const response = yield call(getOnHoldDependencyDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateOnHoldRecheck API **/
function* updateOnHoldRecheckRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOnHoldRecheck, action.payload)
    yield put(actions.setIsLoading(false))
    if (response) {
      action.callback(response?.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_STATUS_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_TASK_STATUS })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateOnHoldCancel API **/
function* updateOnHoldCancelRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOnHoldCancel, action.payload)
    yield put(actions.setIsLoading(false))
    if (response) {
      action.callback(response?.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_STATUS_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_TASK_STATUS })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateOnHoldOverride API **/
function* updateOnHoldOverrideRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOnHoldOverride, action.payload)
    yield put(actions.setIsLoading(false))
    if (response) {
      action.callback(response?.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_STATUS_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_TASK_STATUS })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** reassignTaskReviewer API **/
function* reassignTaskReviewerRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(reassignTaskReviewer, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_REVIEWER_REASSIGN_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_REASSIGNING_TASK_REVIEWER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** reopenTask API **/
function* reopenTaskRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(reopenTask, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      action.callback(response?.data)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_REOPEN_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_REOPENING_TASK })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getScheduledEarliestTime API */
function* getScheduledEarliestTimeRequest(action) {
  try {
    const response = yield call(getScheduledEarliestTime, action.payload)
    if (response?.status === 200) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getSchedulingRequiredSkillsMarkets */
function* getSchedulingRequiredSkillsMarketsRequest(action) {
  try {
    const response = yield call(getSchedulingRequiredSkillsMarkets, action.payload)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback()
  }
}

/** updateCloseTaskManually API **/
function* updateCloseTaskManuallyRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateCloseTaskManually, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.TASK_CLOSE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_CLOSING_TASK })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateSchedulingPendingAssignment API */
function* updateSchedulingPendingAssignmentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateSchedulingPendingAssignment, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SCHEDULING_SAGA.SCHEDULE_PENDING_ASSIGNMENT_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: SCHEDULING_SAGA.ERROR_IN_UPDATING_SCHEDULE_PENDING_ASSIGNMENT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getSchedulingPendingAssignment */
function* getSchedulingPendingAssignmentRequest(action) {
  try {
    const response = yield call(getSchedulingPendingAssignment, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* schedulingSagas() {
  yield takeLatest(actions.DELETE_ASSIGNED_TASK_REQUEST, deleteAssignedTaskRequest)
  yield takeLatest(actions.GET_CONTACT_TASK_LIST_REQUEST, getContactTaskListRequest)
  yield takeLatest(actions.GET_SCHEDULE_VM.REQUEST, getScheduleVMRequest)
  yield takeLatest(actions.GET_SCHEDULE_ASSIGN_VM.REQUEST, getScheduleAssignVMRequest)
  yield takeLatest(actions.GET_SCHEDULING_LIST_REQUEST, getSchedulingListRequest)
  yield takeLatest(actions.GET_SCHEDULING_LIST_TASKS_REQUEST, getSchedulingListTasksRequest)
  yield takeLatest(actions.GET_SCHEDULING_CONTACT_CALENDAR_LIST, getSchedulingContactCalendarListRequest)
  yield takeLatest(actions.GET_TASK_HISTORY_DETAIL_REQUEST, getTaskHistoryDetailRequest)
  yield takeLatest(actions.GET_TASK_HISTORY_LIST_REQUEST, getTaskHistoryListRequest)
  yield takeLatest(actions.GET_TASK_HISTORY_PENDING_UNIT_LIST_REQUEST, getTaskHistoryPendingUnitListRequest)
  yield takeLatest(actions.SCHEDULE_TASK_REQUEST, scheduleTaskRequest)
  yield takeLatest(actions.UPDATE_TASK_STATUS_REQUEST, updateTaskStatusRequest)
  yield takeLatest(actions.GET_ASSIGNED_TASK_CONTACT_VM_REQUEST, getAssignedTaskContactVMRequest)
  yield takeLatest(actions.GET_ASSIGNED_TASK_CONTACT_LIST_DDL_REQUEST, getAssignedTaskContactListDDLRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_OBSERVATION_TASK_LIST_REQUEST, getAssignmentObservationTaskListRequest)
  yield takeLatest(actions.UPDATE_ASSIGNMENT_ACTIVITIES_REQUEST, updateAssignmentActivitiesRequest)
  yield takeLatest(actions.DOWNLOAD_ASSIGNMENT_ACTIVITIES_REQUEST, downloadAssignmentActivitiesRequest)
  yield takeLatest(actions.GET_ASSIGNED_CONTACTS_DDL_REQUEST, getAssignedContactsDDLRequest)
  yield takeLatest(actions.GET_ASSIGNED_TASK_DETAIL_REQUEST, getAssignedTaskDetailRequest)
  yield takeLatest(actions.GET_ASSIGNED_CONTACT_LIST_REQUEST, getAssignedContactListRequest)
  yield takeLatest(actions.GET_OBSERVATION_TASK_LIST_SCHEDULE_REQUEST, getObservationTaskListScheduleRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_UNITS_LIST_REQUEST, getAssignmentUnitsListRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_OBSERVATION_TASKS_LIST_REQUEST, getAssignmentObservationTasksListRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_ORGANIZATIONS_LIST_REQUEST, getAssignmentOrganizationsListRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_SITES_LIST_REQUEST, getAssignmentSitesListRequest)
  yield takeLatest(actions.GET_ASSIGNMENT_ROLES_LIST_REQUEST, getAssignmentRolesListRequest)
  yield takeLatest(actions.GET_SCHEDULING_CONTACT_LIST_REQUEST, getSchedulingContactListRequest)
  yield takeLatest(actions.GET_READY_STATUS_COUNT_WIDGET_REQUEST, getReadyStatusCountWidgetRequest)
  yield takeLatest(actions.GET_ASSIGNED_TASK_COUNT_WIDGET_REQUEST, getAssignedTaskCountWidgetRequest)
  yield takeLatest(actions.GET_RESCHEDULE_TASK_COUNT_WIDGET_REQUEST, getRescheduleTaskCountWidgetRequest)
  yield takeLatest(actions.GET_UNIT_SITE_PLAN_VM, getUnitSitePlansVMRequest)
  yield takeLatest(actions.GET_SITE_PLANS_WITH_VERSION_VM, getSitePlansWithVersionVMRequest)
  yield takeLatest(actions.GET_NOT_READY_REASON_VM_REQUEST, getNotReadyReasonVMRequest)
  yield takeLatest(actions.GET_WORK_ORDER_REQUEST, getWorkOrderRequest)
  yield takeLatest(actions.SET_WORK_ORDER_REQUEST, setWorkOrderRequest)
  yield takeLatest(actions.UPDATE_WORK_ORDER_REQUEST, updateWorkOrderRequest)
  yield takeLatest(actions.GET_IS_WORK_ORDER_NEEDED.REQUEST, getIsWorkOrderNeededRequest)
  yield takeLatest(actions.SET_SCHEDULE_PENDING_ASSIGNMENT_REQUEST, setSchedulePendingAssignmentRequest)
  yield takeLatest(actions.GET_TASK_CONTRACT_LIST_REQUEST, getTaskContractListRequest)
  yield takeLatest(actions.GET_TASK_CONTRACT_MISSING_ITEMS_COUNT_REQUEST, getTaskContractMissingItemsCountRequest)
  yield takeLatest(actions.GET_PURCHASE_ORDER_LIST_REQUEST, getPurchaseOrderListRequest)
  yield takeLatest(actions.GET_PURCHASE_ORDER_VM_REQUEST, getPurchaseOrderVMRequest)
  yield takeLatest(actions.GET_COST_CATEGORY_DDL_REQUEST, getCostCategoryDDLRequest)
  yield takeLatest(actions.ADD_ADDITIONAL_PURCHASE_ORDER_REQUEST, addAdditionalPurchaseOrderRequest)
  yield takeLatest(actions.UPDATE_ADDITIONAL_PURCHASE_ORDER_REQUEST, updateAdditionalPurchaseOrderRequest)
  yield takeLatest(actions.GET_ADDITIONAL_PURCHASE_ORDER_REQUEST, getAdditionalPurchaseOrderRequest)
  yield takeLatest(actions.DELETE_ADDITIONAL_PURCHASE_ORDER_REQUEST, deleteAdditionalPurchaseOrderRequest)
  yield takeLatest(actions.UPDATE_PRIMARY_PURCHASE_ORDER_REQUEST, updatePrimaryPurchaseOrderRequest)
  yield takeLatest(actions.REMOVE_ASSIGNMENT_REQUEST, removeAssignmentRequest)
  yield takeLatest(actions.REMOVE_SCHEDULE_DATE_TIME_REQUEST, removeScheduleDateTimeRequest)
  yield takeLatest(actions.GET_ON_HOLD_TASK_LIST_REQUEST, getOnHoldTaskListRequest)
  yield takeLatest(actions.GET_ON_HOLD_DEPENDENCY_DETAIL_REQUEST, getOnHoldDependencyDetailRequest)
  yield takeLatest(actions.UPDATE_ON_HOLD_RECHECK_REQUEST, updateOnHoldRecheckRequest)
  yield takeLatest(actions.UPDATE_ON_HOLD_CANCEL_REQUEST, updateOnHoldCancelRequest)
  yield takeLatest(actions.UPDATE_ON_HOLD_OVERRIDE_REQUEST, updateOnHoldOverrideRequest)
  yield takeLatest(actions.REOPEN_TASK_REQUEST, reopenTaskRequest)
  yield takeLatest(actions.REASSIGN_TASK_REVIEWER_REQUEST, reassignTaskReviewerRequest)
  yield takeLatest(actions.GET_SCHEDULED_EARLIEST_TIME_REQUEST, getScheduledEarliestTimeRequest)
  yield takeLatest(actions.GET_SCHEDULING_REQUIRED_SKILLS_MARKETS, getSchedulingRequiredSkillsMarketsRequest)
  yield takeLatest(actions.UPDATE_CLOSE_TASK_MANUALLY_REQUEST, updateCloseTaskManuallyRequest)
  yield takeLatest(actions.UPDATE_SCHEDULING_PENDING_ASSIGNMENT_REQUEST, updateSchedulingPendingAssignmentRequest)
  yield takeLatest(actions.GET_SCHEDULING_PENDING_ASSIGNMENT_REQUEST, getSchedulingPendingAssignmentRequest)
}

export default schedulingSagas
