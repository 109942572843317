import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getTagBadgeComponent, getOverviewCreatedByContactInfoComponent, getOverviewUpdatedByContactInfoComponent, getOverviewNavigationLink } from '@views/helpers'
import { REPORTS_TAB_TYPES } from '@views/reports/overview/config'

const {
  SEE_MORE,
  SEE_LESS,
  TAG,
  WORKFLOW,
  OVERVIEW_PAGE_SECTIONS: { CAMPAIGN_OVERVIEW_TAB },
  OVERVIEW_SECTIONS,
  ORGANIZATIONS
} = LANGUAGE_CONSTANTS

export const SECTION_TAB_TYPES = {
  OVERVIEW: 1,
  WORKFLOWS: 2,
  UNITS: 3,
  TASKS: 4,
  DIRECTORY: 5,
  DATA_ACCESS: 6,
  RESOURCES: 7,
  CONTRACTS: 8
}

export const DOT_TYPE = {
  DANGER: "danger"
}

export const CAMPAIGN_TYPE = {
  TRAINING: "Training",
  OBSERVATION: "Observation",
  REPORT: "Report"
}

export const CAMPAIGN_TYPE_ID = {
  OBSERVATION: "1",
  TRAINING: "2",
  FINDING: "6",
  REPORT: "7"
}

export const ADD_CAMPAGIN_FOR = {
  ORGANIZATION: "1",
  SITE: "2"
}

export const overviewSectionTabs = [
  { id: SECTION_TAB_TYPES.OVERVIEW, name: OVERVIEW_SECTIONS.OVERVIEW },
  { id: SECTION_TAB_TYPES.WORKFLOWS, name: OVERVIEW_SECTIONS.WORKFLOWS },
  { id: SECTION_TAB_TYPES.TASKS, name: OVERVIEW_SECTIONS.TASKS, dotType: DOT_TYPE.DANGER },
  // { id: SECTION_TAB_TYPES.DIRECTORY, name: OVERVIEW_SECTIONS.DIRECTORY }, // TODO Tab hidden as per request
  { id: SECTION_TAB_TYPES.DATA_ACCESS, name: OVERVIEW_SECTIONS.DATA_ACCESS },
  { id: SECTION_TAB_TYPES.RESOURCES, name: OVERVIEW_SECTIONS.FILES }
]

export const campaignReportTabConfig = [REPORTS_TAB_TYPES.UNACCEPTABLE, REPORTS_TAB_TYPES.DAILY_INSPECTION, REPORTS_TAB_TYPES.SATURATION_RATE, REPORTS_TAB_TYPES.TRAINING, REPORTS_TAB_TYPES.TRAINING_CAMPAIGN, REPORTS_TAB_TYPES.CAMPAIGN_STATUS]

export const badgeColors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']

export const observationCampaignConfig = {
  col1: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.TYPE,
        keyName: "campaignType"
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.PROGRAM,
        keyName: "programName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'program',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.STATUS,
        keyName: "campaignStatus"
      },
      {
        labelLocaleId: ORGANIZATIONS.EXTERNAL_ID,
        keyName: "externalId"
      }
    ]
  },
  col2: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.MASTER_CAMPAIGN,
        keyName: "campaignMasterName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'masterCampaigns',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.SITE,
        keyName: "siteName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'sites',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.ADDRESS,
        keyName: "address",
        isAddressView: true
      },
      {
        labelLocaleId: TAG,
        keyName: "tagIds",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      },
      {
        labelLocaleId: WORKFLOW.SCOPE,
        keyName: "tagCampaignServices",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CREATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewCreatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.UPDATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewUpdatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.INSURANCE_MANDATED,
        keyName: "isInsuranceMandated",
        isFormattedValue: true,
        getFormattedValue: (value) => {
          return !!value.isInsuranceMandated ? <span>{'Yes'}</span> : <span>{'No'}</span>
        }
      }
    ]
  }
}

export const sharedCampaignConfig = {
  col1: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.TYPE,
        keyName: "campaignType"
      },

      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.STATUS,
        keyName: "campaignStatus"
      },
      {
        labelLocaleId: TAG,
        keyName: "tagIds",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      }
    ]
  },
  col2: {
    md: 4,
    columnConfig: [
      {
        labelLocaleId: WORKFLOW.SCOPE,
        keyName: "tagCampaignServices",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CREATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewCreatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.UPDATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewUpdatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.INSURANCE_MANDATED,
        keyName: "isInsuranceMandated",
        isFormattedValue: true,
        getFormattedValue: (value) => {
          return !!value.isInsuranceMandated ? <span>{'Yes'}</span> : <span>{'No'}</span>
        }
      }
    ]
  }
}

export const trainingCampaignConfig = {
  col1: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.TYPE,
        keyName: "campaignType"
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.ORGANIZATION,
        keyName: "campaignOrganizationName",
        isValueUnderline: true
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.PROGRAM,
        keyName: "programName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'program',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.STATUS,
        keyName: "campaignStatus"
      }
    ]
  },
  col2: {
    md: 4,
    columnConfig: [
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.MASTER_CAMPAIGN,
        keyName: "campaignMasterName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'masterCampaigns',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.SITE,
        keyName: "siteName",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'sites',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.ADDRESS,
        keyName: "address",
        isAddressView: true
      },
      {
        labelLocaleId: TAG,
        keyName: "tagIds",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      },
      {
        labelLocaleId: WORKFLOW.SCOPE,
        keyName: "tagCampaignServices",
        isBadgeRequired: true,
        getBadgeComponent: getTagBadgeComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CREATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewCreatedByContactInfoComponent
      },
            {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.UPDATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewUpdatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.INSURANCE_MANDATED,
        keyName: "isInsuranceMandated",
        isFormattedValue: true,
        getFormattedValue: (value) => {
          return !!value.isInsuranceMandated ? <span>{'Yes'}</span> : <span>{'No'}</span>
        }
      }
    ]
  }
}

export const campaignSeeMoreConfig = {
  col1: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: SEE_MORE,
        isClickable: true,
        isPropUnderline: true
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CAMPAIGN_DESCRIPTION,
        keyName: "campaignDescription",
        isSeeMore: true
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CAMPAIGN_OBJECTIVE,
        keyName: "campaignObjective",
        isSeeMore: true
      },
      {
        labelLocaleId: SEE_LESS,
        isClickable: true,
        isPropUnderline: true
      }
    ]
  }
}
