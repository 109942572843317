import { getSearchQuery } from '../config'

const apiConfig = {
  getAccountsByOrganizationId: {
    method: 'GET',
    url: 'api/UserManagement/entity/current/Roles'
  },
  addOrganizationAddress: {
    method: 'POST',
    url: (organizationId) => `api/Organization/Address/AddOrganizationAddress/${organizationId}`
  },
  addOrganizationContact: {
    method: 'POST',
    url: 'api/Organization/contact'
  },
  addOrganizationDetail: {
    method: 'POST',
    url: 'api/Organization/AddOrganizationDetail'
  },
  deleteOrganization: {
    method: 'DELETE',
    url: ({ organizationId }) => `api/Organization/${organizationId}`
  },
  deleteOrganizationAddress: {
    method: 'DELETE',
    url: ({ organizationAddressId }) => `api/Organization/DeleteOrganizationAddress/${organizationAddressId}`
  },
  deleteOrganizationContact: {
    method: 'DELETE',
    url: ({ contactId, organizationId }) => `api/Organization/contact/${contactId}?organizationId=${organizationId}`
  },
  editOrganizationDetail: {
    method: 'PUT',
    url: (organizationId = 0) => `api/Organization/UpdateOrganizationDetail/${organizationId}`
  },
  getAddOrganizationDetailVM: {
    method: 'GET',
    url: 'api/Organization/GetAddOrganizationDetailVM'
  },
  getContactByEmail: {
    method: 'GET',
    url: (emailAddress) => `api/Organization/contact/GetContactByEmail?EmailAddress=${emailAddress}`
  },
  getContactListVM: {
    method: 'GET',
    url: 'api/Organization/contact/GetContactListVM'
  },
  getCreateAccountVM: {
    method: 'GET',
    url: `api/Organization/contact/GetCreateAccountVM`
  },
  getFilteredOrganizations: {
    method: "GET",
    url: ({ orgName, entityId = 1, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrgName", orgName, false, true)
      ].join('')
      return `api/Organization/FilterOrganization${searchString}`
    }
  },
  getOrganizations: {
    method: "GET",
    url: ({ genericSearch, pageNumber, pageSize, contactId, organizationToIgnore }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrganizationToIgnore", organizationToIgnore, false, true)
      ].join('')

      if (contactId) {
        searchString = [
          searchString,
          getSearchQuery("ContactId", contactId)
        ].join('')
        return `api/Organization/DDL/Organizations/${contactId}${searchString}`
      }

      return `api/Common/filter/Organization${searchString}`
    }
  },
  getOrganizationAddress: {
    method: 'GET',
    url: ({ organizationId }) => `api/Organization/GetOrganizationAddress/${organizationId}`
  },
  getOrganizationContact: {
    method: 'GET',
    url: ({ organizationId = '', contactId }) => {
      return `api/Organization/contact/GetContactById?OrganizationId=${organizationId || ''}&ContactId=${contactId}`
    }
  },
  getOrganizationContactList: {
    method: 'GET',
    url: ({
      entityId = 1,
      organizationId = 0,
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          firstName = '',
          lastName = '',
          jobTitle = '',
          organizationName = '',
          organizationId = 0,
          email = '',
          phone = '',
          accountAccess = 0,
          organizationRole = []
        } = advanceSearch

        const organizationRoleList = organizationRole.map((role) => getSearchQuery("OrgPartyRoleIds", role.value)).join("")

        searchString = [
          searchString,
          getSearchQuery("FirstName", firstName, false, true),
          getSearchQuery("LastName", lastName, false, true),
          getSearchQuery("JobTitle", jobTitle, false, true),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("OrganizationName", organizationName, false, true),
          getSearchQuery("Phone", phone, false, true),
          getSearchQuery("Email", email, false, true),
          getSearchQuery("AccountAccess", accountAccess, false, true),
          organizationRoleList
        ].join('')
      }
      if (!organizationId && !advanceSearch.organizationId) {
        searchString = `${searchString}&OrganizationId=0`
      }
      const organisationId = advanceSearch.organizationId || organizationId
      return `api/Organization/${organisationId}/contact/getcontactlist${searchString}`
    }
  },
  getOrganizationDetail: {
    method: 'GET',
    url: ({ organizationId = 0 }) => `api/Organization/GetOrganizationDetail/${organizationId}`
  },
  getOrganizationList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = '',
      address = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizationName = '',
          parentOrganizationName = '',
          organizationTypeId = '',
          organizationCategoryId = '',
          organizationId = '',
          externalId = ''
        } = advanceSearch

        searchString = [
          searchString,
          getSearchQuery("OrganizationName", organizationName, false, true),
          getSearchQuery("ParentOrganizationName", parentOrganizationName, false, true),
          getSearchQuery("OrganizationTypeId", organizationTypeId),
          getSearchQuery("OrganizationCategoryId", organizationCategoryId),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("ExternalId", externalId, false, true)
        ].join('')

        if (!!Object.keys(address).length) {
          const {
            addressName = '',
            primaryPhone = '',
            streetAddress = '',
            unit = '',
            countryCode = '',
            city = '',
            state = '',
            postCode = ''
          } = address

          searchString = [
            searchString,
            getSearchQuery("AddressName", addressName, false, true),
            getSearchQuery("PrimaryPhone", primaryPhone),
            getSearchQuery("StreetAddress", streetAddress),
            getSearchQuery("Unit", unit),
            getSearchQuery("Country", countryCode),
            getSearchQuery("City", city, false, true),
            getSearchQuery("State", state, false, true),
            getSearchQuery("ZipCode", postCode, false, true)
          ].join('')
        }
      }
      return `api/Organization/GetOrganizationList${searchString}`
    }
  },
  getOrganizationListVM: {
    method: 'GET',
    url: 'api/Organization/GetOrganizationListVM'
  },
  getOrganizationTreeVM: {
    method: 'GET',
    url: ({ entityId = 1, organizationId, organizationParentId }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("OrganizationParentId", organizationParentId)
      ].join('')

      return `api/Organization/GetOrganizationTreeVM${searchString}`
    }
  },
  resetUserProfileImage: {
    method: 'POST',
    url: 'api/Organization/contact/ResetUserProfileImage'
  },
  updateOrganizationAddress: {
    method: 'PUT',
    url: ({ organizationId }) => `api/Organization/UpdateOrganizationAddress/${organizationId}`
  },
  updateOrganizationContact: {
    method: 'PUT',
    url: ({ contactId }) => `api/Organization/contact/${contactId}`
  },
  uploadUserProfileImage: {
    method: 'POST',
    url: () => `api/Organization/contact/UploadUserProfileImage`
  },
  validateOrganizationName: {
    method: 'GET',
    url: ({ entityId = 1, organizationName }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("organizationName", organizationName, false, true)
      ].join('')
      return `api/Organization/ValidateOrganizationName/${searchString}`
    }
  },
  getChildOrganizationList: {
    method: 'GET',
    url: ({
      parentOrganizationId,
      pageNumber,
      pageSize,
      orderColumn = '',
      orderDirections = '',
      genericSearch = ''
    }) => {
      const searchString = [
        getSearchQuery("ParentOrganizationId", parentOrganizationId, true),
        getSearchQuery("ChildOrganizationName", genericSearch, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      return `api/Organization/GetChildOrganizationList/${parentOrganizationId}${searchString}`
    }
  },
  removeChildOrganization: {
    method: 'PUT',
    url: ({ childOrganizationId }) => `api/Organization/RemoveParentOrganization/${childOrganizationId}`
  },
  getOrganizationSiteList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch,
      organizationId,
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("OrganizationId", organizationId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          siteIds,
          organization,
          city,
          state,
          region,
          subRegions,
          siteStatus
        } = advanceSearch
        const organizationList = organization.map((x) => getSearchQuery("OrganizationIds", x.organizationId)).join("")
        const siteList = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const cityList = city.map((city) => getSearchQuery("Cities", city.city, false, true)).join("")
        const statesList = state.map((state) => getSearchQuery("States", state.value)).join("")
        const regionsList = region.map((region) => getSearchQuery("RegionIds", region.value)).join("")
        const subRegionList = subRegions.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join("")
        const siteStatusList = siteStatus.map((status) => getSearchQuery("SiteStatuses", status.value)).join("")

        searchString = [
          searchString,
          organizationList,
          siteList,
          cityList,
          statesList,
          regionsList,
          subRegionList,
          siteStatusList
        ].join('')
      }
      return `api/Project/GetOrganizationOperationalStatusList/${organizationId}${searchString}`
    }
  },
  getOrganizationStatusListVM: {
    method: 'GET',
    url: 'api/Project/GetOrganizationOperationalStatusList/VM'
  },
  addOrganizationRoleContact: {
    method: 'POST',
    url: 'api/Organization/OrganizationRoleContact'
  },
  updateOrganizationRoleContact: {
    method: 'PUT',
    url: ({ organizationRoleContactId }) => `api/Organization/OrganizationRoleContact/${organizationRoleContactId}`
  },
  getOrganizationRoleContact: {
    method: 'GET',
    url: (organizationRoleContactId) => {
      return `api/Organization/GetOrganizationRoleContact/${organizationRoleContactId}`
    }
  },
  deleteOrganizationRoleContact: {
    method: 'DELETE',
    url: (organizationRoleContactId) => `api/Organization/OrganizationRoleContact/${organizationRoleContactId}`
  },
  getOrganizationExternalContactList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch,
      organizationId,
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("OrganizationId", organizationId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          firstName = '',
          lastName = '',
          jobTitle = '',
          organizationName = '',
          organizationId = 0,
          email = '',
          phone = '',
          accountAccess = 0,
          organizationRole = []
        } = advanceSearch

        const organizationRoleList = organizationRole.map((role) => getSearchQuery("OrgPartyRoleIds", role.value)).join("")

        searchString = [
          searchString,
          getSearchQuery("FirstName", firstName, false, true),
          getSearchQuery("LastName", lastName, false, true),
          getSearchQuery("JobTitle", jobTitle, false, true),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("OrganizationName", organizationName, false, true),
          getSearchQuery("Email", email, false, true),
          getSearchQuery("Phone", phone, false, true),
          getSearchQuery("AccountAccess", accountAccess, false, true),
          organizationRoleList
        ].join('')
      }
      return `api/Organization/ContactListExternal/${organizationId}${searchString}`
    }
  },
  getUserAuth0ContactDetailsVM: {
    method: 'GET',
    url: ({
      contactId
    }) => `api/UserContact/VM/${contactId}`
  },
  sendPasswordResetEmail: {
    method: 'POST',
    url: ({
      contactId
    }) => `api/UserContact/SendResetPasswordEmail/${contactId}`
  },
  updateUserAccountPassword: {
    method: 'POST',
    url: (contactId) => `api/UserContact/ChangePassword/${contactId}`
  },
  getUserAccountHistory: {
    method: 'GET',
    url: (contactId) => `api/UserContact/history/${contactId}`
  },
  createNewUserAccount: {
    method: 'POST',
    url: (contactId) => `api/UserContact/CreateUser/${contactId}`
  },
  getOrganizationPlanList: {
    method: 'GET',
    url: ({
      organizationId,
      pageNumber,
      pageSize,
      genericSearch = '',
      orderColumn,
      orderDirections = ''
    }) => {
      const searchString = [
        getSearchQuery("OrganizationId", organizationId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      return `api/Organization/${organizationId}/OrganizationPlan/List${searchString}`
    }
  },
  createOrganizationPlan: {
    method: 'POST',
    url: "api/Organization/OrganizationPlan"
  },
  updateOrganizationPlan: {
    method: 'PUT',
    url: ({
      organizationId,
      organizationPlanId
    }) => {
      return `api/Organization/${organizationId}/OrganizationPlan/${organizationPlanId}`
    }
  },
  getOrganizationPlan: {
    method: 'GET',
    url: ({
      organizationPlanId,
      siteUnitPlanId
    }) => {
      const searchString = getSearchQuery('siteUnitPlanId', siteUnitPlanId, true)
      return `api/Organization/OrganizationPlan/DDL/${organizationPlanId}/detail${searchString}`
    }
  },
  getOrganizationPlanDDL: {
    method: 'GET',
    url: ({
      planName,
      organizationId,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("OrganizationId", organizationId, true),
        getSearchQuery("OrganizationPlanName", planName, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      return `api/Organization/${organizationId}/OrganizationPlan/DDL${searchString}`
    }
  },
  getOrganizationPlanBlueprintDDL: {
    method: 'GET',
    url: ({
      blueprintName,
      organizationPlanId,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("OrganizationPlanId", organizationPlanId, true),
        getSearchQuery("BluePrintName", blueprintName, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      return `api/Organization/OrganizationPlan/${organizationPlanId}/BluePrint/DDL${searchString}`
    }
  },
  getOrganizationPlanBlueprintEnergyModelDDL: {
    method: 'GET',
    url: ({
      energyModelName,
      organizationPlanId,
      blueprintId,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("OrganizationPlanId", organizationPlanId, true),
        getSearchQuery("BluePrintId", blueprintId),
        getSearchQuery("EnergyModelName", energyModelName, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      return `api/Organization/OrganizationPlan/${organizationPlanId}/BluePrint/${blueprintId}/EnergyModel/DDL${searchString}`
    }
  },
  updateOrganizationUnitPlan: {
    method: 'PUT',
    url: ({ siteUnitId, siteUnitPlanId }) => `api/Organization/OrganizationPlan/Unit/${siteUnitId}/${siteUnitPlanId}`
  },
  addOrganizationPlanBlueprint: {
    method: 'POST',
    url: ({ organizationPlanId }) => `api/Organization/OrganizationPlan/${organizationPlanId}/BluePrint`
  },
  addOrganizationPlanBlueprintEnergyModel: {
    method: 'POST',
    url: ({ organizationPlanId, organizationBluePrintId }) => `api/Organization/OrganizationPlan/${organizationPlanId}/BluePrint/${organizationBluePrintId}/EnergyModel`
  },
  getOrganizationPlanBlueprintList: {
    method: 'GET',
    url: ({ organizationPlanId, organizationId }) => {
      const searchString = [
        getSearchQuery("OrganizationPlanId", organizationPlanId, true),
        getSearchQuery("OrganizationId", organizationId)
      ].join('')

      return `api/Organization/${organizationId}/OrganizationPlan/${organizationPlanId}/BluePrint/List${searchString}`
    }
  },
  getOrganizationPlanBlueprintEnergyModelList: {
    method: 'GET',
    url: ({ organizationPlanId, organizationId, blueprintId }) => {
      const searchString = [
        getSearchQuery("OrganizationPlanId", organizationPlanId, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("BluePrintId", blueprintId)
      ].join('')

      return `api/Organization/${organizationId}/OrganizationPlan/${organizationPlanId}/BluePrint/${blueprintId}/EnergyModel/List${searchString}`
    }
  },
  addOrganizationProctor: {
    method: 'POST',
    url: ({
      organizationId
    }) => `api/Organization/Organization/Proctor/${organizationId}`
  },
  updateOrganizationProctor: {
    method: 'PUT',
    url: ({ 
      organizationProctorId
    }) => `api/Organization/Organization/Proctor/${organizationProctorId}`
  },
  deleteOrganizationProctor: {
    method: 'DELETE',
    url: ({
      organizationProctorId
    }) => `api/Organization/Organization/Proctor/${organizationProctorId}`
  },
  getOrganizationProctorList: {
    method: 'GET',
    url: ({
      organizationId,
      pageNumber,
      pageSize,
      genericSearch = '',
      orderColumn,
      orderDirections = ''
    }) => {
      const searchString = [
        getSearchQuery("OrganizationId", organizationId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("FileName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Organization/Organization/Proctor/List/${organizationId}${searchString}`
    }
  },
  getOrganizationProctor: {
    method: 'GET',
    url: ({
      organizationProctorId
    }) => `api/Organization/Organization/Proctor/${organizationProctorId}`
  }
}

export default apiConfig
