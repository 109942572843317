import config from "./APIConfig"
import { postDataApi } from '../index'

export const addOrganizationProctor = (data) => {
  const { url } = config.addOrganizationProctor

  return postDataApi(url(data), data)
}

export default addOrganizationProctor
