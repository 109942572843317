import config from "./APIConfig"
import { postDataApi } from "../../index"

/**
 * @method createCampaignContractVersion
 * @param {object} data 
 *{
  projectId: "",
  startDate: null,
  endDate: null,
  copyContractLineItem: false,
  copyAdditionalLineItem: false
}
 *
 */
export const createCampaignContractVersion = (payload) => {
  const { url } = config.createCampaignContractVersion
  return postDataApi(url({campaignId: payload.campaignId, contractVersionId: payload.contractVersionId}), payload)
}

export default createCampaignContractVersion
