import { getSearchQuery } from '../config'

const getWidgetFormattedQueryPayload = (params) => {
  const {
    unitName,
    taskName,
    programName,
    campaignName,
    activityName,
    projectId,
    siteId,
    campaignTypeId,
    programTypeId,
    startDate,
    endDate,
    wfActionStatusId,
    actionAssigendTo
  } = params || {}
  return [
    getSearchQuery('ContactId', actionAssigendTo),
    getSearchQuery('ActionStatusId', wfActionStatusId),
    getSearchQuery('ProjectId', projectId),
    getSearchQuery('SiteId', siteId),
    getSearchQuery('UnitName', unitName, false, true),
    getSearchQuery('ProgramName', programName, false, true),
    getSearchQuery('ProgramTypeId', programTypeId),
    getSearchQuery('CampaignName', campaignName, false, true),
    getSearchQuery('CampaignTypeId', campaignTypeId),
    getSearchQuery('TaskName', taskName, false, true),
    getSearchQuery('ActivityName', activityName, false, true),
    getSearchQuery('StartDate', startDate),
    getSearchQuery('EndDate', endDate)
  ].join('')
}

const getContactListFormattedQueryPayload = ({
  pageNumber,
  pageSize,
  contactName = ""
}) => {
  return [
    getSearchQuery("PageNumber", pageNumber, true), 
    getSearchQuery("PageSize", pageSize),
    getSearchQuery("ContactName", contactName, false, true)
  ].join('')
}

const apiConfig = {
  getActionList: {
    method: 'GET',
    url: ({
      isListView,
      timeInterval = 0,
      actionAssignedToId = '',
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      observationTaskId,
      orderColumn,
      orderDirections = '',
      language = '',
      isMyActionOnly = false
    }) => {
      let searchString = [
        getSearchQuery("TimeInterval", timeInterval, !isListView),
        getSearchQuery("PageNumber", pageNumber, !!isListView),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("ObservationTaskId", observationTaskId),
        getSearchQuery("OrderColumn", orderColumn),
        `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`,
        `${language ? `&Langauge=${language}` : ''}`,
        `${actionAssignedToId ? `&ActionAssignedToId=${actionAssignedToId}` : ''}`,
        getSearchQuery("IsMyActionOnly", isMyActionOnly)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          actionTypeId = '',
          wfActionStatusId = '',
          projectId = '',
          campaignId = '',
          workflowId = '',
          programId = '',
          siteId = '',
          unitName = '',
          programName = '',
          programTypeId = 0,
          programStatusId = 0,
          campaignName = '',
          campaignTypeId = 0,
          startDate = '',
          endDate = '',
          actionAssigendTo = 0,
          taskName = 0,
          activityName = 0,
          tagNumber = '',
          region = [],
          organizations = [],
          sites = [],
          units = [],
          siteIds = [],
          siteUnitIds = [],
          actionTypes = [],
          assignee = []
        } = advanceSearch

        const regionSearch = region.map((id) => getSearchQuery("RegionIds", id.value)).join("")
        const organizationSearch = organizations.map((data) => data.organizationId).join(",")
        const sitesSearch = sites.map((data) => data.value).join(",")
        const unitsSearch = units.map((data) => data.value).join(",")
        const sitesList = siteIds.map((site) => site.value).join(",")
        const siteUnitIdsList = siteUnitIds.map((unit) => unit.value).join(",")
        const actionTypesList = actionTypes.map((type) => type.value).join(",")
        const assigneeList = assignee.map((contact) => contact.value).join(",")

        searchString = [
          searchString,
          getSearchQuery("WFActionTypeId", actionTypeId),
          getSearchQuery("WFActionStatusId", wfActionStatusId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("CampaignId", campaignId),
          getSearchQuery("WFCampaignSetupId", workflowId),
          getSearchQuery("ProgramId", programId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("ProgramStatusId", programStatusId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("ActionAssignedToId", actionAssigendTo),
          getSearchQuery("TaskName", taskName, false, true),
          getSearchQuery("ActivityName", activityName, false, true),
          getSearchQuery("TagNumber", tagNumber, false, true),
          getSearchQuery("OrganizationIds", organizationSearch),
          getSearchQuery("SiteIds", sitesSearch),
          getSearchQuery("SiteUnitIds", unitsSearch),
          getSearchQuery("SiteIds", sitesList),
          getSearchQuery("SiteUnitIds", siteUnitIdsList),
          getSearchQuery("WFActionTypeIds", actionTypesList),
          getSearchQuery("AssigneeIds", assigneeList),
          regionSearch
        ].join('')
      }

      if (isListView) {
        return `api/WorkFlow/Action/ActionList${searchString}`
      }

      return `api/WorkFlow/Action/ActionList/${timeInterval}${searchString}`
    }
  },
  updateAcceptByTrade: {
    method: 'PUT',
    url: ({ wfActionId }) => `api/WorkFlow/AcceptByTrade/${wfActionId}`
  },
  updateVerifyBySuperintendent: {
    method: 'PUT',
    url: ({
      wfActionId
    }) => `api/WorkFlow/VerifyBySuperintendent/${wfActionId}
    `
  },
  getActionListVM: {
    method: "GET",
    url: "api/WorkFlow/Action/vm/ActionList"
  },
  createConfirmSiteVisit: {
    method: 'POST',
    url: ({
      observationTaskId,
      isConfirmSiteVisit,
      rescheduleDate,
      actionNotReadyResponseId,
      note
    }) => {
      const searchString = [getSearchQuery("ReScheduleDate", rescheduleDate), getSearchQuery("ActionNotReadyResponseId", actionNotReadyResponseId), getSearchQuery("Note", note, false, true)].join('')
      return `api/Scheduling/confirmsitevisit/${observationTaskId}?IsConfirmSiteVisit=${isConfirmSiteVisit}${searchString}`
    }
  },
  getActionDetails: {
    method: 'GET',
    url: (actionId) => `api/WorkFlow/Action/ActionDetail/${actionId}?ActionId=${actionId}`
  },
  getActionAuditTrail: {
    method: 'GET',
    url: ({
      actionId,
      language
    }) => `api/WorkFlow/AuditTrail/${actionId}?Langauge=${language}`
  },
  getTradeContactToReassign: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize, contactName }) => {
      const searchString = getContactListFormattedQueryPayload({ pageNumber, pageSize, contactName })
      return `api/WorkFlow/GetTradeContacttoReassign/${actionId}${searchString}`
    }
  },
  updateTradeActionReassign: {
    method: 'PUT',
    url: ({ actionId, contactId, roleId }) => `api/WorkFlow/TradeActionReassign/${actionId}/${contactId}/${roleId}`
  },
  getSuperintendentToReassign: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize }) => {
      const searchString = getContactListFormattedQueryPayload({ pageNumber, pageSize })
      return `api/WorkFlow/GetsuperintendentstoReassign/${actionId}${searchString}`
    }
  },
  updateSuperintendentActionReassign: {
    method: 'PUT',
    url: (actionId) => `api/WorkFlow/SuperintendentActionReassign/${actionId}`
  },
  getReassignFailedInspectionContact: {
    method: 'GET',
    url: ({ actionId, pageNumber, pageSize, contactName }) => {
      const searchString = getContactListFormattedQueryPayload({ pageNumber, pageSize, contactName })
      return `api/WorkFlow/GetReassignFailedInspectionContact/${actionId}${searchString}`
    }
  },
  updateReassignFailedInspection: {
    method: 'PUT',
    url: ({ actionId, contactId, roleId }) => `api/WorkFlow/ReassignFailedInspection/${actionId}/${contactId}/${roleId}`
  },
  getCorrectionDetails: {
    method: 'GET',
    url: ({
      actionId,
      language
    }) => `api/WorkFlow/CorrectionDetails/${actionId}?Langauge=${language}`
  },
  getWidget: {
    method: 'GET',
    url: (
      type = "",
      { advanceSearch = {} }
    ) => {
      if (!type) {
        return ""
      }

      let searchString = ""
      if (!!Object.keys(advanceSearch).length) {
        searchString = getWidgetFormattedQueryPayload(advanceSearch)
      }
      searchString = searchString.replace('&', '?')

      if (type === "ConfirmSiteVisit") {
        return `api/Report/Widget/Actions/ConfirmSiteVisitCount${searchString}`
      }

      if (type === "ConfirmSiteVisitOverdue") {
        return `api/Report/Widget/Actions/ConfirmSiteVisitOverdueCount${searchString}`
      }

      if (type === "FailedInspection") {
        return `api/Report/Widget/Actions/TradeCorrectionsCount${searchString}`
      }

      if (type === "FailedInspectionOverdue") {
        return `api/Report/Widget/Actions/FailedInspCorrOverdueCount${searchString}`
      }

      if (type === "VerifyBySuperIntendent") {
        return `api/Report/Widget/Actions/VerificationCount${searchString}`
      }

      if (type === "VerifyBySuperIntendentOverdue") {
        return `api/Report/Widget/Actions/VerifySuperintendOverdueCount${searchString}`
      }

      if (type === "ReassignFailedInspectionCorrection") {
        return `api/Report/Widget/Actions/ReassignFailedInspCorrCount${searchString}`
      }

      if (type === "ReassignFailedInspectionCorrectionOverdue") {
        return `api/Report/Widget/Actions/ReassignFailedInspCorrOverdueCount${searchString}`
      }

      if (type === "ReadyActionCount") {
        return `api/Report/Widget/Actions/ActionReadyCount${searchString}`
      }

      if (type === "ReadyActionOverdueCount") {
        return `api/Report/Widget/Actions/ActionReadyOverdueCount${searchString}`
      }
    }
  },
  getEntityContacts: {
    method: 'GET',
    url: ({ pageNumber, pageSize, contactName }) => {
      const searchString = getContactListFormattedQueryPayload({ pageNumber, pageSize, contactName })
      return `api/Organization/DDL/EntityContacts${searchString}`
    }
  },
  updateBulkAssignActionItems: {
    method: 'PUT',
    url: ({ contactId }) => `api/WorkFlow/BulkAssignActionItems/${contactId}`
  },
  getSignOffReasonVM: {
    method: "GET",
    url: "api/WorkFlow/Action/vm/SignOffReason"
  },
  updateSignOff: {
    method: 'PUT',
    url: ({ wfActionId }) => `api/WorkFlow/SignOff/${wfActionId}`
  },
  updateReassignSignOff: {
    method: 'PUT',
    url: ({ wfActionId, contactId }) => `api/WorkFlow/SignOff/Reassign/${wfActionId}/${contactId}`
  },
  updateBulkVerificationAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkVerificationActionItems'
  },
  updateBulkConfirmSiteVisitAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkConfirmSiteVisitActionItems'
  },
  updateBulkCorrectionAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkCorrectionActionItems'
  },
  updateBulkAssignCorrectionAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkAssignCorrectionActionItems'
  },
  updateBulkSignOffAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkSignOffActionItems'
  },
  updateBulkAssignSignOffAction: {
    method: 'PUT',
    url: 'api/WorkFlow/BulkAssignSignOffActionItems'
  }
}

export default apiConfig
