// ** React Imports
import classNames from 'classnames'
import { Fragment, useEffect, useRef, useMemo } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, ChevronRight } from 'react-feather'
import {
  Card,
  CardHeader,
  CardTitle,
  Input,
  Row,
  Col,
  Button
} from 'reactstrap'
import PropTypes from 'prop-types'
import { isPermissionDenied } from '@router/Helper'
import { DEFAULT_TABLE_PAGE_SIZE, TABLE_SCROLL_CONFIG, PERMISSION_ROUTES } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { pageSizeList } from '@shared/tables'
import { getUniqueId, getLocaleMessage } from '@utils'
import { ActionsDropdown, DataTableLoader, ReactSelect, CustomLabel } from '@views/components'

import AgDataGridComponent from './ag-data-grid'
import { customStyles, defaultTableComponentProps, getTableViewActionConfig, EXPANDABLE_TYPE, TABLE_VIEW_ACTION_TYPE } from './config'
import ExpandabeActivityChildDetail from './expandable-activity-child-detail'
import LocaleMessageWrapper from '../locale-message'
import CustomBulkActions from './CustomBulkActions'
import CustomCheckbox from './CustomCheckbox'
import CustomPagination from './CustomPagination'
import ExpandablePlans from './ExpandablePlans'
import ExpandableRowsTable from './ExpandableRowsTable'
import ExpandableUnits from './ExpandableUnits'
import ExpandableCheckpointTable from './ExpandableCheckpointTable'
import ExpandableTemplateActivityList from './ExpandableTemplateActivityList'
import ExpandableChecklistActivityList from './ExpandableChecklistActivityList'
import ExpandableContractsOrderDetailsList from './ExpandableContractsOrderDetailsList'
import ExpandableOrganisationPlanBlueprintList from './ExpandableOrganisationPlanBlueprintList'
import ExpandableOrganisationPlanList from './ExpandableOrganisationPlanList'
import ExpandableInspectionDetailsChildDetail from './expandable-activity-child-detail/ExpandableInspectionDetailsChildDetail'
import ExpandableServiceTable from './ExpandableServiceTable'
import ExpandableTrainingCampaignReportList from './ExpandableTrainingCampaignReportList'
import ExpandableTrainingReportList from './ExpandableTrainingReportList'
import ExpandableUnacceptableActivitiesReportList from './ExpandableUnacceptableActivitiesReportList'
import ExpandableUnitCloseOutReportList from './ExpandableUnitCloseOut'
import GridFilters from './grid-filters'
import ListHeader from "./ListHeader"
import NoDataComponent from "./NoDataComponent"
import ScrollToLoadTable from './scroll-to-load-table-view'
import SearchFilter from "./SearchFilter"
// const ExpandableChecklistTable = lazy(() => import('./ExpandableChecklistTable'))

const canAddPermission = (label) => {
  const btnTitle = label.toLowerCase()
  let permission = ''
  if (btnTitle.includes('plan')) {
    permission = 'Plan'
  } else if (btnTitle.includes('role')) {
    permission = 'Role'
  } else if (btnTitle.includes('unit')) {
    permission = 'Unit'
  } else if (btnTitle.includes('site')) {
    permission = 'Site'
  }

  return !(permission && isPermissionDenied(PERMISSION_ROUTES[`create${permission}`]))
}

const TableComponent = ({
  intl,
  children = null,
  titleWarpperClassname = '',
  className = '',
  clearSelectedRows = false,
  clearSelectedChildRows = false,
  tableViewAdvanceSearchRequired = true,
  isExportRequired = false,
  isSearchFilterRequired = true,
  columns = [],
  data = [],
  subData = [],
  expandableRowData = {},
  pathDetails = null,
  isChildGrid = false,
  isProjectsFilterComponent = false,
  isTableMarginRequired = true,
  isModal = false,
  isRefreshGrid = false,
  bulkActionsConfig,
  bulkActionsList,
  isShowBulkActions = false,
  isControlledBulkActions = false,
  isDeleteBulkAction,
  isBulkAlertActionRequired = false,
  isSubTable = false,
  editableTable = false,
  expandableKey = '',
  expandableRows = false,
  expandedRowId = '',
  expandableRowsType = EXPANDABLE_TYPE.TABLE,
  genericSearchRequired = true,
  genericSearch = '',
  isLoading = false,
  isLoadingChildGrid = false,
  isPersistTableHead = true,
  keyField = '',
  leftAlignHeaderChildren = false,
  noTableDataClassRequired = true,
  noDataMessage = '',
  noDataMessageForSubTable = '',
  pagination = true,
  showExpandedPagination = false,
  pageNumber = 0,
  pageSize = 0,
  defaultPageSize = 0,
  selectableRows = true,
  subColumns = [],
  subColumnConfigType,
  subKeyField = '',
  tableViewHeaderRequired = true,
  tableHeaderRequired = true,
  pageSizeFilterRequired = true,
  tableFiltersRequired = true,
  title = '',
  totalCount = 0,
  totalPages = 0,
  headerConfig = {},
  addButtonLabel = "",
  tableSubHeader,
  hasCloseAction = false,
  isChildHeaderRequired = false,
  isChildGridRefreshed = false,
  hasMultipleActions = false,
  sortServer = false,
  isDisableDeleteAllAction = false,
  isDisableDeleteAllChildTableAction = false,
  scrollToLoadRequired = false,
  showAgGrid = false,
  hasNextPage = false,
  selectedRows = [],
  customStyleProps = {},
  hasNoData = false,
  noDataComponentProps = {},
  tableComponentProps: tableComponentPropsList = {},
  isNewListTheme = false,
  conditionalRowStyles,
  rowSelectionType = 'single',
  isColumnMenuRequired = false,
  gridFilterProps = {},
  onZoomImage = () => { },
  expandableRowExpanded = () => { },
  onAddNew = () => { },
  onAddVersion = () => { },
  onClone = () => { },
  onBulkDelete = () => { },
  onCheckIfShowBulkAlert = () => { },
  onClose = () => { },
  onRowClicked = () => { },
  onOpenNewTabClicked = () => { },
  onResetChildGridRefreshed = () => { },
  onKeyDown = () => { },
  onChangeSearchFilter = () => { },
  onToggleAdvanceSearch = () => { },
  onGetGridRef = () => { },
  onPagination = () => { },
  onPerPageRecords = () => { },
  onRowExpandToggled = () => { },
  onSaveAndContiue = () => { },
  onSaveAndClose = () => { },
  onSelectedRowsChange = () => { },
  onExportList = () => { },
  onSort = () => { },
  onSelectableRowDisabled = () => { },
  onSelectableRowSelected = () => { },
  TableHeaderComponent = () => { return null },
  onChildSelectedRowsChange = () => { },
  onChildBulkDelete = () => { },
  onCsvExport = () => { },
  onSelectActivityType = () => { },
  onSaveBulkList = () => { },
  onSelectFilter = () => { },
  onToggleViewMode = () => { },
  onFirstRenderCheckboxSelection = () => { }
}) => {

  const gridRef = useRef()

  const tableComponentProps = useMemo(
    () => ({
      ...defaultTableComponentProps,
      ...tableComponentPropsList
    }),
    [tableComponentPropsList]
  )

  useEffect(() => {
    if (!hasNoData) {
      onGetGridRef(gridRef)
    }
  }, [hasNoData])

  const quickFilterConfig = useMemo(
    () => tableComponentProps.quickFilterConfig || {},
    [tableComponentProps.quickFilterConfig]
  )

  if (isNewListTheme && hasNoData) {
    return <NoDataComponent {...noDataComponentProps} />
  }

  let pageSizeOptions = []
  if (tableViewHeaderRequired) {
    pageSizeOptions = pageSizeList.filter(value => totalCount >= value)
  }

  const customIconPositionClassName = selectableRows && expandableRows ? 'rdt-icon-custom-position' : ''

  let childGridPaginationProps = {}
  let startIndex = 0
  let endIndex = 0
  if (pagination) {
    if (!isChildGrid) {
      startIndex = pageNumber > 1 ? 1 + ((pageNumber - 1) * pageSize) : 1
      const expectedLastIndex = startIndex + pageSize - 1
      endIndex = expectedLastIndex <= totalCount ? expectedLastIndex : totalCount
    } else {
      childGridPaginationProps = {
        paginationTotalRows: totalCount,
        paginationComponentOptions: {
          rowsPerPageText: ''
        },
        onChangePage: onPagination
      }
    }
  }

  return (
    <Fragment>
      <Card
        className={classNames({
          "no-data-table": !isLoading && noTableDataClassRequired && !totalCount,
          "show-row-action-records": !!totalCount && totalCount <= 4 && isTableMarginRequired,
          "multi-action-rows": hasMultipleActions,
          "no-table-border-bottom": isLoading,
          "child-table": isChildGrid,
          "nt-rdt-list w-100": isNewListTheme
        })}
      >
        {!isNewListTheme && tableViewHeaderRequired && <>
          <CardHeader className={classNames("flex-md-row flex-row align-items-center align-items-start py-1", {
            'border-bottom': !isNewListTheme
          })}>
            <div className={classNames(isProjectsFilterComponent ? "d-flex align-items-center template-detail" : "d-flex align-items-center template-detail csv-added", titleWarpperClassname)}>
              <CardTitle tag='h4'>{title}</CardTitle>
              {headerConfig.reactSelect && <div className="col-xs-12 col-md-6 col-xl-4">
                <ReactSelect
                  name={headerConfig.reactSelect.name}
                  control={headerConfig.reactSelect.control}
                  value={headerConfig.reactSelect.value}
                  options={headerConfig.reactSelect.options}
                  placeHolder={headerConfig.reactSelect.placeHolder}
                  defaultValue={headerConfig.reactSelect.defaultValue}
                  onSelect={(selected) => {
                    headerConfig.reactSelect.onSelect(headerConfig.reactSelect.id, selected?.value || '')
                  }}
                />
              </div>}
              {leftAlignHeaderChildren && children}
            </div>

            {((isNewListTheme && tableComponentProps.isShowAddButton) || (canAddPermission(addButtonLabel) && editableTable)) && (
              <div>
                <Button
                  className={classNames("common-button", {
                    'primary-solid': isNewListTheme,
                    'ml-2': !isNewListTheme
                  })}
                  onClick={onAddNew}
                >
                  {tableComponentProps.isShowAddButton ? getLocaleMessage(intl, tableComponentProps.addButtonLocaleId || LANGUAGE_CONSTANTS.ADD) : addButtonLabel}
                </Button>
              </div>
            )}
            {!leftAlignHeaderChildren && children}
            <TableHeaderComponent />
            {(isModal || hasCloseAction) && <div className="d-flex">
              {hasCloseAction &&
                <Button
                  outline
                  className="mr-2 delete-button"
                  onClick={onClose}
                >
                  {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CLOSE)}
                </Button>
              }
              {isModal &&
                <ActionsDropdown
                  footerMarginClassRequired={false}
                  primaryActionId="save"
                  primaryActionLabel={TABLE_VIEW_ACTION_TYPE.SAVE_CLOSE}
                  actionConfig={getTableViewActionConfig(onSaveAndContiue)}
                  onClickPrimary={onSaveAndClose}
                />
              }
            </div>}
          </CardHeader>
          {!!tableFiltersRequired && <Row className='mx-1  my-1 sort-label'>
            {!!pageSizeFilterRequired && <Col className="sort-content" sm='4'>
              <div className='d-flex align-items-center sort-select'>
                <CustomLabel title={LANGUAGE_CONSTANTS.TABLE.SHOW} forValue='sort-select' />
                <Input
                  className='dataTable-select'
                  type='select'
                  id='sort-select'
                  value={pageSize}
                  onChange={onPerPageRecords}
                >
                  {pageSizeOptions.map(value => <option value={value} key={`page_size_${getUniqueId()}`}>{value}</option>)}
                  {pageSizeOptions.indexOf(defaultPageSize || DEFAULT_TABLE_PAGE_SIZE) === -1 && <option value={defaultPageSize || DEFAULT_TABLE_PAGE_SIZE}>{defaultPageSize || DEFAULT_TABLE_PAGE_SIZE}</option>}
                </Input>
              </div>
            </Col>}
            {
              !!isSearchFilterRequired && <SearchFilter
                genericSearch={genericSearch}
                genericSearchRequired={genericSearchRequired}
                isNewListTheme={isNewListTheme}
                pageSizeFilterRequired={pageSizeFilterRequired}
                tableViewAdvanceSearchRequired={tableViewAdvanceSearchRequired}
                onChangeSearchFilter={onChangeSearchFilter}
                onKeyDown={onKeyDown}
                onToggleAdvanceSearch={onToggleAdvanceSearch}
              />
            }
          </Row>}
        </>}
        {isNewListTheme && tableViewHeaderRequired &&
          <>
            {(isModal || hasCloseAction) && <div className="d-flex justify-content-end">
              {hasCloseAction &&
                <Button
                  outline
                  className="mr-2 delete-button"
                  onClick={onClose}
                >
                  {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CLOSE)}
                </Button>
              }
              {isModal &&
                <ActionsDropdown
                  footerMarginClassRequired={false}
                  primaryActionId="save"
                  isDisabled={tableComponentProps?.isDisabled || false}
                  primaryActionLabel={TABLE_VIEW_ACTION_TYPE.SAVE_CLOSE}
                  actionConfig={getTableViewActionConfig(onSaveAndContiue)}
                  onClickPrimary={onSaveAndClose}
                />
              }
            </div>}
            <ListHeader
              genericSearch={genericSearch}
              genericSearchRequired={genericSearchRequired}
              isSearchFilterRequired={isSearchFilterRequired}
              pageSizeFilterRequired={pageSizeFilterRequired}
              pageSize={pageSize}
              tableViewAdvanceSearchRequired={tableViewAdvanceSearchRequired}
              tableComponentProps={tableComponentProps}
              title={title}
              exportRef={gridRef}
              onAddNew={onAddNew}
              onAddVersion={onAddVersion}
              onClone={onClone}
              onChangeSearchFilter={onChangeSearchFilter}
              onExportList={onExportList}
              onKeyDown={onKeyDown}
              onPerPageRecords={onPerPageRecords}
              onToggleAdvanceSearch={onToggleAdvanceSearch}
              onCsvExport={onCsvExport}
              onSelectActivityType={onSelectActivityType}
              onSaveBulkList={onSaveBulkList}
              onSelectFilter={onSelectFilter}
              onToggleViewMode={onToggleViewMode}
            />
           {(!!tableComponentProps.customMessage || tableComponentProps.isShowRowCount) &&  <div className='d-flex justify-content-end'>
              {!!tableComponentProps.customMessage && <h5 className={classNames("list-custom-message mr-1", tableComponentProps.customMessageClass)}>
                {!!tableComponentProps.customMessageIconSrc && <img src={tableComponentProps.customMessageIconSrc} className="message-icon" />}
                {tableComponentProps.customMessage}
              </h5>}
              {tableComponentProps.isShowRowCount && <h5 className="row-count">{totalCount ? `${totalCount} ${getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.ROWS)}` : ''}</h5>}
            </div>}
          </>
        }
        {!!quickFilterConfig.listFilterComponent && quickFilterConfig.listFilterComponent()}
        <GridFilters {...gridFilterProps} isLeftMarginRequired={!isNewListTheme} />
        {tableComponentProps.isShowCustomTitle && <h4 className="list-title">{getLocaleMessage(intl, tableComponentProps.customTitleLocaleId)}</h4>}
        {!!tableComponentProps.listSecondaryActionsViewComponent && tableComponentProps.listSecondaryActionsViewComponent()}
        <CustomBulkActions
          bulkActionsConfig={bulkActionsConfig}
          bulkActionsList={bulkActionsList}
          isBulkAlertActionRequired={isBulkAlertActionRequired}
          isControlledBulkActions={isControlledBulkActions}
          isDeleteBulkAction={isDeleteBulkAction}
          isDisableDeleteAllAction={isDisableDeleteAllAction}
          isShow={isShowBulkActions}
          onCheckIfShowBulkAlert={onCheckIfShowBulkAlert}
          onDelete={onBulkDelete}
        />
        {!showAgGrid && scrollToLoadRequired ? (
          <ScrollToLoadTable
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            expandableRows={expandableRows}
            selectableRows={selectableRows}
            columns={columns}
            className={className}
            data={data}
            keyField={keyField}
            noDataMessage={noDataMessage}
            expandableRowsType={expandableRowsType}
            pageNumber={pageNumber}
            sortServer={sortServer}
            selectedRows={selectedRows}
            totalCount={totalCount}
            onPagination={onPagination}
            expandedRowId={expandedRowId}
            onRowExpandToggled={onRowExpandToggled}
            isLoadingChildGrid={isLoadingChildGrid}
            subData={subData}
            noDataMessageForSubTable={noDataMessageForSubTable}
            tableHeaderRequired={tableHeaderRequired}
            onSelectedRowsChange={onSelectedRowsChange}
            onSelectableRowDisabled={onSelectableRowDisabled}
            onSort={onSort}
          />
        ) : (
          <>
            {
              isNewListTheme && showAgGrid ?
                <AgDataGridComponent
                  intl={intl}
                  gridRef={gridRef}
                  className={className}
                  isLoading={isLoading}
                  columns={columns}
                  data={data}
                  pageSize={pageSize}
                  selectedRows={selectedRows}
                  selectableRows={selectableRows}
                  onSelectedRowsChange={onSelectedRowsChange}
                  onSelectableRowDisabled={onSelectableRowDisabled}
                  keyField={keyField}
                  expandedRowId={expandedRowId}
                  subData={subData}
                  pagination={pagination}
                  rowSelection={rowSelectionType}
                  tableComponentProps={tableComponentProps}
                  isLoadingChildGrid={isLoadingChildGrid}
                  expandableRows={expandableRows}
                  isColumnMenuRequired={isColumnMenuRequired}
                  isServerSide={scrollToLoadRequired}
                  pageNumber={pageNumber}
                  totalCount={totalCount}
                  isTableMarginRequired={isTableMarginRequired}
                  isClearSelectedRows={clearSelectedChildRows}
                  // noDataComponent={() => <div className="no-data-txt">{noDataMessage}</div>}
                  noDataMessage={noDataMessage}
                  noDataMessageForSubTable={noDataMessageForSubTable}
                  expandableRowsComponent={(gridProps) => (
                    (expandableRowsType === EXPANDABLE_TYPE.GENERAL_UNACCEPTABLE_ACTIVITIES) ? (<ExpandableUnacceptableActivitiesReportList
                      expandableRowData={expandableRowData}
                      onZoomImage={onZoomImage}
                      {...gridProps}
                    />) : (expandableRowsType === EXPANDABLE_TYPE.FAILED_ACTIVITIES_TRAINING_REPORT) ? (
                      <ExpandableTrainingReportList
                        expandableRowData={expandableRowData}
                        {...gridProps}
                      />
                    ) : (expandableRowsType === EXPANDABLE_TYPE.TRAINING_CAMPAIGN_REPORT) ? (
                      <ExpandableTrainingCampaignReportList
                        expandableRowData={expandableRowData}
                        {...gridProps}
                      />
                    ) : (expandableRowsType === EXPANDABLE_TYPE.UNIT_CLOSEOUT_REPORT) ? (
                      <ExpandableUnitCloseOutReportList
                        {...gridProps}
                      />
                    ) : (expandableRowsType === EXPANDABLE_TYPE.CHECKPOINTS) ? (
                      <ExpandableInspectionDetailsChildDetail
                        noDataMessage={noDataMessage}
                        {...gridProps} />
                    ) : (expandableRowsType === EXPANDABLE_TYPE.CONTRACTS_ORDER_DETAILS) ? (
                      <ExpandableContractsOrderDetailsList
                        noDataMessage={noDataMessage}
                        {...gridProps} />
                    ) : (expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH) ?
                      <ExpandableTemplateActivityList
                        expandableRowData={expandableRowData}
                        {...gridProps}
                      />
                      : expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS ?
                        <ExpandableChecklistActivityList
                          expandableRowData={expandableRowData}
                          {...gridProps}
                        /> : (expandableRowsType === EXPANDABLE_TYPE.ORGANISATION_PLAN_LIST) ? (
                          <ExpandableOrganisationPlanList
                            {...gridProps}
                          />
                        ) : (expandableRowsType === EXPANDABLE_TYPE.ORGANISATION_PLAN_BLUEPRINT_LIST) ? (
                          <ExpandableOrganisationPlanBlueprintList
                            {...gridProps}
                          />
                        ) : (
                          <ExpandableUnits noDataMessage={noDataMessageForSubTable} />
                        ))}
                  onRowClicked={onRowClicked}
                  onRowExpandToggled={onRowExpandToggled}
                  onOpenNewTabClicked={onOpenNewTabClicked}
                  onSort={onSort}
                  onPagination={onPagination}
                  onFirstRenderCheckboxSelection={onFirstRenderCheckboxSelection}
                /> :
                <DataTable
                  className={classNames('react-dataTable', className, customIconPositionClassName)}
                  clearSelectedRows={clearSelectedRows}
                  columns={columns}
                  customStyles={{ ...customStyles, ...customStyleProps }}
                  conditionalRowStyles={conditionalRowStyles}
                  data={data}
                  expandableRows={expandableRows}
                  expandableIcon={{
                    collapsed: <ChevronRight size={23} color={'gray'} />,
                    expanded: <ChevronDown size={23} color={'gray'} />
                  }}
                  expandOnRowClicked={expandableRows}
                  expandableRowsComponent={
                    (expandableRowsType === EXPANDABLE_TYPE.SITE_UNITS_TABLE ||
                      expandableRowsType === EXPANDABLE_TYPE.TABLE) ? (
                      <ExpandableRowsTable
                        columns={subColumns}
                        expandableKey={expandableKey}
                        expandedRowId={expandedRowId}
                        expandableRowsType={expandableRowsType}
                        isLoadingChildGrid={isLoadingChildGrid}
                        subData={subData}
                        isSubTable={isSubTable}
                        keyField={subKeyField}
                        noDataMessage={noDataMessageForSubTable}
                        isChildHeaderRequired={isChildHeaderRequired}
                        isChildGridRefreshed={isChildGridRefreshed}
                        isDisableDeleteAllChildTableAction={isDisableDeleteAllChildTableAction}
                        onResetChildGridRefreshed={onResetChildGridRefreshed}
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedChildRows={clearSelectedChildRows}
                        onChildSelectedRowsChange={onChildSelectedRowsChange}
                        onChildBulkDelete={onChildBulkDelete}
                      />
                    ) : expandableRowsType === EXPANDABLE_TYPE.PLANS ? (
                      <ExpandablePlans expandedRowId={expandedRowId} isLoadingChildGrid={isLoadingChildGrid} subData={subData} noDataMessage={noDataMessageForSubTable} />
                    ) : expandableRowsType === EXPANDABLE_TYPE.CHECKPOINTS ? (
                      <ExpandabeActivityChildDetail expandedRowId={expandedRowId} isLoadingChildGrid={isLoadingChildGrid} subData={subData} noDataMessage={noDataMessageForSubTable} />
                    ) : expandableRowsType === EXPANDABLE_TYPE.CAMPAIGN_SERVICE_LIST ? (
                      <ExpandableServiceTable
                        subData={subData}
                        noDataMessage={noDataMessageForSubTable}
                      />
                    )
                      // : expandableRowsType === EXPANDABLE_TYPE.CAMPAIGN_SERVICE_CHECKLIST ? (
                      //   <ExpandableChecklistTable
                      //     pathDetails={pathDetails}
                      //     subData={subData}
                      //     noDataMessage={noDataMessageForSubTable}
                      //   />
                      // ) 
                      : (expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH ||
                        expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS
                      ) ? (
                        <ExpandableCheckpointTable
                          expandableRowsType={expandableRowsType}
                          clearSelectedRows={clearSelectedChildRows}
                          expandedRowId={expandedRowId}
                          isRefreshGrid={isRefreshGrid}
                          subData={subData}
                          subColumnConfigType={subColumnConfigType}
                          noDataMessage={noDataMessageForSubTable}
                          onSelectedRowsChange={onSelectedRowsChange}
                        />
                      ) : (expandableRowsType === EXPANDABLE_TYPE.FAILED_ACTIVITIES_TRAINING_REPORT) ? (
                        <ExpandableTrainingReportList
                          expandedRowId={expandedRowId}
                          isLoadingChildGrid={isLoadingChildGrid}
                          subData={subData}
                          noDataMessage={noDataMessageForSubTable}
                        />
                      ) : (expandableRowsType === EXPANDABLE_TYPE.GENERAL_UNACCEPTABLE_ACTIVITIES) ? (
                        <ExpandableUnacceptableActivitiesReportList
                          expandedRowId={expandedRowId}
                          isLoadingChildGrid={isLoadingChildGrid}
                          subData={subData}
                          noDataMessage={noDataMessageForSubTable}
                        />
                      ) : (expandableRowsType === EXPANDABLE_TYPE.TRAINING_CAMPAIGN_REPORT) ? (
                        <ExpandableTrainingCampaignReportList
                          expandedRowId={expandedRowId}
                          isLoadingChildGrid={isLoadingChildGrid}
                          subData={subData}
                          noDataMessage={noDataMessageForSubTable}
                        />
                      ) : (expandableRowsType === EXPANDABLE_TYPE.UNIT_CLOSEOUT_REPORT) ? (
                        <ExpandableUnitCloseOutReportList
                          expandedRowId={expandedRowId}
                          isLoadingChildGrid={isLoadingChildGrid}
                          subData={subData}
                          noDataMessage={noDataMessageForSubTable}
                        />
                      ) :
                        (
                          <ExpandableUnits noDataMessage={noDataMessageForSubTable} />
                        )
                  }
                  fixedHeader
                  fixedHeaderScrollHeight={TABLE_SCROLL_CONFIG.fixedHeaderScrollHeight}
                  highlightOnHover
                  keyField={keyField}
                  noDataComponent={<div className="no-data-txt">{noDataMessage}</div>}
                  noHeader
                  {...childGridPaginationProps}
                  paginationServer
                  persistTableHead={isPersistTableHead}
                  pointerOnHover
                  progressPending={isLoading}
                  progressComponent={<DataTableLoader customProgress />}
                  selectableRows={selectableRows}
                  selectableRowsComponent={CustomCheckbox}
                  selectableRowsHighlight
                  sortIcon={<ChevronDown size={6} />}
                  sortServer={sortServer}
                  expandableRowExpanded={expandableRowExpanded}
                  onRowClicked={onRowClicked}
                  onRowExpandToggled={onRowExpandToggled}
                  onSelectedRowsChange={onSelectedRowsChange}
                  onSort={onSort}
                  selectableRowDisabled={onSelectableRowDisabled}
                />}
            {!isLoading && (showExpandedPagination || !isChildGrid) && pagination && !!data.length && <CustomPagination
              isNewListTheme={isNewListTheme}
              intl={intl}
              pageNumber={pageNumber}
              totalPages={totalPages}
              onPageChange={onPagination}
            />}
            {!isNewListTheme && !isLoading && !isChildGrid && pagination && !!data.length && <h5 className="pagination-txt">{`${getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.SHOWING)} ${startIndex} to ${endIndex || data.length} of ${totalCount || data.length} ${getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.ENTRIES)}`}</h5>}
          </>
        )}
      </Card >
    </Fragment >
  )
}

TableComponent.propTypes = {
  intl: PropTypes.object,
  titleWarpperClassname: PropTypes.string,
  className: PropTypes.string,
  clearSelectedRows: PropTypes.bool,
  clearSelectedChildRows: PropTypes.bool,
  tableViewAdvanceSearchRequired: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  subData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  expandableRowData: PropTypes.object,
  isChildGrid: PropTypes.bool,
  isTableMarginRequired: PropTypes.bool,
  isModal: PropTypes.bool,
  isRefreshGrid: PropTypes.bool,
  isSubTable: PropTypes.bool,
  editableTable: PropTypes.bool,
  expandableKey: PropTypes.string,
  expandableRows: PropTypes.bool,
  expandedRowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  expandableRowsType: PropTypes.string,
  genericSearchRequired: PropTypes.bool,
  genericSearch: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingChildGrid: PropTypes.bool,
  keyField: PropTypes.string,
  leftAlignHeaderChildren: PropTypes.bool,
  noTableDataClassRequired: PropTypes.bool,
  noDataMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  noDataMessageForSubTable: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  pagination: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  defaultPageSize: PropTypes.number,
  selectableRows: PropTypes.bool,
  onSelectableRowDisabled: PropTypes.func,
  onSelectableRowSelected: PropTypes.func,
  subColumns: PropTypes.array,
  subKeyField: PropTypes.string,
  tableViewHeaderRequired: PropTypes.bool,
  tableHeaderRequired: PropTypes.bool,
  pageSizeFilterRequired: PropTypes.bool,
  tableFiltersRequired: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  headerConfig: PropTypes.object,
  addButtonLabel: PropTypes.string,
  hasCloseAction: PropTypes.bool,
  isChildHeaderRequired: PropTypes.bool,
  isChildGridRefreshed: PropTypes.bool,
  hasMultipleActions: PropTypes.bool,
  sortServer: PropTypes.bool,
  scrollToLoadRequired: PropTypes.bool,
  selectedRows: PropTypes.array,
  bulkActionsConfig: PropTypes.object,
  bulkActionsList: PropTypes.arrayOf(PropTypes.object),
  isShowBulkActions: PropTypes.bool,
  isBulkAlertActionRequired: PropTypes.bool,
  isControlledBulkActions: PropTypes.bool,
  isDeleteBulkAction: PropTypes.bool,
  isDisableDeleteAllAction: PropTypes.bool,
  isExportRequired: PropTypes.bool,
  hasNoData: PropTypes.bool,
  noDataComponentProps: PropTypes.object,
  tableComponentProps: PropTypes.object,
  isNewListTheme: PropTypes.bool,
  isPersistTableHead: PropTypes.bool,
  gridFilterProps: PropTypes.object,
  expandableRowExpanded: PropTypes.func,
  onAddNew: PropTypes.func,
  onBulkDelete: PropTypes.func,
  onClose: PropTypes.func,
  onCheckIfShowBulkAlert: PropTypes.func,
  onRowClicked: PropTypes.func,
  onOpenNewTabClicked: PropTypes.func,
  onResetChildGridRefreshed: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChangeSearchFilter: PropTypes.func,
  onToggleAdvanceSearch: PropTypes.func,
  onPagination: PropTypes.func,
  onPerPageRecords: PropTypes.func,
  onRowExpandToggled: PropTypes.func,
  onSaveAndContiue: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  onExportList: PropTypes.func,
  onSort: PropTypes.func,
  TableHeaderComponent: PropTypes.func,
  onSelectView: PropTypes.func,
  onZoomImage: PropTypes.func
}

export default LocaleMessageWrapper(TableComponent)