import { LocaleMessage } from "@views/components"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getOverviewCreatedByContactInfoComponent, getOverviewUpdatedByContactInfoComponent, getOverviewNavigationLink } from "@views/helpers"
import { REPORTS_TAB_TYPES } from "@views/reports/overview/config"

const {
  ADDRESS: { PRIMARY_PHONE },
  CONTACTS: { ORGANIZATION },
  ORGANIZATIONS: { EXTERNAL_ID, ORG_ID, ORGANIZATION_TYPE, TABLE_COLUMNS },
  OVERVIEW_SECTIONS,
  OVERVIEW_PAGE_SECTIONS: { ORGANIZATION_OVERVIEW_TAB, CAMPAIGN_OVERVIEW_TAB }
} = LANGUAGE_CONSTANTS

export const cardConfig = [
  [
    {
      label: <LocaleMessage id={ORGANIZATION} />,
      keyName: "organizationName"
    },
    {
      label: <LocaleMessage id={ORG_ID} />,
      keyName: "organizationId"
    },
    {
      label: <LocaleMessage id={TABLE_COLUMNS.LOCATION} />,
      keyName: "address"
    },
    {
      label: <LocaleMessage id={ORGANIZATION_TYPE} />,
      keyName: "orgType"
    }
  ],
  [
    {
      label: <LocaleMessage id={TABLE_COLUMNS.CATEGORY} />,
      keyName: "orgCategory"
    },
    {
      label: <LocaleMessage id={TABLE_COLUMNS.PARENT} />,
      keyName: "organizationParentName"
    },
    {
      label: <LocaleMessage id={PRIMARY_PHONE} />,
      keyName: "primaryPhone"
    },
    {
      label: <LocaleMessage id={EXTERNAL_ID} />,
      keyName: "externalId"
    }
  ]
]

export const SECTION_TAB_TYPES = {
  OVERVIEW: 1,
  DIRECTORY: 2,
  SITES: 3,
  RESOURCES: 4,
  INTERNAL_THREAD: 5,
  EXTERNAL_THREAD: 6,
  PLAN: 7,
  DATA_ACCESS: 8,
  PROCTORS: 9
}

export const overviewSectionTabs = [
  { id: SECTION_TAB_TYPES.OVERVIEW, name: OVERVIEW_SECTIONS.OVERVIEW },
  { id: SECTION_TAB_TYPES.DIRECTORY, name: OVERVIEW_SECTIONS.DIRECTORY },
  { id: SECTION_TAB_TYPES.SITES, name: OVERVIEW_SECTIONS.SITES },
  { id: SECTION_TAB_TYPES.RESOURCES, name: OVERVIEW_SECTIONS.FILES },
  { id: SECTION_TAB_TYPES.INTERNAL_THREAD, name: OVERVIEW_SECTIONS.NOTES },
  { id: SECTION_TAB_TYPES.PLAN, name: OVERVIEW_SECTIONS.PLAN },
  { id: SECTION_TAB_TYPES.DATA_ACCESS, name: OVERVIEW_SECTIONS.DATA_ACCESS },
  { id: SECTION_TAB_TYPES.PROCTORS, name: OVERVIEW_SECTIONS.PROCTORS }
  // { id: SECTION_TAB_TYPES.EXTERNAL_THREAD, name: OVERVIEW_SECTIONS.EXTERNAL_THREAD }
]

export const organizationReportTabConfig = [REPORTS_TAB_TYPES.DAILY_INSPECTION]

export const detailCardConfig = {
  col1: {
    md: 5,
    columnConfig: [
      {
        labelLocaleId: ORGANIZATION_OVERVIEW_TAB.TYPE,
        keyName: "type"
      },
      {
        labelLocaleId: ORGANIZATION_OVERVIEW_TAB.CATEGORY,
        keyName: "category"
      }
    ]
  },
  col2: {
    md: 4,
    columnConfig: [
      {
        labelLocaleId: ORGANIZATION_OVERVIEW_TAB.PARENT,
        keyName: "parent",
        isValueUnderline: true,
        isNavigationLink: true,
        navigationRoute: 'parentOrganization',
        getNavigationLink: getOverviewNavigationLink
      },
      {
        labelLocaleId: ORGANIZATION_OVERVIEW_TAB.ADDRESS,
        keyName: "address",
        isAddressView: true
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.CREATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewCreatedByContactInfoComponent
      },
      {
        labelLocaleId: CAMPAIGN_OVERVIEW_TAB.UPDATED_BY,
        isFormattedValue: true,
        getFormattedValue: getOverviewUpdatedByContactInfoComponent
      }
    ]
  }
}

export const PERCENTAGE_DIFFERENCE_STATUS_TYPE = {
  SUCCESS: "success",
  DANGER: "danger"
}

export const POWER_BI_WIDGETS = {
  CONSTRUCTION_CALLBACKS: 1,
  CYCLE_TIME_REDUCTION: 2,
  WARRANTY_COST: 3,
  WARRANTY_CLAIM: 3,
  CORRECTION_COSTS: 1,
  QUALITY_SCORE: 4,
  AVG_ACTIVITY_CYCLE_TIME: 5,
  AVG_FAILED_ACTIVITIES: 6
}

export const REPORTS_ROUTE_NAME_TYPES = {
  UNACCEPTABLE: 'unacceptable-activities',
  TRAINING: 'training-activities',
  TRAINING_CAMPAIGN: 'training-campaign-activities',
  UNITS_CLOSEOUT: 'units-closeout'
}

export const DIRECTORY_TYPES = {
  EXTERNAL_CONTACT: "External Contact",
  INTERNAL_CONTACT: "Internal Contact"
}

export const CORD_GROUPS = ['internal', 'external']