export const GET_PROJECT_LIST_REQUEST = 'GET_PROJECT_LIST_REQUEST'
export const GET_CREATE_PROJECT_VM_REQUEST = 'GET_CREATE_PROJECT_VM_REQUEST'
export const GET_AUTHORITY_NAME_REQUEST = 'GET_AUTHORITY_NAME_REQUEST'
export const ADD_PROJECT_DETAILS_REQUEST = 'ADD_PROJECT_DETAILS_REQUEST'
export const GET_PROJECT_DETAILS_REQUEST = 'GET_PROJECT_DETAILS_REQUEST'
export const UPDATE_PROJECT_DETAILS_REQUEST = 'UPDATE_PROJECT_DETAILS_REQUEST'
export const ADD_PROJECT_ADDRESS_REQUEST = 'ADD_PROJECT_ADDRESS_REQUEST'
export const GET_PROJECT_ADDRESS_REQUEST = 'GET_PROJECT_ADDRESS_REQUEST'
export const UPDATE_PROJECT_ADDRESS_REQUEST = 'UPDATE_PROJECT_ADDRESS_REQUEST'
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_LOCATION_REQUEST = 'DELETE_PROJECT_LOCATION_REQUEST'
export const GET_PROJECT_LIST_VM_REQUEST = 'GET_PROJECT_LIST_VM_REQUEST'
export const GET_PROJECT_SITE_SEARCH_REQUEST = 'GET_PROJECT_SITE_SEARCH_REQUEST'
export const GET_SITE_ROLE_ASSIGNMENT_DDL_REQUEST = 'GET_SITE_ROLE_ASSIGNMENT_DDL_REQUEST'
export const GET_PROJECT_SITE_DDL_REQUEST = 'GET_PROJECT_SITE_DDL_REQUEST'
export const GET_SITE_UNITS_REQUEST = 'GET_SITE_UNITS_REQUEST'
export const GET_CONTACT_PROJECT_LIST_REQUEST = 'GET_CONTACT_PROJECT_LIST_REQUEST'
export const GET_PROJECTS_FILTERED_ORGANIZATIONS = 'GET_PROJECTS_FILTERED_ORGANIZATIONS'
export const ADD_PROJECT_SITE_QA_TAG_REQUEST = "ADD_PROJECT_SITE_QA_TAG_REQUEST"
export const DELETE_PROJECT_SITE_QA_TAG_REQUEST = "DELETE_PROJECT_SITE_QA_TAG_REQUEST"
export const GET_HOME_SITE_DDL_REQUEST = 'GET_HOME_SITE_DDL_REQUEST'
export const VALIDATE_AUTO_GENERATED_UNIT_NAMES_REQUEST = 'VALIDATE_AUTO_GENERATED_UNIT_NAMES_REQUEST'
export const GET_CONTACT_SITE_ROLE_LIST_REQUEST = 'GET_CONTACT_SITE_ROLE_LIST_REQUEST'
export const GET_SITE_PARTY_ROLE_VM_REQUEST = 'GET_SITE_PARTY_ROLE_VM_REQUEST'
export const DELETE_SITE_ROLES_REQUEST = 'DELETE_SITE_ROLES_REQUEST'
export const ADD_SITE_ROLES_REQUEST = 'ADD_SITE_ROLES_REQUEST'
export const GET_SITE_UNLINKED_SHARED_CAMPAIGNS_REQUEST = 'GET_SITE_UNLINKED_SHARED_CAMPAIGNS_REQUEST'
export const GET_SITE_CAMPAIGNS_MISSING_SITE_ROLES_REQUEST = 'GET_SITE_CAMPAIGNS_MISSING_SITE_ROLES_REQUEST'
export const ADD_SHARED_CAMPAIGNS_TO_SITE_REQUEST = 'ADD_SHARED_CAMPAIGNS_TO_SITE_REQUEST'
export const GET_TASK_LIST_TO_BE_CREATED_REQUEST = 'GET_TASK_LIST_TO_BE_CREATED_REQUEST'
export const CREATE_TASKS_ON_DEMAND_REQUEST = 'CREATE_TASKS_ON_DEMAND_REQUEST'

/****************************************************/
/** GET_PROJECT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProjectListRequest : To get project list
 *
 */
export const getProjectListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_DETAILS **/
/****************************************************/

/**
 * @method getProjectDetailsRequest :  To get details of Project
 *
 */
export const getProjectDetailsRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_DETAILS **/
/****************************************************/

/**
 * @method addProjectDetailsRequest :  To add details of Project
 *
 */
export const addProjectDetailsRequest = (payload, callback) => {
  return {
    type: ADD_PROJECT_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_DETAILS **/
/****************************************************/

/**
 * @method updateProjectDetailsRequest :  To update details of Project
 *
 */
export const updateProjectDetailsRequest = (payload, callback) => {
  return {
    type: UPDATE_PROJECT_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_ADDRESS **/
/****************************************************/

/**
 * @method getProjectAddressRequest :  To get address detail of Project
 *
 */
export const getProjectAddressRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_ADDRESS **/
/****************************************************/

/**
 * @method addProjectAddressRequest :  To add address detail of Project
 *
 */
export const addProjectAddressRequest = (payload, callback) => {
  return {
    type: ADD_PROJECT_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_ADDRESS **/
/****************************************************/

/**
 * @method updateProjectAddressRequest :  To update address detail of Project
 *
 */
export const updateProjectAddressRequest = (payload, callback) => {
  return {
    type: UPDATE_PROJECT_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CREATE_PROJECT_VM_REQUEST **/
/****************************************************/

/**
 * @method getCreateProjectVMRequest :  To get dropdowns list for project list
 *
 */
export const getCreateProjectVMRequest = (callback = () => { }) => {
  return {
    type: GET_CREATE_PROJECT_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** DELETE_PROJECT **/
/****************************************************/

/**
 * @method deleteProjectRequest : To delete Project
 *
 */
export const deleteProjectRequest = (payload, callback) => {
  return {
    type: DELETE_PROJECT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_AUTHORITY_NAME_REQUEST **/
/****************************************************/

/**
 * @method getAuthorityNameRequest :  To get dropdown list for AuthorityNames
 *
 */
export const getAuthorityNameRequest = (payload, callback = () => { }) => {
  return {
    type: GET_AUTHORITY_NAME_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_PROJECT_LOCATION **/
/****************************************************/

/**
 * @method deleteProjectLocationRequest : To delete Project Location
 *
 */
export const deleteProjectLocationRequest = (payload, callback) => {
  return {
    type: DELETE_PROJECT_LOCATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getProjectListVMRequest :  To get dropdowns list for project list
 *
 */
export const getProjectListVMRequest = (callback = () => { }) => {
  return {
    type: GET_PROJECT_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_SEARCH **/
/****************************************************/

/**
 * @method getProjectSiteSearchRequest :  To get project site search dropdown
 *
 */
export const getProjectSiteSearchRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_SITE_SEARCH_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_ROLE_ASSIGNMENT_DDL **/
/****************************************************/

/**
 * @method getSiteRoleAssignmentDDLRequest :  To get site role assignment list
 *
 */
export const getSiteRoleAssignmentDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SITE_ROLE_ASSIGNMENT_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteDDLRequest : To Get Paginated site DDL without depending on project Id
 *
 */
export const getProjectSiteDDLRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_SITE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_UNITS_REQUEST **/
/****************************************************/

/**
 * @method getSiteUnitsRequest : To Get Paginated site units DDL
 *
 */
export const getSiteUnitsRequest = (payload, callback) => {
  return {
    type: GET_SITE_UNITS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_PROJECT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getContactProjectListRequest : To get contact project list
 *
 */
export const getContactProjectListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_PROJECT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECTS_FILTERED_ORGANIZATIONS **/
/****************************************************/

/**
 * @method getProjectsFilteredOrganizationsRequest : To get projects filtered organization list for dropdowns
 *
 */
export const getProjectsFilteredOrganizationsRequest = (payload, callback) => {
  return {
    type: GET_PROJECTS_FILTERED_ORGANIZATIONS,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_SITE_QA_TAG_REQUEST **/
/****************************************************/

/**
 * @method addProjectSiteQATagRequest : To add new site qa tag
 *
 */
export const addProjectSiteQATagRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_PROJECT_SITE_QA_TAG_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_PROJECT_SITE_QA_TAG_REQUEST **/
/****************************************************/

/**
 * @method deleteProjectSiteQATagRequest : To delete site qa tag
 *
 */
export const deleteProjectSiteQATagRequest = (payload, callback = () => { }) => {
  return {
    type: DELETE_PROJECT_SITE_QA_TAG_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_HOME_SITE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getHomeSiteDDLRequest : To Get Paginated site DDL without depending on project Id
 *
 */
export const getHomeSiteDDLRequest = (payload, callback) => {
  return {
    type: GET_HOME_SITE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** VALIDATE_AUTO_GENERATED_UNIT_NAMES_REQUEST **/
/****************************************************/

/**
 * @method validateAutoGeneratedUnitNamesRequest : To validate auto generated unit names
 *
 */
export const validateAutoGeneratedUnitNamesRequest = (payload, callback = () => { }) => {
  return {
    type: VALIDATE_AUTO_GENERATED_UNIT_NAMES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_SITE_ROLE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getContactSiteRoleListRequest : To get contact site role list
 *
 */
export const getContactSiteRoleListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_SITE_ROLE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_PARTY_ROLE_VM_REQUEST **/
/****************************************************/

/**
 * @method getSitePartyRoleVMRequest : To Get Paginated site party role DDL without depending on any params
 *
 */
export const getSitePartyRoleVMRequest = (payload, callback) => {
  return {
    type: GET_SITE_PARTY_ROLE_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_SITE_ROLES_REQUEST **/
/****************************************************/

/**
 * @method deleteSiteRolesRequest : To delete single and multiple site roles
 *
 */
export const deleteSiteRolesRequest = (payload, callback) => {
  return {
    type: DELETE_SITE_ROLES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_SITE_ROLES_REQUEST **/
/****************************************************/

/**
 * @method addSiteRolesRequest : To add site roles
 *
 */
export const addSiteRolesRequest = (payload, callback) => {
  return {
    type: ADD_SITE_ROLES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_UNLINKED_SHARED_CAMPAIGNS_REQUEST **/
/****************************************************/

/**
 * @method getSiteUnLinkedSharedCampaignsRequest : To get sites unlinked shared campaigns
 *
 */
export const getSiteUnLinkedSharedCampaignsRequest = (payload, callback) => {
  return {
    type: GET_SITE_UNLINKED_SHARED_CAMPAIGNS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_CAMPAIGNS_MISSING_SITE_ROLES_REQUEST **/
/****************************************************/

/**
 * @method getSiteCampaignsMissingSiteRolesRequest :  To get the site campaigns missing site roles
 *
 */
export const getSiteCampaignsMissingSiteRolesRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SITE_CAMPAIGNS_MISSING_SITE_ROLES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_SHARED_CAMPAIGNS_TO_SITE_REQUEST **/
/****************************************************/

/**
 * @method addSharedCampaignsToSiteRequest :  To add shared campaigns to site
 */
export const addSharedCampaignsToSiteRequest = (payload, callback) => {
  return {
    type: ADD_SHARED_CAMPAIGNS_TO_SITE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_LIST_TO_BE_CREATED_REQUEST **/
/****************************************************/

/**
 * @method getTaskListToBeCreatedRequest : To get task list to be created in units
 *
 */
export const getTaskListToBeCreatedRequest = (payload, callback) => {
  return {
    type: GET_TASK_LIST_TO_BE_CREATED_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_TASKS_ON_DEMAND_REQUEST **/
/****************************************************/

/**
 * @method createTasksOnDemandRequest :  To create tasks on demand in site units
 */
export const createTasksOnDemandRequest = (payload, callback) => {
  return {
    type: CREATE_TASKS_ON_DEMAND_REQUEST,
    payload,
    callback
  }
}
