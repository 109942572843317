export * from "./SearchHistory"
export * from './TableConfig'

/**
 * @constant ROUTES : To define app routes.
 */
export const ROUTES = {
    /** home */
    home: "/",
    dashboard: "/home/dashboard",
    notificationOverview: "/notifications/overview",
    homePageActions: "/home/dashboard/actions",
    homePageMultiActionDetails: "/home/dashboard/action-details",
    homePageActionsDetails: "/home/dashboard/actions/:id",
    homePageTaskDetail: "/home/dashboard/task-overview/:taskId",
    homePageTaskActionDetail: "/home/dashboard/task-overview/:taskId/actions",
    homePageTaskOverview: "/home/dashboard/tasks-overview",
    homePageTaskOverviewTaskDetail: "/home/dashboard/tasks-overview/:taskId",
    homePageTaskOverviewActionDetail: "/home/dashboard/tasks-overview/:taskId/actions",

    homePageUnacceptableActivities: "/home/dashboard/unacceptable-activities",
    homePageTrainingActivities: "/home/dashboard/training-activities",
    homePageUnitCloseoutActivities: "/home/dashboard/units-closeout",
    homeKPIPowerBi: "/home/dashboard/kpi-report/:id",
    homePageReports: "/home/dashboard/reports",

    /** Dashboards */
    planReviewLeadDashboard: "/dashboard/plan-review-lead",
    planReviewDashboard: "/dashboard/plan-review",
    fileProcessLeadDashboard: "/dashboard/file-process-lead",
    fileProcessDashboard: "/dashboard/file-process",
    providershipDashboard: "/dashboard/providership",
    reportProcessorDashboard: "/dashboard/report-processor",
    workOrderDashboard: "/dashboard/work-order",
    performanceDashboard: "/dashboard/performance",
    openActionsAgingDashboard: "/dashboard/open-actions-aging",
    codeFormsDashboard: "/dashboard/code-forms",
    // fieldOpsDashboard: "/dashboard/field-ops", //todo not a dashboard page
    // buildQualityDashboard: "/dashboard/build-quality", //todo not a dashboard page

    /** organizations */
    addNewOrganization: "/organizations/add-new-organization",
    overview: "/organizations/overview",
    organizationsReports: '/organizations/reports',
    organizationDetails: "/organizations/overview/:id",
    updateOrganization: "/organizations/edit-organization",
    updateOrganizationWithId: "/organizations/edit-organization/:id",
    organizationUnitCloseoutActivities: "/organizations/overview/:id/units-closeout",
    organizationSitesDetails: "/organizations/overview/:orgId/sites/:id",
    organizationSiteUnacceptableActivities: "/organizations/overview/:orgId/sites/:id/unacceptable-activities",
    organizationSiteTrainingActivities: "/organizations/overview/:orgId/sites/:id/training-activities",
    organizationSiteActionsOverview: "/organizations/overview/:orgId/sites/:id/actions",
    organizationSiteActionsDetails: "/organizations/overview/:orgId/sites/:sid/actions/:id",
    organizationSiteTaskOverview: "/organizations/overview/:orgId/sites/:id/task-overview",
    organizationSiteTaskDetailByTaskCardId: "/organizations/overview/:orgId/sites/:id/task-overview/:taskId",
    organizationSiteTaskByTaskCardIdActionDetail: "/organizations/overview/:orgId/sites/:siteId/task-overview/:taskId/actions",
    organizationAddNewSite: "/organizations/overview/:orgId/sites/add-new-site",
    organizationEditSite: "/organizations/overview/:orgId/sites/edit-site/:id",
    organizationUnitsDetails: "/organizations/overview/:orgId/sites/:id/units/:siteUnitId",
    organizationSiteUnitsTaskSamplingWorkflowDetails: "/organizations/overview/:orgId/sites/:id/units/:siteUnitId/sampling-workflow/:workflowId",
    organizationKPIPowerBi: "/organizations/overview/:id/kpi-report/:reportId",
    organizationSiteUnitsTaskAssignment: "/organizations/overview/:orgId/sites/:id/units/:siteUnitId/task-assignment",

    contacts: "/contacts",
    contactsWithId: "/contacts/:id",
    loginPage: "/login",
    profile: "/profile",
    projectsOverview: "/projects/overview",

    /** Schedule */
    projectsSchedule: "/projects/schedule",
    scheduleTaskAssignment: "/schedule/task-assignment",
    projectsScheduleSampling: "/projects/schedule/sampling-workflow/:workflowId",
    projectsScheduleTaskDetail: "/projects/schedule/task-overview/:taskId",
    projectsScheduleTaskActionDetail: "/projects/schedule/task-overview/:taskId/actions",
    calendarOverview: "/calendar/overview",
    calendarTaskAssignment: "/calendar/task-assignment",
    scheduleTaskAssignmentTaskDetails: "/schedule/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentUnitDetails: "/schedule/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentUnitTaskDetails: "/schedule/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentActionDetails: "/schedule/task-assignment/tasks/:taskId/actions",
    scheduleTaskAssignmentUnitTaskPageActionDetails: "/schedule/task-assignment/units/:siteUnitId/tasks/:taskId/actions",
    scheduleTaskAssignmentCalendarTaskDetails: "/calendar/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentCalendarUnitDetails: "/calendar/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentCalendarUnitTaskDetails: "/calendar/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentCalendarTaskActionDetails: "/calendar/task-assignment/tasks/:taskId/actions",
    scheduleTaskAssignmentCalendarUnitTaskPageActionDetails: "/calendar/task-assignment/units/:siteUnitId/tasks/:taskId/actions",
    scheduleTaskAssignmentCampaignsTaskDetails: "/campaigns/overview/:id/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentCampaignUnitDetail: "/campaigns/overview/:id/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentCampaignUnitTaskDetails: "/campaigns/overview/:id/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentSiteTaskDetails: "/sites/overview/:id/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentSiteUnitDetail: "/sites/overview/:id/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentSiteUnitTaskDetails: "/sites/overview/:id/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentSiteUnitAssignmentTaskDetails: "/sites/overview/:id/units/:siteUnitId/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentSiteUnitAssignmentUnitDetails: "/sites/overview/:id/units/:siteUnitId/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentUnitDetailsTask: "/sites/overview/:id/units/:siteUnitId/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentCampaignTaskActionDetails: "/campaigns/overview/:id/task-assignment/tasks/:taskId/actions",
    scheduleTaskAssignmentSiteTaskActionDetails: "/sites/overview/:id/task-assignment/tasks/:taskId/actions",
    scheduleTaskAssignmentProgramTaskDetail: "/programs/overview/:id/task-assignment/tasks/:taskId",
    scheduleTaskAssignmentProgramUnitDetail: "/programs/overview/:id/task-assignment/units/:siteUnitId",
    scheduleTaskAssignmentProgramUnitTaskDetail: "/programs/overview/:id/task-assignment/units/:siteUnitId/tasks/:taskId",
    scheduleTaskAssignmentProgramTaskActionDetail: "/programs/overview/:id/task-assignment/tasks/:taskId/actions",
    scheduleTaskAssignmentProgramUnitTaskActionDetail: "/programs/overview/:id/task-assignment/units/:siteUnitId/tasks/:taskId/actions",

    projectsReports: '/projects/reports',
    projectDetails: "/projects/overview/:id",
    addNewProject: "/projects/add-new-project",
    editProject: "/projects/edit-project/:id",
    siteUnitDetails: "/projects/overview/:id/site/:sid/unit/:uid",
    siteDetails: "/projects/overview/:id/site/:sid",
    addNewTemplates: "/templates/add-new-template",
    checkPoints: '/projects/activities',
    editTemplate: "/templates/edit-template/:templateId",
    templates: "/templates/overview",
    templateDetail: "/templates/overview/:templateId",
    checkpointsDetail: "/templates/activities/:cid/:cvid",

    /** campaigns */
    campaignOverview: "/campaigns/overview",
    campaignsSchedule: '/campaigns/schedule',
    campaignsChecklists: '/campaigns/checklists',
    campaignsReports: '/campaigns/reports',
    campaignDetail: "/campaigns/overview/:id",
    campaignTaskDetails: "/campaigns/overview/:id/tasks/:taskId",
    campaignTaskSamplingWorkflowDetails: "/campaigns/overview/:id/sampling-workflow/:workflowId",
    campaignUnitsDetails: "/campaigns/overview/:id/units/:siteUnitId",
    campaignUnitTaskDetails: "/campaigns/overview/:id/units/:siteUnitId/tasks/:taskId",
    campaignUnitTaskPageActionDetails: "/campaigns/overview/:cid/units/:siteUnitId/tasks/:taskId/actions",
    campaignUnitTaskSamplingWorkflowDetails: "/campaigns/overview/:id/units/:siteUnitId/sampling-workflow/:workflowId",
    campaignUnacceptableActivities: "/campaigns/overview/:id/unacceptable-activities",
    campaignTrainingActivities: "/campaigns/overview/:id/training-campaign-activities",
    campaignUnitCloseoutActivities: "/campaigns/overview/:id/units-closeout",
    campaignActionsOverview: "/campaigns/overview/:id/actions",
    campaignActionsDetails: "/campaigns/overview/:cid/actions/:id",
    campaignTaskOverview: "/campaigns/overview/:id/task-overview",
    campaignTaskDetailByTaskCardId: "/campaigns/overview/:id/task-overview/:taskId",
    campaignTaskByTaskCardIdActionDetail: "/campaigns/overview/:cid/task-overview/:taskId/actions",
    campaignKPIPowerBi: "/campaigns/overview/:id/kpi-report/:reportId",
    campaignTaskActionDetails: "/campaigns/overview/:cid/tasks/:taskId/actions",
    campaignsTaskAssignment: "/campaigns/overview/:id/task-assignment",
    campaignUnitsTaskAssignment: "/campaigns/overview/:id/units/:siteUnitId/task-assignment",

    /** workflows */
    campaignWorkflowDetails: "/campaigns/overview/:id/workflows/:wfId",
    workflowsUnacceptableActivities: "/campaigns/overview/:cid/workflows/:id/unacceptable-activities",
    workflowsTrainingActivities: "/campaigns/overview/:cid/workflows/:id/training-activities",
    workflowsTaskDetails: "/campaigns/overview/:cid/workflows/:id/tasks/:taskId",
    workflowsTaskSamplingWorkflowDetails: "/campaigns/overview/:cid/workflows/:id/sampling-workflow/:workflowId",
    workflowsActionsOverview: "/campaigns/overview/:cid/workflows/:id/actions",
    workflowsActionsDetails: "/campaigns/overview/:cid/workflows/:wfId/actions/:id",
    workflowsTaskOverview: "/campaigns/overview/:cid/workflows/:id/task-overview",
    workflowsTaskDetailByTaskCardId: "/campaigns/overview/:cid/workflows/:id/task-overview/:taskId",
    workflowsTaskByTaskCardIdActionDetail: "/campaigns/overview/:cid/workflows/:wid/task-overview/:taskId/actions",
    workflowsTaskActionDetails: "/campaigns/overview/:cid/workflows/:id/tasks/:taskId/actions",
    campaignWorkflowsTaskAssignment: "/campaigns/overview/:cid/workflows/:id/task-assignment",
    campaignWorkflowAssignmentTaskDetail: "/campaigns/overview/:cid/workflows/:id/task-assignment/tasks/:taskId",

    /** campaigns */
    addNewCampaign: "/campaigns/add-new-campaign",
    editCampaign: "/campaigns/edit-campaign/:id",
    campaignCheckpointDetails: "/camapigns/overview/campaignCheckpointDetails",
    serviceDetail: "/campaigns/overview/:cid/service/:sid",
    checklistDetail: "/campaigns/overview/:cid/service/:sid/checklist/:ckid",
    checklistCheckpointDetail: "/campaigns/overview/:cid/service/:sid/checklist/:ckid/activities/:chid/:chvid",

    reportsOverview: '/reports/overview',
    reportsSchedule: '/reports/schedule',
    accountSettings: '/accountSettings',
    notAuthorized: '/misc/not-authorized',
    error: '/misc/error',
    UserManagementPermission: '/user-management/permission',
    UserManagementRole: '/user-management/role',
    selectLoginProfile: '/select-login-profile',
    search: '/search',
    windwardReportProgressDetails: '/reports/windward',

    /** programs */
    programsOverview: "/programs/overview",
    programsDetails: "/programs/overview/:id",
    addNewProgram: "/programs/add-new-program",
    editProgram: "/programs/edit-program/:id",
    programTaskDetails: "/programs/overview/:id/tasks/:taskId",
    programTaskSamplingWorkflowDetails: "/programs/overview/:id/sampling-workflow/:workflowId",
    programUnitsDetails: "/programs/overview/:id/units/:siteUnitId",
    programUnitTaskDetails: "/programs/overview/:id/units/:siteUnitId/tasks/:taskId",
    programUnitTaskActionDetails: "/programs/overview/:pid/units/:siteUnitId/tasks/:taskId/actions",
    programUnitTaskSamplingWorkflowDetails: "/programs/overview/:id/units/:siteUnitId/sampling-workflow/:workflowId",
    programUnacceptableActivities: "/programs/overview/:id/unacceptable-activities",
    programTrainingActivities: "/programs/overview/:id/training-activities",
    programUnitCloseoutActivities: "/programs/overview/:id/units-closeout",
    programActionsOverview: "/programs/overview/:id/actions",
    programActionsDetails: "/programs/overview/:pid/actions/:id",
    programTaskOverview: "/programs/overview/:id/task-overview",
    programTaskDetailByTaskCardId: "/programs/overview/:id/task-overview/:taskId",
    programTaskByTaskCardIdActionDetail: "/programs/overview/:pid/task-overview/:taskId/actions",
    programKPIPowerBi: "/programs/overview/:id/kpi-report/:reportId",
    programTaskActionDetails: "/programs/overview/:pid/tasks/:taskId/actions",
    programTaskAssignment: "/programs/overview/:id/task-assignment",
    programUnitsTaskAssignment: "/programs/overview/:id/units/:siteUnitId/task-assignment",

    resourcesOverview: "/resources/overview",
    communicationLog: "/resources/communication-log",
    communicationLogTaskDetails: "/resources/communication-log/task-overview/:taskId",
    communicationLogTaskActionDetails: "/resources/communication-log/task-overview/:taskId/actions",
    manageTaskById: "/manage-task/:id",
    manageTask: "/manage-task",
    taskDataCollectionOverview: "/task-data-collection/overview",
    taskDataCollectionTaskDetail: "/task-data-collection/overview/:taskId",
    taskDataCollectionTaskActionDetail: "/task-data-collection/overview/:taskId/actions",
    actionsOverview: "/actions/overview",
    actionsDetails: "/actions/details",
    dataRetentionPolicies: "/settings/data-retention-policies",
    notificationSettings: "/settings/notification",

    /** sites */
    sitesOverview: "/sites/overview",
    sitesDetails: "/sites/overview/:id",
    siteTaskDetails: "/sites/overview/:id/tasks/:taskId",
    siteTaskActionDetails: "/sites/overview/:id/tasks/:taskId/actions",
    siteUnacceptableActivities: "/sites/overview/:id/unacceptable-activities",
    siteTrainingActivities: "/sites/overview/:id/training-activities",
    siteUnitsCloseoutActivities: "/sites/overview/:id/units-closeout",
    siteActionsOverview: "/sites/overview/:id/actions",
    siteActionsDetails: "/sites/overview/:pid/actions/:id",
    siteTaskOverview: "/sites/overview/:id/task-overview",
    siteTaskDetailByTaskCardId: "/sites/overview/:id/task-overview/:taskId",
    siteTaskByTaskCardIdActionDetail: "/sites/overview/:id/task-overview/:taskId/actions",
    siteUnitsDetails: "/sites/overview/:id/units/:siteUnitId",
    siteUnitTaskDetails: "/sites/overview/:id/units/:siteUnitId/tasks/:taskId",
    siteUnitTaskSamplingWorkflowDetails: "/sites/overview/:id/units/:siteUnitId/sampling-workflow/:workflowId",
    siteSamplingWorkflowDetails: "/sites/overview/:id/sampling-workflow/:workflowId",
    addNewSite: "/sites/add-new-site",
    editSite: "/sites/edit-site/:id",
    siteKPIPowerBi: "/sites/overview/:id/kpi-report/:reportId",
    siteUnitTaskActionDetails: "/sites/overview/:sid/units/:siteUnitId/tasks/:taskId/actions",
    siteTaskAssignment: "/sites/overview/:id/task-assignment",
    siteUnitsTaskAssignment: "/sites/overview/:id/units/:siteUnitId/task-assignment",

    /** sampling */
    samplingOverview: "/sampling/overview",
    samplingWorkflowDetails: "/sampling/overview/workflow/:workflowId",

    /** activities */
    activitiesOverview: "/activities/overview",
    activitiesDetails: "/activities/overview/:cid/:cvid",
    addNewActivity: "/activities/add-new-activity",
    editActivity: "/activities/edit-activity/:checkpointId/:checkpointVersionId",

    /**Admin Task Management */
    administrativeTasks: "/operations/administrative-tasks",
    administrativeTaskDetail: "/operations/administrative-tasks/task-overview/:taskId",
    administrativeTaskActionDetail: "/operations/administrative-tasks/task-overview/:taskId/actions",

    /** operations */
    exceptionDashboard: "/operations/exceptions",

     /** builder home */
     builderHome: "/builder/home",
     builderHomeSiteUnits: "/builder/home/my-site-units/:Id",
     builderHomeActionDetails: "/builder/home/actions/details"

}   

/**
 * @constant PERMISSION_ROUTES : To define user permission routes.
 */
export const PERMISSION_ROUTES = {
    [ROUTES.homePageActions]: "OrganizationRead",
    [ROUTES.homePageActionsDetails]: "OrganizationRead",
    [ROUTES.homePageMultiActionDetails]: "OrganizationRead",
    [ROUTES.homePageTaskOverview]: "OrganizationRead",
    [ROUTES.homePageTaskDetail]: "OrganizationRead",
    [ROUTES.homePageTaskActionDetail]: "OrganizationRead",
    [ROUTES.homePageTaskOverviewTaskDetail]: "OrganizationRead",
    [ROUTES.homePageUnacceptableActivities]: "OrganizationRead",
    [ROUTES.homePageTaskOverviewActionDetail]: "OrganizationRead",
    [ROUTES.homePageTrainingActivities]: "OrganizationRead",
    [ROUTES.homePageUnitCloseoutActivities]: "OrganizationRead",
    [ROUTES.homeKPIPowerBi]: "OrganizationRead",
    [ROUTES.homePageReports]: "OrganizationRead", //TODO: Permission
    [ROUTES.addNewOrganization]: 'OrganizationCreate',
    [ROUTES.overview]: 'OrganizationRead',
    [ROUTES.organizationDetails]: 'OrganizationRead',
    [ROUTES.organizationKPIPowerBi]: 'OrganizationRead',
    [ROUTES.updateOrganization]: 'OrganizationUpdate',
    [ROUTES.updateOrganizationWithId]: 'OrganizationUpdate',
    [ROUTES.organizationSitesDetails]: 'OrganizationRead',
    [ROUTES.organizationSiteUnacceptableActivities]: 'OrganizationRead',
    [ROUTES.organizationUnitCloseoutActivities]: 'OrganizationRead',
    [ROUTES.organizationSiteTrainingActivities]: 'OrganizationRead',
    [ROUTES.organizationSiteActionsOverview]: 'OrganizationRead',
    [ROUTES.organizationSiteActionsDetails]: 'OrganizationRead',
    [ROUTES.organizationSiteTaskOverview]: 'OrganizationRead',
    [ROUTES.organizationSiteTaskDetailByTaskCardId]: 'OrganizationRead',
    [ROUTES.organizationSiteTaskByTaskCardIdActionDetail]: 'OrganizationRead',
    [ROUTES.organizationAddNewSite]: 'OrganizationCreate',
    [ROUTES.organizationEditSite]: 'OrganizationUpdate',
    [ROUTES.organizationUnitsDetails]: 'OrganizationRead', //Todo: Permissions
    [ROUTES.organizationSiteUnitsTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.organizationSiteUnitsTaskAssignment]: 'ScheduleUpdate',
    [ROUTES.contacts]: 'OrganizationRead',
    [ROUTES.contactsWithId]: 'OrganizationRead',
    [ROUTES.projectsOverview]: 'ProjectRead',
    [ROUTES.projectDetails]: 'ProjectRead',
    [ROUTES.addNewProject]: 'ProjectCreate',
    [ROUTES.editProject]: 'ProjectUpdate',
    [ROUTES.projectsSchedule]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignment]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentTaskDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentUnitDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentUnitTaskDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentActionDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentUnitTaskPageActionDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCalendarTaskDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCalendarUnitDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCalendarUnitTaskDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCalendarTaskActionDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCalendarUnitTaskPageActionDetails]: 'ScheduleRead',
    [ROUTES.scheduleTaskAssignmentCampaignsTaskDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentCampaignTaskActionDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentCampaignUnitDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentCampaignUnitTaskDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteTaskDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteTaskActionDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteUnitDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteUnitTaskDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteUnitAssignmentTaskDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentSiteUnitAssignmentUnitDetails]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentUnitDetailsTask]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentProgramTaskDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentProgramUnitDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentProgramUnitTaskDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentProgramTaskActionDetail]: "ScheduleRead",
    [ROUTES.scheduleTaskAssignmentProgramUnitTaskActionDetail]: "ScheduleRead",
    [ROUTES.calendarTaskAssignment]: 'ScheduleRead',
    [ROUTES.projectsScheduleSampling]: 'ScheduleRead',
    [ROUTES.projectsScheduleTaskDetail]: 'ScheduleRead',
    [ROUTES.projectsScheduleTaskActionDetail]: 'ScheduleRead',
    [ROUTES.calendarOverview]: 'ScheduleRead', //TODO: Permissions
    [ROUTES.addNewTemplates]: 'TemplateCreate',
    [ROUTES.checkPoints]: 'CheckpointRead',
    [ROUTES.editTemplate]: 'TemplateUpdate',
    [ROUTES.templates]: 'TemplateRead',
    [ROUTES.templateDetail]: 'TemplateRead',
    [ROUTES.checkpointsDetail]: 'CheckpointRead',
    [ROUTES.campaignOverview]: 'CampaignRead',
    [ROUTES.campaignDetail]: 'CampaignRead',
    [ROUTES.campaignKPIPowerBi]: 'CampaignRead',
    [ROUTES.campaignTaskActionDetails]: 'CampaignRead',
    [ROUTES.campaignTaskDetailByTaskCardId]: 'CampaignRead',
    [ROUTES.campaignTaskByTaskCardIdActionDetail]: 'CampaignRead',
    [ROUTES.campaignTaskOverview]: 'CampaignRead',
    [ROUTES.campaignTaskDetails]: 'CampaignRead',
    [ROUTES.campaignsTaskAssignment]: 'CampaignRead',
    [ROUTES.campaignUnitsTaskAssignment]: 'CampaignRead',
    [ROUTES.campaignTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.campaignUnitsDetails]: 'CampaignRead',
    [ROUTES.campaignUnitTaskDetails]: 'CampaignRead',
    [ROUTES.campaignUnitTaskPageActionDetails]: 'CampaignRead',
    [ROUTES.campaignUnitTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.campaignUnacceptableActivities]: 'CampaignRead',
    [ROUTES.campaignTrainingActivities]: 'CampaignRead',
    [ROUTES.campaignUnitCloseoutActivities]: 'CampaignRead',
    [ROUTES.campaignActionsOverview]: 'CampaignRead',
    [ROUTES.campaignActionsDetails]: 'CampaignRead',
    [ROUTES.workflowsUnacceptableActivities]: 'CampaignRead',
    [ROUTES.workflowsTrainingActivities]: 'CampaignRead',
    [ROUTES.workflowsTaskOverview]: 'CampaignRead',
    [ROUTES.workflowsTaskDetailByTaskCardId]: 'CampaignRead',
    [ROUTES.workflowsTaskByTaskCardIdActionDetail]: 'CampaignRead',
    [ROUTES.workflowsTaskActionDetails]: 'CampaignRead',
    [ROUTES.campaignWorkflowsTaskAssignment]: 'CampaignRead',
    [ROUTES.campaignWorkflowAssignmentTaskDetail]: 'CampaignRead',
    [ROUTES.workflowsTaskDetails]: 'CampaignRead',
    [ROUTES.workflowsTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.workflowsActionsOverview]: 'CampaignRead',
    [ROUTES.workflowsActionsDetails]: 'CampaignRead',
    [ROUTES.campaignWorkflowDetails]: 'CampaignRead',
    [ROUTES.addNewCampaign]: 'CampaignCreate',
    [ROUTES.editCampaign]: 'CampaignUpdate',
    [ROUTES.campaignCheckpointDetails]: 'CheckpointRead',
    [ROUTES.checklistCheckpointDetail]: 'CheckpointRead',
    [ROUTES.serviceDetail]: 'TemplateRead',
    createService: 'TemplateCreate',
    createChecklist: 'TemplateCreate',
    [ROUTES.checklistDetail]: 'TemplateRead',
    deleteCampaign: 'CampaignDelete',
    deleteProgram: 'ProgramDelete',
    updateService: "TemplateUpdate",
    deleteService: "TemplateDelete",
    updateChecklist: 'TemplateUpdate',
    deleteChecklist: 'TemplateDelete',
    createCheckpoint: "CheckpointCreate",
    updateCheckpoint: "CheckpointUpdate",
    cloneCheckpoint: "CheckpointCreate",
    cloneTemplateCheckpoint: "CheckpointCreate",
    newVersionCheckpoint: "CheckpointCreate",
    deleteCheckpoint: "CheckpointDelete",
    createContact: 'OrganizationCreate',
    updateContact: 'OrganizationUpdate',
    deleteContact: "OrganizationDelete",
    createSite: 'ProjectCreate',
    updateSite: "ProjectUpdate",
    deleteSite: "ProjectDelete",
    [ROUTES.siteDetails]: "ProjectRead",
    [ROUTES.siteUnitDetails]: "ProjectRead",
    createUnit: "ProjectCreate",
    createRole: "ProjectCreate",
    createPlan: "ProjectCreate",
    updateRole: "ProjectUpdate",
    deleteRole: "ProjectDelete",
    updatePlan: "ProjectUpdate",
    deletePlan: "ProjectDelete",
    updateUnit: "ProjectUpdate",
    deleteUnit: "ProjectDelete",
    deleteOrganization: "OrganizationDelete",
    [ROUTES.addNewTemplates]: "TemplateCreate",
    [ROUTES.editTemplate]: "TemplateUpdate",
    [ROUTES.templateDetail]: "TemplateRead",
    cloneTemplate: "TemplateCreate",
    newVersionTemplate: "TemplateCreate",
    deleteTemplate: "TemplateDelete",
    [ROUTES.UserManagementPermission]: 'UserManagementRead',
    [ROUTES.UserManagementRole]: 'UserManagementRead',
    createUserRolePermissions: "UserManagementCreate",
    updateUserRolePermissions: "UserManagementUpdate",
    [ROUTES.programsOverview]: 'ProgramRead',
    [ROUTES.programsDetails]: 'ProgramRead',
    [ROUTES.programKPIPowerBi]: 'ProgramRead',
    [ROUTES.programTaskActionDetails]: 'ProgramRead',
    [ROUTES.programTaskDetailByTaskCardId]: 'TemplateRead',
    [ROUTES.programTaskByTaskCardIdActionDetail]: 'TemplateRead',
    [ROUTES.programTaskOverview]: 'TemplateRead',
    [ROUTES.programTaskDetails]: 'TemplateRead',
    [ROUTES.programTaskAssignment]: 'TemplateRead',
    [ROUTES.programUnitsTaskAssignment]: 'TemplateRead',
    [ROUTES.programTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.programUnitsDetails]: 'TemplateRead',
    [ROUTES.programUnitTaskDetails]: 'TemplateRead',
    [ROUTES.programUnitTaskActionDetails]: 'TemplateRead',
    [ROUTES.programUnitTaskSamplingWorkflowDetails]: 'ScheduleUpdate',
    [ROUTES.programUnacceptableActivities]: 'TemplateRead',
    [ROUTES.programTrainingActivities]: 'TemplateRead',
    [ROUTES.programUnitCloseoutActivities]: 'TemplateRead',
    [ROUTES.programActionsOverview]: 'TemplateRead',
    [ROUTES.programActionsDetails]: 'TemplateRead',
    [ROUTES.addNewProgram]: 'ProgramCreate',
    [ROUTES.editProgram]: 'ProgramUpdate',
    [ROUTES.resourcesOverview]: 'ProjectRead',
    [ROUTES.taskDataCollectionOverview]: 'TemplateRead',
    [ROUTES.taskDataCollectionTaskDetail]: 'TemplateRead',
    [ROUTES.actionsOverview]: 'CampaignRead',
    [ROUTES.actionsDetails]: 'CampaignRead',
    [ROUTES.accountSettings]: 'CampaignRead',
    [ROUTES.dataRetentionPolicies]: 'CampaignRead', //Todo: Permissions
    [ROUTES.notificationSettings]: "CampaignRead",

    [ROUTES.sitesOverview]: "ProjectRead",
    [ROUTES.sitesDetails]: "ProjectRead",
    [ROUTES.siteTaskAssignment]: "ProjectRead",
    [ROUTES.siteTaskDetails]: "ProjectRead",
    [ROUTES.siteTaskActionDetails]: "ProjectRead",
    [ROUTES.siteKPIPowerBi]: "ProjectRead",
    [ROUTES.siteUnacceptableActivities]: "ProjectRead",
    [ROUTES.siteTrainingActivities]: "ProjectRead",
    [ROUTES.siteUnitsCloseoutActivities]: "ProjectRead",
    [ROUTES.siteActionsOverview]: "ProjectRead",
    [ROUTES.siteActionsDetails]: "ProjectRead",
    [ROUTES.siteTaskOverview]: "ProjectRead",
    [ROUTES.siteTaskDetailByTaskCardId]: "ProjectRead",
    [ROUTES.siteTaskByTaskCardIdActionDetail]: "ProjectRead",
    [ROUTES.siteUnitsDetails]: "ProjectRead",
    [ROUTES.siteUnitsTaskAssignment]: "ProjectRead",
    [ROUTES.siteUnitTaskDetails]: "ProjectCreate",
    [ROUTES.siteUnitTaskSamplingWorkflowDetails]: "ScheduleUpdate",
    [ROUTES.siteSamplingWorkflowDetails]: "ScheduleUpdate",
    [ROUTES.addNewSite]: "ProjectCreate",
    [ROUTES.editSite]: "ProjectCreate",
    [ROUTES.notificationOverview]: "CampaignRead", //Todo: Permissions
    [ROUTES.samplingOverview]: "ProjectCreate", //Todo: Permissions
    [ROUTES.samplingWorkflowDetails]: "ScheduleUpdate",
    //Note: tasksGridContextMenuOptions permission for options - Sample, SetToReady, NotReady, Canceled, Assign, EditAssign, Unassign
    tasksGridContextMenuOptions: "ScheduleUpdate",

    [ROUTES.activitiesOverview]: 'TemplateRead',
    [ROUTES.activitiesDetails]: 'TemplateRead',
    [ROUTES.addNewActivity]: 'TemplateCreate',
    [ROUTES.editActivity]: 'TemplateCreate',

    [ROUTES.communicationLog]: "CommunicationLogRead",
    [ROUTES.communicationLogTaskDetails]: "CommunicationLogRead",
    [ROUTES.communicationLogTaskActionDetails]: "CommunicationLogRead",

    /** Dashboards */
    [ROUTES.planReviewLeadDashboard]: "DashboardsPlanReviewLead",
    [ROUTES.planReviewDashboard]: "DashboardsPlanReview",
    [ROUTES.fileProcessLeadDashboard]: "DashboardsFileProcessLead",
    [ROUTES.fileProcessDashboard]: "DashboardsFileProcess",
    [ROUTES.providershipDashboard]: "DashboardsProvidership",
    [ROUTES.reportProcessorDashboard]: "DashboardsReportProcessor",
    [ROUTES.workOrderDashboard]: "DashboardsWorkOrder",
    [ROUTES.performanceDashboard]: "DashboardsPerformance",
    [ROUTES.openActionsAgingDashboard]: "DashboardsOpenActionsAging",
    [ROUTES.codeFormsDashboard]: "DashboardsCodeForms",
    // [ROUTES.fieldOpsDashboard]: "DashboardsFieldOps", //todo not a dashboard page
    // [ROUTES.buildQualityDashboard]: "DashboardsBuildQuality" //todo not a dashboard page

    /** Admin Task Management */
    [ROUTES.administrativeTasks]: "TaskManagement", 
    [ROUTES.administrativeTaskDetail]: "TaskManagement", 
    [ROUTES.administrativeTaskActionDetail]: "TaskManagement",

    /** operations */
    [ROUTES.exceptionDashboard]: "DashboardsException", //Todo: check is permission updates required
    tasksClose: "TasksClose",

    [ROUTES.builderHome]: "BuilderHomepage_Page",
    [ROUTES.builderHomeSiteUnits]: "BuilderHomepage_RequestInspection_Unit",
    [ROUTES.builderHomeActionDetails]: "BuilderHomepage_MyActions"
}

export const DEFAULT_VM_PAGE_SIZE = 10
export const DEFAULT_VM_TAG_PAGE_SIZE = 50

export const MODAL_TYPES = {
    ADD: 'ADD',
    EDIT: 'EDIT'
}
export const FORM_ACTION_TYPES = {
    ADD: 'ADD',
    EDIT: 'EDIT'
}
export const CHECKPOINT_ORIGNATED_FROM_TYPES = {
    TEMPLATE: 'template',
    CAMPAIGN: 'campaign',
    ACTIVITIES_OVERVIEW: 'activities/overview'
}
export const CHECKPOINT_NUMERIC_TYPES = {
    SINGLE_NUMBER: '1',
    NUMERIC_RANGE: '2'
}
export const CHECKPOINT_NUMERIC_VALIDATION_TYPES = {
    WHOLE_NUMBER: 1,
    DECIMAL_NUMBER: 2,
    PERCENTAGE_NUMBER: 3
}
export const TEMPLATE_ACTION_TYPE = {
    CLONE: 'CLONE',
    EDIT: 'EDIT',
    NEW_VERSION: 'NEW_VERSION'
}
export const CLONE_TYPE = {
    TEMPLATE: 'TEMPLATE',
    CHECKPOINT: 'CHECKPOINT'
}
export const COLUMN_LIST_TYPE = {
    WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS: 'WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS',
    CHECKPOINTS_FOR_CHECKLIST: 'CHECKPOINTS_FOR_CHECKLIST',
    OVERVIEW: 'OVERVIEW',
    SEARCH_FOR_CAMPAIGN: 'SEARCH_FOR_CAMPAIGN',
    CAMPAIGN_SERVICE_OVERVIEW: 'CAMPAIGN_SERVICE_OVERVIEW',
    PROGRAM_CAMPAIGN_SERVICE_GRID: 'PROGRAM_CAMPAIGN_SERVICE_GRID'
}
export const CHECKPOINT_PARENT_TYPE = {
    CAMPAIGN_SERVICE_CHECKLIST: 'CAMPAIGN_SERVICE_CHECKLIST'
}
export const CAMPAIGN_SERVICE_MODE = {
    VIEW: 'VIEW',
    EDIT: 'EDIT'
}

export const SCHEDULING_TASK_STATUS_TYPES = {
    NOT_READY: 1,
    READY: 2,
    SAMPLED: 3,
    ASSIGNED: 4,
    SCHEDULED: 5,
    RESCHEDULE_NEEDED: 6,
    IN_PROGRESS: 7,
    IN_PROGRESS_STAR: 8,
    COMPLETED: 9,
    VERIFICATION: 10,
    CLOSED: 11,
    CANCELLED: 12,
    READY_MARKED_FOR_INPECTION: 14,
    PENDING: 15,
    IN_REVIEW: 16,
    ON_HOLD: 13,
    REVISION: 17
}

export const SCHEDULING_TASK_STATUS = {
    CLOSED: "Closed" 
}

export const MODAL_MODES = {
    VIEW: "View",
    ADD: 'Add',
    EDIT: 'Edit',
    REASSIGN: 'Reassign',
    REOPEN: 'Reopen'
}

/**
 * @constant ADVANCE_SEARCH_FILTER_KEYS : To define searchFilterKey to save advance search filter
 */
export const ADVANCE_SEARCH_FILTER_KEYS = {
    FORESITE_ADVANCE_SEARCH: 'foresite_advance_search',
    PROJECTS_SCHEDULING_CONTACT_LIST: 'projects_scheduling_contact_list',
    PROJECTS_SCHEDULING_TASK_LIST: 'projects_scheduling_task_list',
    TASK_LIST: 'task_list', //Todo: check for key based on route
    CALENDAR_TASK_LIST: "calendar_task_list",
    ADMINISTRATIVE_TASK_LIST: "administrative_task_list",
    OPERATIONS_TASK_LIST: "operations_task_list"
}
/**
 * @constant PREFERRED_LANGUAGES : To define list of languages available for preferrence in foresite
 */
export const PREFERRED_LANGUAGES = [
    { text: "English", value: "en", countryCode: "us" },
    { text: "Español", value: "es", countryCode: "es" }
]
/**
 * @constant ACTIVITY_TYPE : To define value of activity types
 */
export const ACTIVITY_TYPE = {
    OBSERVATION: '1',
    TRAINING: '2'
}

/**
 * @constant ACTIVITY_TYPE_NAME : To define value of activity type names
 */
export const ACTIVITY_TYPE_NAME = {
    TRAINING: 'Training',
    DOCS_COMPLIANCE: 'Docs / Compliance'
}

/**
 * @constant CAMPAIGN_SCHEDULE_DRIVER : To define values for schedule drivers
 */

export const CAMPAIGN_SCHEDULE_DRIVER = {
    EVENT_INTERVAL: '3'
}

export const OBSERVATION = 'Observation'

export const DEFAULT_LANGUAGE = "English"

export const DEFAULT_LANGUAGE_CODE = "en"

export const DEFAULT_COUNTRY_CODE = "us"

export const API_RESPONSE_CONFIG = {
    NO_CONTENT: "No Content"
}

export const DETAILS_SECTION_TAB_TYPE = {
    DETAILS: "DETAILS",
    REPORTS: 'REPORTS',
    TASKS: "TASKS"
}

export const REPORT_PAGE = {
    HOME: "Home",
    PROGRAM: "Program",
    ORGANIZATION: "Organization",
    PROJECT: "Project",
    CAMPAIGN: "Campaign"
}

export const LIST_SORT_ORDER_DIRECTION = {
    ASC: 'asc',
    DESC: 'desc',
    NO_SORT: 'null'
}

export const API_ERROR_STATUS_CODE = {
    NO_CONTENT: 'No Content'
}

export const LANGUAGE_TYPE = {
    ENGLISH: 0,
    SPANISH: 1
}

export const COUNTRY_CODE_TYPE = {
    ENGLISH: 'en',
    SPANISH: 'es'
}

export const NOTIFICATION_TYPES = {
    SCHEDULE: "Schedule"
}

export const EXPORT_TYPES = {
    CSV: "CSV",
    DOCX: "DOCX",
    PDF: "PDF",
    TASK_RESULT_EXPORT: "TASK_RESULT_EXPORT",
    FAILED_INSPECTION_EXPORT: "FAILED_INSPECTION_EXPORT",
    AGING_REPORT: "AGING_REPORT",
    FIT_AND_FINISH_PERFORMANCE: "FIT_AND_FINISH_PERFORMANCE",
    FIT_AND_FINISH_CLOSEOUT: "FIT_AND_FINISH_CLOSEOUT",
    INSPECTION_REPORT: "INSPECTION_REPORT",
    OPEN_ACTIONS_AGING_BY_UNIT: "OPEN_ACTIONS_AGING_BY_UNIT",
    SITE_VISIT_REPORT: "SITE_VISIT_REPORT",
    REGIONAL_SCHEDULING_REPORT: "REGIONAL_SCHEDULING_REPORT",
    UNIT_STATUS_TWINING_REPORT: "UNIT_STATUS_TWINING_REPORT"
}

export const EXPORT_REPORT_TYPE_ID = {
    TASK_RESULT_EXPORT: 1,
    FAILED_INSPECTION_EXPORT: 9
}

export const TASK_TYPE = {
    OBSERVATION: "Observation",
    TRAINING: "Training"
}

export const DOCUMENT_FILE_NAMES = {
    UNAPPROVED_ACTIVITIES: "UnapprovedActivitiesReport",
    UNACCEPTABLE_ACTIVITIES: "UnacceptableActivitiesReport",
    DAILY_INSPECTION: "TaskSummary",
    TRAINING_CAMPAIGN: "TrainingCampaignReport",
    UNIT_CLOSEOUT: "UnitCloseOutReport",
    TASKS: "TaskResultsReport",
    DIGITAL_RESUME: "DigitalResume",
    ENERGY_SUMMARY_REPORT: "EnergyGeneralSummaryReport",
    HOME_INSPECTION_TRACKER: "NewHomeInspecitonTracker",
    TRADE_PERFORMANCE: "TradePerformance",
    SAMPLING_RATE: "SamplingRate",
    TASK_RESULT_EXPORT: "TaskResultsReport",
    FAILED_INSPECTION_EXPORT: "UnapprovedActivitiesReport_TaskOnly", // added after Justin confirmation the previous name doesn't exist
    AGING_REPORT: "OpenActionsAging",
    CAMPAIGN_STATUS: "CampaignStatus",
    UNIT_STATUS: "UnitStatus_UnitID",
    FIT_AND_FINISH_PERFORMANCE: "FitandFinish_Performance_UnitID",
    FIT_AND_FINISH_CLOSEOUT: "FitandFinish_CloseOut_UnitID",
    INSPECTION_REPORT: "QB_AlliedRisk_InspectionReport_UnitId",
    OPEN_ACTIONS_AGING_BY_UNIT: "OpenActionsAgingByUnit",
    SITE_VISIT_REPORT: "SiteVisitReport",
    REGIONAL_SCHEDULING_REPORT: "ScheduleReport",
    UNIT_STATUS_TWINING_REPORT: "UnitStatus_Twining_Unit"
}

export const INSPECTION_TYPE = {
    VIRTUAL: 2
}

export const SAVE_RECORD_API_STATUS = {
    SUCCESS: 200,
    FAILURE: 400
}

export const QUICK_SEARCH_KEY = "quickSearch"

export const ENTITY_FEATURE = {
    BILLING_CONTRACT: 'BillingContract',
    WORK_ORDER: 'WorkOrder',
    TASK_REVIEW_GLOBAL_APPROVAL: 'TaskReview-GlobalApproval'
}

export const OVERVIEW_TABS = {
    TASKS: 'tasks'
}

export const OVERVIEW_TAB_STORAGE_KEY = 'overviewTab'

export const TAG_SOURCE_TYPE = {
    SITE: 2,
    CONTACT: 4,
    WORKFLOW_SCOPE: 3,
    WORKFLOW_SKILL: 6,
    Campaign_Service: 7,
    CAMPAIGN: 1,
    CONTACT_MARKET: 9,
    CONTACT_SECONDARY_MARKET: 11,
    DISCLAIMER: 12
}

export const BADGE_COLORS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']

export const ENTITY_TYPES = {
    FORESITE: 1,
    QUALITY_BUILT: 2, //QB
    WOODSIDE_HOMES: 23,
    TWINING: 26
}

export const CC_CONTACT_SOURCE_TYPES = {
    ORGANIZATION: 2
}
