import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateCampaignContractVersion
 * @param {object} data 
 * {
  projectId: "",
  startDate: null,
  endDate: null,
  copyContractLineItem: false,
  copyAdditionalLineItem: false
* }
 *
 */
export const updateCampaignContractVersion = (payload) => {
  const { url } = config.updateCampaignContractVersion

  return putDataApi(url({ campaignId: payload.campaignId, contractVersionId: payload.contractVersionId }), payload)
}

export default updateCampaignContractVersion
