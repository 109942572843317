import { useCallback, useEffect, useMemo, useState } from "react"
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import {
  moreHorizontalSVGIcon
} from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import { ConfirmationModal, DeleteConfirmationModal } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import ActionItem from "./ActionItem"
import { defaultActionTypes, rowActionItems, ROW_ACTION_TYPES } from "./config"
import MenuListPortal from "./MenuListPortal"

const { CLONE_TITLE } = LANGUAGE_CONSTANTS

const RowActions = ({
  intl,
  config = {},
  rowId,
  isDisabled,
  isOpenCloneModal,
  isOpenDeleteModal,
  isWorkflowSetup,
  isSamplingOverview,
  onClickActionItem = () => { },
  onDeleteRow = () => { },
  onCloneRow = () => { },
  onToggleConfirmationModal = () => { },
  onToggleDeleteModal = () => { }
}) => {

  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const handleGridBodyScroll = () => {
      setOpen(false)
    }
    window.addEventListener("onGridBodyScroll", handleGridBodyScroll)
    return () => window.removeEventListener("onGridBodyScroll", handleGridBodyScroll)
  }, [])

  const handleToggleRowMenu = useCallback((e) => {
    e?.preventDefault()
    if (!isOpen) {
      setOpen(true)
    } else {
      setTimeout(() => {
        setOpen(false)
      }, [])
    }
  }, [isOpen])

  const actionTypes = useMemo(() => {
    if (config.actionTypes) {
      return config.actionTypes
    }

    return defaultActionTypes
  }, [config])

  const actionItems = useMemo(() => {
    if (config.rowActionItems) {
      return config.rowActionItems
    } else if (config.isViewNotRequired || isWorkflowSetup) {
      return rowActionItems.filter((x) => (actionTypes.includes(x.id)) && x.id !== ROW_ACTION_TYPES.VIEW)
    } else if (isSamplingOverview) {
      return rowActionItems.filter((x) => (actionTypes.includes(x.id)) && x.id === ROW_ACTION_TYPES.VIEW)
    } else {
      return rowActionItems.filter((x) => actionTypes.includes(x.id))
    }
  }, [config, actionTypes])

  return (
    <>
      <div className="d-flex nt-rdt-list-actions">
        <Dropdown
          isOpen={isOpen}
          className="grid-row-actions-dropdown"
          toggle={handleToggleRowMenu}
        >
          <DropdownToggle className="px-1 grid-row-actions-toggle" tag="span" >
            <img src={moreHorizontalSVGIcon} alt="" />
          </DropdownToggle>
          {!isDisabled && isOpen && <MenuListPortal>
            <DropdownMenu right className="grid-row-actions">
              {actionItems.map((item) => {
                return (
                  <ActionItem
                    key={item.id}
                    item={item}
                    onClick={onClickActionItem}
                  />
                )
              })}
            </DropdownMenu>
          </MenuListPortal>}
        </Dropdown>
      </div>
      {(!!actionTypes.includes(ROW_ACTION_TYPES.DELETE)) && !config.deleteModal.isCustomRowActionModalRequired && (
        <DeleteConfirmationModal
          {...config.deleteModal}
          id={rowId}
          open={isOpenDeleteModal}
          onToggleModal={onToggleDeleteModal}
          onConfirmDeleteRecord={onDeleteRow}
          type={config.deleteModal.labelLocaleId}
          upperText={config.deleteModal.upperText}
          isWarningIcon={config.deleteModal.isWarningIcon}
        />
      )}
      {!!actionTypes.includes(ROW_ACTION_TYPES.CLONE) && (
        <ConfirmationModal
          open={isOpenCloneModal}
          name={config.cloneModal.nameLocaleId}
          title={getLocaleMessage(intl, config.cloneModal?.titleLocalId || CLONE_TITLE)}
          onConfirm={onCloneRow}
          onToggleModal={onToggleConfirmationModal}
        />
      )}
    </>
  )
}

export default localeMessageWrapper(RowActions)
