export const GET_CAMPAIGN_DETAIL_VM_REQUEST = 'GET_CAMPAIGN_DETAIL_VM_REQUEST'
export const GET_SERVICE_DETAIL_VM_REQUEST = 'GET_SERVICE_DETAIL_VM_REQUEST'
export const ADD_CAMPAIGN_DETAIL_REQUEST = 'ADD_CAMPAIGN_DETAIL_REQUEST'
export const GET_CAMPAIGN_DETAIL_REQUEST = 'GET_CAMPAIGN_DETAIL_REQUEST'
export const GET_CAMPAIGN_BASIC_DETAIL_REQUEST = 'GET_CAMPAIGN_BASIC_DETAIL_REQUEST'
export const UPDATE_CAMPAIGN_DETAIL_REQUEST = 'UPDATE_CAMPAIGN_DETAIL_REQUEST'
export const ADD_CAMPAIGN_SERVICE_DETAIL_REQUEST = 'ADD_CAMPAIGN_SERVICE_DETAIL_REQUEST'
export const GET_CAMPAIGN_SERVICE_LIST_REQUEST = 'GET_CAMPAIGN_SERVICE_LIST_REQUEST'
export const GET_CAMPAIGN_SERVICE_CHECKLIST_LIST_REQUEST = 'GET_CAMPAIGN_SERVICE_CHECKLIST_LIST_REQUEST'
export const GET_CAMPAIGN_LIST_REQUEST = 'GET_CAMPAIGN_LIST_REQUEST'
export const GET_PROJECT_LIST_DDL = 'GET_PROJECT_LIST_DDL'
export const DELETE_CAMPAIGN_SERVICE_REQUEST = "DELETE_CAMPAIGN_SERVICE_REQUEST"
export const GET_CAMPAIGN_DESCRIPTION_REQUEST = "GET_CAMPAIGN_DESCRIPTION_REQUEST"
export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST'
export const GET_CAMPAIGN_OBSERVATION_LIST_REQUEST = 'GET_CAMPAIGN_OBSERVATION_LIST_REQUEST'
export const GET_CAMPAIGN_DDL_REQUEST = 'GET_CAMPAIGN_DDL_REQUEST'
export const GET_CAMPAIGN_SERVICE_DDL_REQUEST = 'GET_CAMPAIGN_SERVICE_DDL_REQUEST'
export const GET_OBSERVATION_TASK_VM_REQUEST = "GET_OBSERVATION_TASK_VM_REQUEST"
export const GET_CAMPAIGN_OSHA_PDF_REQUEST = 'GET_CAMPAIGN_OSHA_PDF_REQUEST'
export const GET_CONTACT_CAMPAIGN_LIST_REQUEST = 'GET_CONTACT_CAMPAIGN_LIST_REQUEST'
export const CREATE_CAMPAIGN_CHECKLIST_REQUEST = 'CREATE_CAMPAIGN_CHECKLIST_REQUEST'
export const UPDATE_CAMPAIGN_CHECKLIST_REQUEST = 'UPDATE_CAMPAIGN_CHECKLIST_REQUEST'
export const GET_CAMPAIGN_CHECKLIST_DETAIL_REQUEST = 'GET_CAMPAIGN_CHECKLIST_DETAIL_REQUEST'
export const GET_CAMPAIGN_WORKFLOW_VM = 'GET_CAMPAIGN_WORKFLOW_VM'
export const GET_CAMPAIGN_WORKFLOWS_REQUEST = 'GET_CAMPAIGN_WORKFLOWS_REQUEST'
export const CLONE_CAMPAIGN_REQUEST = 'CLONE_CAMPAIGN_REQUEST'
export const GET_CAMPAIGN_TAGS_DDL_REQUEST = 'GET_CAMPAIGN_TAGS_DDL_REQUEST'
export const UPDATE_CHECKLIST_REQUIRED_ACTIVITY_REQUEST = 'UPDATE_CHECKLIST_REQUIRED_ACTIVITY_REQUEST'
export const GET_CAMPAIGN_REPORT_LIST_REQUEST = 'GET_CAMPAIGN_REPORT_LIST_REQUEST'
export const UPDATE_CHECKLIST_COPY_ALLOWED_ACTIVITY_REQUEST = 'UPDATE_CHECKLIST_COPY_ALLOWED_ACTIVITY_REQUEST'
export const GET_SHARED_CAMPAIGN_UNLINKED_SITE_LIST_REQUEST = 'GET_SHARED_CAMPAIGN_UNLINKED_SITE_LIST_REQUEST'
export const ADD_SITES_TO_SHARED_CAMPAIGN_REQUEST = 'ADD_SITES_TO_SHARED_CAMPAIGN_REQUEST'
export const GET_SHARED_CAMPAIGN_SITE_LIST_REQUEST = 'GET_SHARED_CAMPAIGN_SITE_LIST_REQUEST'
export const GET_CAMPAIGN_MISSING_SITE_ROLES_REQUEST = 'GET_CAMPAIGN_MISSING_SITE_ROLES_REQUEST'
export const GET_SITE_DDL_BY_CAMPAIGN_ID_REQUEST = 'GET_SITE_DDL_BY_CAMPAIGN_ID_REQUEST'
export const GET_ORGANIZATION_DDL_BY_CAMPAIGN_ID_REQUEST = 'GET_ORGANIZATION_DDL_BY_CAMPAIGN_ID_REQUEST'
export const DELETE_SHARED_CAMPAIGN_SITE_REQUEST = 'DELETE_SHARED_CAMPAIGN_SITE_REQUEST'
export const GET_SHARED_CAMPAIGN_UNLINKED_SITE_ORGANIZATION_DDL_LIST_REQUEST = 'GET_SHARED_CAMPAIGN_UNLINKED_SITE_ORGANIZATION_DDL_LIST_REQUEST'
export const UPDATE_CAMPAIGN_STATUS_REQUEST = 'UPDATE_CAMPAIGN_STATUS_REQUEST'

/****************************************************/
/** GET_CAMPAIGN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignListRequest : To get campaign list
 *
 */
export const getCampaignListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_DETAIL_VM_REQUEST **/
/****************************************************/

/**
 * @method getCampaignDetailVM : To get campaign detail VM
 *
 */
export const getCampaignDetailVMRequest = (callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_DETAIL_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_CAMPAIGN_DETAILS **/
/****************************************************/

/**
 * @method addCampaignDetailRequest :  To add details of campaign
 *
 */
export const addCampaignDetailRequest = (payload, callback) => {
  return {
    type: ADD_CAMPAIGN_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_DETAIL **/
/****************************************************/

/**
 * @method getCampaignDetailRequest : To get campaign detail
 *
 */
export const getCampaignDetailRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_BASIC_DETAIL **/
/****************************************************/

/**
 * @method getCampaignBasicDetail : To get campaign basic detail
 *
 */
export const getCampaignBasicDetailRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_BASIC_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_DETAILS **/
/****************************************************/

/**
 * @method updateCampaignDetailRequest :  To update detail of campaign
 *
 */
export const updateCampaignDetailRequest = (payload, callback) => {
  return {
    type: UPDATE_CAMPAIGN_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SERVICE_DETAIL_VM_REQUEST **/
/****************************************************/

/**
 * @method getServiceDetailVM : To get service detail VM
 *
 */
export const getServiceDetailVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SERVICE_DETAIL_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_CAMPAIGN_SERVICE_DETAIL **/
/****************************************************/

/**
 * @method addCampaignServiceDetailRequest :  To add detail of campaign service
 *
 */
export const addCampaignServiceDetailRequest = (payload, callback) => {
  return {
    type: ADD_CAMPAIGN_SERVICE_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_SERVICE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignServiceListRequest :  To get service list of campaign
 *
 */
export const getCampaignServiceListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_SERVICE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_SERVICE_CHECKLIST_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignServiceChecklistRequest :  To get service checklist list of campaign
 *
 */
export const getCampaignServiceChecklistRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_SERVICE_CHECKLIST_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_LIST_DDL **/
/****************************************************/

/**
 * @method getProjectListDDLRequest : To get project list DDL
 *
 */
export const getProjectListDDLRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_LIST_DDL,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_SERVICE_REQUEST **/
/****************************************************/

/**
 * @method deleteCampaignServiceRequest :  To delete campaign service
 *
 */
export const deleteCampaignServiceRequest = (payload, callback) => {
  return {
    type: DELETE_CAMPAIGN_SERVICE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_REQUEST **/
/****************************************************/

/**
 * @method deleteCampaignRequest :  To delete campaign
 *
 */
export const deleteCampaignRequest = (payload, callback) => {
  return {
    type: DELETE_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_DESCRIPTION **/
/****************************************************/

/**
 * @method getCampaignDescriptionRequest :  To get campaign description request
 *
 */
export const getCampaignDescriptionRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_DESCRIPTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCampaignDDLRequest : To Get Paginated campaign DDL
 *
 */
export const getCampaignDDLRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_SERVICE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCampaignServiceDDLRequest : To Get Paginated campaign service DDL
 *
 */
export const getCampaignServiceDDLRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_SERVICE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_OBSERVATION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignObservationListRequest :  To get campaign observation request
 *
 */
export const getCampaignObservationListRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_OBSERVATION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_VM_REQUEST **/
/****************************************************/

/**
 * @method getObservationTaskVMRequest : To get program list VM
 *
 */
export const getObservationTaskVMRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_TASK_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_OSHA_PDF_REQUEST **/
/****************************************************/

/**
 * @method getCampaignOshaPDFRequest :  To download campaign Osha PDF
 *
 */
export const getCampaignOshaPDFRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_OSHA_PDF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_CAMPAIGN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getContactCampaignListRequest :  To Get Contact Campaign List
 *
 */
export const getContactCampaignListRequest = (payload, callback) => {
  return {
    type: GET_CONTACT_CAMPAIGN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_CAMPAIGN_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method createCampaignChecklistRequest :  To add checklist to campaign
 *
 */
export const createCampaignChecklistRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_CAMPAIGN_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_CHECKLIST_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getCampaignChecklistDetailRequest : To get campaign checkist detail 
 *
 */
export const getCampaignChecklistDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_CHECKLIST_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method updateCampaignChecklistRequest :  To update campaign checkist details
 *
 */
export const updateCampaignChecklistRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_CAMPAIGN_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_WORKFLOW_VM **/
/****************************************************/

/**
 * @method getCampaignWorkflowVM :  To get Campaign Workflow VM
 *
 */
export const getCampaignWorkflowVMRequest = (callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_WORKFLOW_VM,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_WORKFLOWS_REQUEST **/
/****************************************************/

/**
 * @method getCampaignWorkflows :  To get workflows list with name, id search and without any search
 *
 */
export const getCampaignWorkflowsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_WORKFLOWS_REQUEST,
    payload,
    callback
  }
}

/**
 * @method cloneCampaignRequest :  To clone a campaign
 *
 */
export const cloneCampaignRequest = (payload, callback = () => { }) => {
  return {
    type: CLONE_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_TAGS_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCampaignTagsDDLRequest : To Get Paginated campaign tags DDL
 *
 */
export const getCampaignTagsDDLRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_TAGS_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CHECKLIST_REQUIRED_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method updateCheckListRequiredActivityRequest :  To update check list required activity
 *
 */
export const updateCheckListRequiredActivityRequest = (payload, callback) => {
  return {
    type: UPDATE_CHECKLIST_REQUIRED_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_REPORT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignReportListRequest :  To get the campaign report list
 *
 */
export const getCampaignReportListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_REPORT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CHECKLIST_COPY_ALLOWED_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method updateChecklistCopyAllowedActivityRequest :  To update check list allow copy activity
 *
 */
export const updateChecklistCopyAllowedActivityRequest = (payload, callback) => {
  return {
    type: UPDATE_CHECKLIST_COPY_ALLOWED_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SHARED_CAMPAIGN_UNLINKED_SITE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getSharedCampaignUnLinkedSiteListRequest :  To get the shared campaign unlinked site list
 *
 */
export const getSharedCampaignUnLinkedSiteListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SHARED_CAMPAIGN_UNLINKED_SITE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_SITES_TO_SHARED_CAMPAIGN_REQUEST **/
/****************************************************/

/**
 * @method addSitesToSharedCampaignRequest :  To add sites to shared campaign
 */
export const addSitesToSharedCampaignRequest = (payload, callback) => {
  return {
    type: ADD_SITES_TO_SHARED_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SHARED_CAMPAIGN_SITE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getSharedCampaignSiteListRequest :  To get the shared campaign site list
 *
 */
export const getSharedCampaignSiteListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SHARED_CAMPAIGN_SITE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_MISSING_SITE_ROLES_REQUEST **/
/****************************************************/

/**
 * @method getCampaignMissingSiteRolesRequest :  To get the campaign missing site roles
 *
 */
export const getCampaignMissingSiteRolesRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_MISSING_SITE_ROLES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_DDL_BY_CAMPAIGN_ID_REQUEST **/
/****************************************************/

/**
 * @method getSiteDDLByCampaignIdRequest :  To get the site ddl by campaignId
 *
 */
export const getSiteDDLByCampaignIdRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SITE_DDL_BY_CAMPAIGN_ID_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_DDL_BY_CAMPAIGN_ID_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationDDLByCampaignIdRequest : To get the organization ddl by campaignId
 *
 */
export const getOrganizationDDLByCampaignIdRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_DDL_BY_CAMPAIGN_ID_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_SHARED_CAMPAIGN_SITE_REQUEST **/
/****************************************************/

/**
 * @method deleteSharedCampaignSiteRequest :  To delete shared campaign site
 *
 */
export const deleteSharedCampaignSiteRequest = (payload, callback) => {
  return {
    type: DELETE_SHARED_CAMPAIGN_SITE_REQUEST,
    payload,
    callback
  }
}

/*********************************************************************/
/** GET_SHARED_CAMPAIGN_UNLINKED_SITE_ORGANIZATION_DDL_LIST_REQUEST **/
/*********************************************************************/

/**
 * @method getSharedCampaignUnLinkedSiteOrganizationDDLListRequest : To get the shared campaign unlinked site organization ddl by campaignId
 *
 */
export const getSharedCampaignUnLinkedSiteOrganizationDDLListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SHARED_CAMPAIGN_UNLINKED_SITE_ORGANIZATION_DDL_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_STATUS_REQUEST **/
/****************************************************/

/**
 * @method updateCampaignStatusRequest :  To update campaign status
 *
 */
export const updateCampaignStatusRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_CAMPAIGN_STATUS_REQUEST,
    payload,
    callback
  }
}
