import { LANGUAGE_TYPE } from '@shared/constants'
import { ENGLISH_TAB_LANGUAGE_CONSTANTS, SPANISH_TAB_LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getUniqueUuid, validateUuid } from '@utils'

import { RESPONSE_CHOICE_TYPES } from './choice-response'

const getOptionList = (list = [], savedConditions) => {
  return list.map(text => {
    const savedUniqueId = savedConditions.find(cond => cond.text === text)?.value || ''
    return { text, value: validateUuid(savedUniqueId) ? savedUniqueId : text }
  })
}

const getCalcualtionGotoOptions = [
  {
    text: "Pass",
    value: "True"
  },
  {
    text: "Fail",
    value: "False"
  }
]

export const getConditions = (
  options = [],
  responseTypeId = '',
  savedConditions = [],
  activityResponseCustomType = []
) => {
  const cond = String(responseTypeId || '')
  if (!cond) return []

  switch (cond) {
    case RESPONSE_CHOICE_TYPES.BOOLEAN:
    case RESPONSE_CHOICE_TYPES.SINGLE:
    case RESPONSE_CHOICE_TYPES.MULTI:
    case RESPONSE_CHOICE_TYPES.DDL_SINGLE:
    case RESPONSE_CHOICE_TYPES.DDL_MULTI:
    case RESPONSE_CHOICE_TYPES.QA_STANDARD:
    case RESPONSE_CHOICE_TYPES.BEAZER_QA_STANDARD:
    case RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITH_BV:
    case RESPONSE_CHOICE_TYPES.ESTAR_STANDARD_WITHOUT_BV:
    case RESPONSE_CHOICE_TYPES.PASS_FAIL:
    case RESPONSE_CHOICE_TYPES.BUTTON:
      return options.map(op => ({ text: op.options, value: op.questionId }))

    case RESPONSE_CHOICE_TYPES.DDL_CUSTOM_SINGLE_SELECT:
      return options.map(op => {
        const text = activityResponseCustomType.find(x => x.value === op.options)?.text || op.options
        return { text, value: op.questionId }
      })
    
    case RESPONSE_CHOICE_TYPES.CALCULATION:
      return [...getCalcualtionGotoOptions]

    case RESPONSE_CHOICE_TYPES.DATE:
      return getOptionList([
        "Primary is before",
        "Primary is equal to",
        "Primary is after"
      ], savedConditions)

    case RESPONSE_CHOICE_TYPES.FREE_TEXT:
    case RESPONSE_CHOICE_TYPES.PHOTO:
    case RESPONSE_CHOICE_TYPES.SIGNATURE:
    case RESPONSE_CHOICE_TYPES.FILE:
    case RESPONSE_CHOICE_TYPES.SCAN:
      return getOptionList(["Primary response is answered"], savedConditions)

    case RESPONSE_CHOICE_TYPES.NUMERIC:
      return getOptionList([
        "Primary is greater than",
        "Primary is greater than or equal to",
        "Primary is equal to",
        'Primary is less than or equal to',
        'Primary is less than'
      ], savedConditions)
  }
}

export const addEnglishLanguageToOther = (list) => {
  const englishVersionValue = list.find(lan => lan.language === LANGUAGE_TYPE.ENGLISH) || {}
  return list.map(lan => ({
    ...lan,
    languageValue: lan.languageValue || englishVersionValue.languageValue || "",
    description: lan.description || englishVersionValue.description || ""
  }))
}

export const setOtherLanguage = (response) => {
  const updatedResponse = Object.assign({}, response)
  const { responseName, checkpointResponseQuestions } = updatedResponse
  updatedResponse.responseName = addEnglishLanguageToOther(responseName)
  if (checkpointResponseQuestions?.length) {
    updatedResponse.checkpointResponseQuestions = checkpointResponseQuestions.map(
      question => ({
        ...question,
        options: addEnglishLanguageToOther(question.options)
      })
    )
  }
  return updatedResponse
}

/**
 * @method addQuestionIdToOptions
 * @param {object} question : Question for whose options need, unique id need to be added 
 * @param {bool} isConditionalResForMultiSelectView : If `question` is conditional response item and this conditional response item is added using multi-selection of condiion value, then its true. 
 * 
 * @returns {object} updated `question` with unique id for checkpointResponseQuestions options
 */
export const addQuestionIdToOptions = (question, isConditionalResForMultiSelectView) => {
  if (question.checkpointResponseQuestions?.length) {
    question.checkpointResponseQuestions.forEach((ques, index) => {
      if (isConditionalResForMultiSelectView || !ques.questionId) {
        question.checkpointResponseQuestions[index] = { ...ques, questionId: getUniqueUuid() }
      }
    })
  }

  return question
}

export const getGivenTextLanguageList = (text = '') => {
  const list = Object.values(LANGUAGE_TYPE)
  if (typeof text === 'object') {
    return list.map(lanCode => ({ language: lanCode, languageValue: text[lanCode] || '', description: "" }))
  } else {
    return list.map(lanCode => ({ language: lanCode, languageValue: text, description: "" }))
  }
}

export const getSpecificLanguage = (list, value, config = {}) => {
  if (config.isRequiresAllDetails) {
    return list.find(lan => lan.language === value) || {}
  }
  return list.find(lan => lan.language === value)?.languageValue || ''
}

export const setSpecificLanguage = ({ isDescriptionUpdate = false, list = [], value = '', activeLocaleTab = 0 }) => {
  const newList = list.map(lan => {
    const { language, languageValue, description = "" } = lan
    const obj = { language, languageValue: !isDescriptionUpdate && language === activeLocaleTab ? value : languageValue, description: isDescriptionUpdate && language === activeLocaleTab ? value : description}
    return obj
  })
  return newList
}

const language = {
  [LANGUAGE_TYPE.ENGLISH]: ENGLISH_TAB_LANGUAGE_CONSTANTS,
  [LANGUAGE_TYPE.SPANISH]: SPANISH_TAB_LANGUAGE_CONSTANTS
}

export const getLanguageText = (
  lanCode = LANGUAGE_TYPE.ENGLISH,
  key
) => {
  const code = parseInt(lanCode)
  const lanText = language[code][key]
  if (code === LANGUAGE_TYPE.ENGLISH || !ENGLISH_TAB_LANGUAGE_CONSTANTS[key]) {
    return lanText
  } else {
    return `${lanText} (${ENGLISH_TAB_LANGUAGE_CONSTANTS[key]})`
  }
}

export const DEFAULT_VALUE_INFO_TYPES = ["<fs>UnitCity</fs>", "<fs>UnitStreetAddress</fs>", "<fs>UnitZipCode</fs>", "<fs>UnitState</fs>", "<fs>SiteAddress</fs>", "<fs>UnitName</fs>", "<fs>TaskName</fs>", "<fs>WorkFlowName</fs>", "<fs>OrganizationName</fs>", "<fs>PlanName</fs>", "<fs>SiteName</fs>", "<fs>CampaignName</fs>", "<fs>ConditionedBasementArea</fs>", "<fs>ContractorIsFactoryChargeRemovedFirst</fs>", "<fs>ContractorIsGeoPhotosCollected</fs>", "<fs>ContractorLengthOfLiquidLineFactorySupp</fs>", "<fs>ContractorTotalLengthOfLiquidLine</fs>", "<fs>ContractorTotalWeightOfRefrigerant</fs>", "<fs>ContractorWeightOfFactorySuppRefrigerant</fs>", "<fs>ContractorWeightOfRefrigerantAddSpecComp</fs>", "<fs>DuctLeakageIsDocCollected</fs>", "<fs>Elevation</fs>", "<fs>FirstFloorArea</fs>", "<fs>HVACDesignBlowerFanAirflow</fs>", "<fs>HVACDesignCoolingBlowerFanAirflow</fs>", "<fs>HVACMaximumTotalHeatGainl</fs>", "<fs>JobNumber</fs>", "<fs>MechanicalVentilationAirflowRate</fs>", "<fs>MechanicalVentilationAirflowRuntime</fs>", "<fs>NoOfBathrooms</fs>", "<fs>NoOfBedrooms</fs>", "<fs>NoOfStories</fs>", "<fs>PermitDate</fs>", "<fs>PermitNumber</fs>", "<fs>Phase</fs>", "<fs>PhaseStartDate</fs>", "<fs>RefrigerantChargeIsGeoPhotoProvided</fs>", "<fs>SecondFloorArea</fs>", "<fs>ShellArea</fs>", "<fs>ThirdFloorArea</fs>", "<fs>TotalConditionSpace</fs>", "<fs>UnconditionedBasementArea</fs>", "<fs>UnitContractorOutsideDiameterLiquidLineId</fs>", "<fs>UnitContractorOutsideDiameterLiquidLineName</fs>", "<fs>UnitContractorRefrigerantId</fs>", "<fs>UnitContractorRefrigerantName</fs>", "<fs>UnitDwellingForcedAirModeId</fs>", "<fs>UnitSystemTestingEquipmentId</fs>", "<fs>Volume</fs>", "<fs>WallHeight</fs>", "<fs>EquipmentRatedSEER</fs>", "<fs>EnterMaximumTotalHeatGain</fs>", "<fs>EnterMeteringDevice</fs>", "<fs>TargetSubcooling</fs>", "<fs>QDesignHeatingCFM</fs>", "<fs>QDesignCoolingCFM</fs>"]
export const RESULT_VALUE_INFO_TYPES = ["ConditionedBasementArea", "ContractorIsFactoryChargeRemovedFirst", "ContractorIsGeoPhotosCollected", "ContractorLengthOfLiquidLineFactorySupp", "ContractorTotalLengthOfLiquidLine", "ContractorTotalWeightOfRefrigerant", "ContractorWeightOfFactorySuppRefrigerant", "ContractorWeightOfRefrigerantAddSpecComp", "DuctLeakageIsDocCollected", "Elevation", "FirstFloorArea", "HVACDesignBlowerFanAirflow", "HVACDesignCoolingBlowerFanAirflow", "HVACMaximumTotalHeatGainl", "JobNumber", "MechanicalVentilationAirflowRate", "MechanicalVentilationAirflowRuntime", "NoOfBathrooms", "NoOfBedrooms", "NoOfStories", "PermitDate", "PermitNumber", "Phase", "PhaseStartDate", "RefrigerantChargeIsGeoPhotoProvided", "SecondFloorArea", "ShellArea", "ThirdFloorArea", "TotalConditionSpace", "UnconditionedBasementArea", "UnitContractorOutsideDiameterLiquidLineId", "UnitContractorOutsideDiameterLiquidLineName", "UnitContractorRefrigerantId", "UnitContractorRefrigerantName", "UnitDwellingForcedAirModeId", "UnitSystemTestingEquipmentId", "Volume", "WallHeight", "EquipmentRatedSEER", "EnterMaximumTotalHeatGain", "EnterMeteringDevice", "TargetSubcooling", "QDesignHeatingCFM", "QDesignCoolingCFM"]

export const ALL_RESPONSE_GOTO_ANSWER = [{ text: "All Response", value: "All Response"}]
