import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { components } from 'react-select'

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getFormattedDate, getLocaleMessage } from '@utils'
import { CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { CONTRACT_VERSION } = LANGUAGE_CONSTANTS.OVERVIEW_PAGE_SECTIONS.CONTRACTS

const ContractVersionFormattedLabel = ({
    intl, option
}) => {
    if (!option.contractVersionId) {
        return '— Select —'
    }

    return (
        <span key={`option-${option.contractVersionId}`}>
        <strong>{getLocaleMessage(intl, CONTRACT_VERSION.VERSION)} {option.contractVersionId}:</strong> {getFormattedDate(option.effectiveStartDate)} {option.effectiveEndDate ? ` - ${getFormattedDate(option.effectiveEndDate)}` : ""}
    </span>
    )
}

const ContractVersionFormattedSelectedValue = ({
    intl,
    children,
    ...props
}) => {
  const { data = {} } = props || {}
  return (
    <components.SingleValue {...props}> 
        <span>
            <strong>{getLocaleMessage(intl, CONTRACT_VERSION.VERSION)} {data.contractVersionId}:</strong> {getFormattedDate(data.effectiveStartDate)} {data.effectiveEndDate ? ` - ${getFormattedDate(data.effectiveEndDate)}` : ""}
        </span>
    </components.SingleValue>
)
}

const SingleValue = localeMessageWrapper(ContractVersionFormattedSelectedValue)

const ContractVersionDropdown = ({
    intl,
    id = "",
    name = "",
    value = "",
    updatedVersionValue = null,
    campaignId = "",
    isDisabled = false,
    isRequired = false,
    isShowSelectedAtTop = false,
    isResetOptions = false,
    title = CONTRACT_VERSION.SELECT_VERSION,
    onSelect = () => { }
}) => {
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const [isResetCache, setResetCache] = useState(false)
    const [selectedCVersionData, setSelectedCVersionData] = useState(null)

    useEffect(() => {
        const fn = () => {
            setTimeout(() => {
                setResetCache((prevState) => !prevState)
                setOptions([])
                if (!!updatedVersionValue) {
                    setSelectedCVersionData(updatedVersionValue)
                }
            }, [100])
        }
        fn()
    }, [updatedVersionValue, campaignId])

    useEffect(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
    }, [isResetOptions])

    const handleLoadMore = useCallback((search, page, prevOptions) => {
        return new Promise((resolve) => {

            const handleLoadMoreCallback = (response) => {
                if (response) {
                    const { items, hasNextPage } = response
                    setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
                    resolve({
                        optionList: items,
                        hasMore: hasNextPage
                    })
                } else {
                    resolve({
                        optionList: [],
                        hasMore: false
                    })
                }
            }

            const payload = {
                pageNumber: page || 1,
                pageSize: DEFAULT_VM_PAGE_SIZE,
                campaignId,
                campaignVersionName: search
            }
            dispatch(actions.getCampaignContractVersionsListDDLRequest(payload, handleLoadMoreCallback))
        })
    }, [campaignId])

    const handleSelect = useCallback(
        (selected) => {
                onSelect(id, selected?.contractVersionId || "", selected?.isLatestVersion || false)
                setSelectedCVersionData(selected)
        },
        [onSelect, id]
    )

    return (
        <>
            <CustomLabel title={getLocaleMessage(intl, title)} required={isRequired} />
            <CustomReactSelect
                additional={{
                    page: 1
                }}
                isDisabled={isDisabled}
                cacheUniqs={[isResetCache]}
                id={id}
                name={name}
                placeholder={getLocaleMessage(intl, CONTRACT_VERSION.SELECT)}
                options={options}
                defaultOptions={options}
                formattedOption={(option) => (<ContractVersionFormattedLabel intl={intl} option={option} />)}
                isFormattedValue
                value={!!value ? selectedCVersionData : null}
                isMulti={false}
                isShowCustomAction={false}
                customComponents={{ SingleValue }}
                isShowSelectedAtTop={isShowSelectedAtTop}
                onLoadOptions={handleLoadMore}
                getOptionValue={op => op.contractVersionId}
                onSelect={handleSelect}
            />
        </>
    )
}

export default localeMessageWrapper(ContractVersionDropdown)
