import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkConfirmSiteVisitAction
 * This api is called to confirm site visit for scheduling workflow
 * [Confirm Site Visit]
 * 
 * @param {object} data 
 * 
 * {
 *  observationTaskIds: string,
 *  isConfirmSiteVisit: false,
 *  reScheduleDate: string,
 *  actionNotReadyResponseId: number,
 *  note: string
 * }
 * 
 */
export const updateBulkConfirmSiteVisitAction = async (data) => {
  const { url } = config.updateBulkConfirmSiteVisitAction
  return await putDataApi(url, data)
}

export default updateBulkConfirmSiteVisitAction
