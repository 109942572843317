import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { mySite, myReports, myRequests, myInspections, myActions } from "@src/assets/images/new-theme"

const {
  BUILDER_HOME
} = LANGUAGE_CONSTANTS

export const SECTION_TAB_TYPES = {
  MY_SITES: 1,
  MY_INSPECTIONS: 2,
  MY_ACTIONS: 3,
  MY_REQUESTS: 4,
  MY_REPORTS: 5
}

export const FILTER_SECTION_KEYS = {
  MY_SITES: "mySites",
  MY_INSPECTIONS: "myInspections",
  MY_ACTIONS: "myActions",
  MY_REQUESTS: "myRequests",
  MY_REPORTS: "myReports",
  MY_SITES_UNITS: "mySitesUnits"
}

export const getPermissionBasedTabList = ({
  hasMySitesPermission,
  hasMyInspectionsPermission,
  hasMyRequestPermission,
  hasMyActionsPermission,
  hasMyReportsPermission
}) => {
  const builderHomeTabs = []
  if (hasMySitesPermission) {
    builderHomeTabs.push({ id: SECTION_TAB_TYPES.MY_SITES, name: BUILDER_HOME.MY_SITES, icon: mySite, key: 'sites' })
}
if (hasMyInspectionsPermission) {
  builderHomeTabs.push({ id: SECTION_TAB_TYPES.MY_INSPECTIONS, name: BUILDER_HOME.MY_INSPECTIONS, icon: myInspections, key: 'inspections' })
}
if (hasMyRequestPermission) {
  builderHomeTabs.push({ id: SECTION_TAB_TYPES.MY_REQUESTS, name: BUILDER_HOME.MY_REQUESTS, icon: myRequests, key: 'requests' })
}
if (hasMyActionsPermission) {
  builderHomeTabs.push({ id: SECTION_TAB_TYPES.MY_ACTIONS, name: BUILDER_HOME.MY_ACTIONS, icon: myActions, key: 'actions' })
}
// if (hasMyReportsPermission) {
//   builderHomeTabs.push({ id: SECTION_TAB_TYPES.MY_REPORTS, name: BUILDER_HOME.MY_REPORTS, icon: myReports, key: 'reports' })
// }
  return [...builderHomeTabs]
}

export const MY_REQUESTS_TAB_TYPES = {
  RECENT: 1,
  TODAY: 2,
  FUTURE: 3,
  REPORTS: 4,
  FEES: 5
}

export const MY_REQUESTS_TABS = [
  { id: MY_REQUESTS_TAB_TYPES.RECENT, name: BUILDER_HOME.TABS.MY_REQUESTS.RECENT },
  { id: MY_REQUESTS_TAB_TYPES.TODAY, name: BUILDER_HOME.TABS.MY_REQUESTS.TODAY },
  { id: MY_REQUESTS_TAB_TYPES.FUTURE, name: BUILDER_HOME.TABS.MY_REQUESTS.FUTURE }
  // { id: MY_REQUESTS_TAB_TYPES.REPORTS, name: BUILDER_HOME.TABS.MY_REQUESTS.REPORTS } //TODO:
]

export const MY_SITES_INSPECTION_TABS = [
  { id: MY_REQUESTS_TAB_TYPES.RECENT, name: BUILDER_HOME.TABS.MY_REQUESTS.RECENT },
  { id: MY_REQUESTS_TAB_TYPES.TODAY, name: BUILDER_HOME.TABS.MY_REQUESTS.TODAY },
  { id: MY_REQUESTS_TAB_TYPES.FUTURE, name: BUILDER_HOME.TABS.MY_REQUESTS.FUTURE },
  { id: MY_REQUESTS_TAB_TYPES.FEES, name: BUILDER_HOME.TABS.MY_REQUESTS.FEES }
  // { id: MY_REQUESTS_TAB_TYPES.REPORTS, name: BUILDER_HOME.TABS.MY_REQUESTS.REPORTS } //TODO:
]

export const BUILDER_TASK_LIST_PAGE = {
  MY_SITES: 1,
  MY_INSPECTIONS: 2,
  MY_REQUESTS: 3
}

export const CANCEL_REQUEST_STATUS = 1

export const TASK_STATUS_GROUP_NAME = {
  REQUESTED: "Requested",
  SCHEDULED: "Scheduled",
  PENDING: "Pending",
  PASSED: "Passed",
  FAILED: "Failed",
  CANCELED: "Canceled"
}

export const DEFAULT_STATUS_ALL = [
  {
    text: "All",
    value: "-1"
  }
]