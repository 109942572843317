import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { CAMAPIGN_CONTRACT_SAGA } = LANGUAGE_CONSTANTS

/** getCampaignContractList API **/
function* getCampaignContractListRequest(action) {
  try {
    const response = yield call(services.getCampaignContractList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** addCampaignContractDetail API  **/
function* addCampaignContractDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addCampaignContractDetail, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: CAMAPIGN_CONTRACT_SAGA.ADDED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMAPIGN_CONTRACT_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCampaignContractDetail API **/
function* getCampaignContractDetailRequest(action) {
  try {
    const response = yield call(services.getCampaignContractDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateCampaignContractDetail API  **/
function* updateCampaignContractDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateCampaignContractDetail, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMAPIGN_CONTRACT_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMAPIGN_CONTRACT_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}
/** getCampaignContractProductList API **/
function* getCampaignContractProductListRequest(action) {
  try {
    const response = yield call(services.getCampaignContractProductList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCampaignContractVM API **/
function* getCampaignContractVMRequest(action) {
  try {
    const response = yield call(services.getCampaignContractVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteCampaignContract API **/
function* deleteCampaignContractRequest(action) {
  try {
    const { isClearProduct } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCampaignContract, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: isClearProduct ? CAMAPIGN_CONTRACT_SAGA.PRODUCT_DETAIL_CLEARED_SUCCESS : CAMAPIGN_CONTRACT_SAGA.DELETED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: isClearProduct ? CAMAPIGN_CONTRACT_SAGA.ERROR_IN_CLEARING_PRODUCT_DETAIL : CAMAPIGN_CONTRACT_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCampaignContractVersionDetails API **/
function* getCampaignContractVersionDetailsRequest(action) {
  try {
    const response = yield call(services.getCampaignContractVersionDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCampaignContractVersionsListDDL API **/
function* getCampaignContractVersionsListDDLRequest(action) {
  try {
    const response = yield call(services.getCampaignContractVersionsListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteCampaignContractVersion API **/
function* deleteCampaignContractVersionRequest(action) {
  try {
    const { isClearProduct } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCampaignContractVersion, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMAPIGN_CONTRACT_SAGA.DELETED_CONTRACT_VERSION_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMAPIGN_CONTRACT_SAGA.ERROR_IN_DELETING_CONTRACT_VERSION })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** createCampaignContractVersion API  **/
function* createCampaignContractVersionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createCampaignContractVersion, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: CAMAPIGN_CONTRACT_SAGA.ADDED_CONTRACT_VERSION_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMAPIGN_CONTRACT_SAGA.ERROR_IN_ADDING_CONTRACT_VERSION })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateCampaignContractVersion API  **/
function* updateCampaignContractVersionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateCampaignContractVersion, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMAPIGN_CONTRACT_SAGA.UPDATED_CONTRACT_VERSION_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMAPIGN_CONTRACT_SAGA.ERROR_IN_UPDATING_CONTRACT_VERSION })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

export function* campaignContractSagas() {
  yield takeEvery(actions.GET_CAMPAIGN_CONTRACT_LIST_REQUEST, getCampaignContractListRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CONTRACT_PRODUCT_LIST_REQUEST, getCampaignContractProductListRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CONTRACT_VM_REQUEST, getCampaignContractVMRequest)
  yield takeLatest(actions.ADD_CAMPAIGN_CONTRACT_DETAIL_REQUEST, addCampaignContractDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CONTRACT_DETAIL_REQUEST, getCampaignContractDetailRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_CONTRACT_DETAIL_REQUEST, updateCampaignContractDetailRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_CONTRACT_REQUEST, deleteCampaignContractRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CONTRACT_VERSION_DETAILS_REQUEST, getCampaignContractVersionDetailsRequest)
  yield takeEvery(actions.GET_CAMPAIGN_CONTRACT_VERSION_LIST_DDL_REQUEST, getCampaignContractVersionsListDDLRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_CONTRACT_VERSION_REQUEST, deleteCampaignContractVersionRequest)
  yield takeLatest(actions.CREATE_CAMPAIGN_CONTRACT_VERSION_REQUEST, createCampaignContractVersionRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_CONTRACT_VERSION_REQUEST, updateCampaignContractVersionRequest)
}
export default campaignContractSagas