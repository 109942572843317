import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import { PROJECT_DETAIL_FORM_TYPES } from "./config"
import localeMessageWrapper from "../locale-message"

const { PROJECT_SEARCH } = LANGUAGE_CONSTANTS

const ProjectSearch = ({
  intl,
  asyncInitialValue,
  isResetProjectDetail = false,
  defaultOptions,
  errors,
  id,
  organizationId,
  initialLabel,
  isEditMode,
  isUpdateEditModeValue,
  isMulti,
  isRequired,
  isShowCustomAction,
  customActionLabel,
  label,
  siteId,
  labelRequired,
  name,
  placeholder,
  value,
  isDisabled = false,
  onOpenAddForm = () => { },
  onResetUpdateEditModeValue = () => { },
  onSelect = () => { }
}) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState(defaultOptions || [])

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)

  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    if (isResetProjectDetail) {
      setResetCache(prevState => !prevState)
    }
  }, [isResetProjectDetail])

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
   */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )
  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode without initialLabel
    if (isEditMode && !isMulti && !initialLabel) {
      if (!isEditModeItemLoaded && !!value) {
        setEditModeItemLoaded(true)
        dispatch(
          actions.getProjectDetailsRequest(
            {
              projectId: value,
              isLoadingRequired: false
            },
            (result) => {
              if (result) {
                const selectedItem = {
                  text: result.projectName,
                  value: result.projectId.toString()
                }
                setSelectedOptionLabel(selectedItem.text || "")
                setResetCache((prevState) => !prevState)
                handleSetDefaultOptions(selectedItem, value)
              }
            }
          )
        )
      }
    }
  }, [initialLabel, isEditMode, isEditModeItemLoaded, value])

  useEffect(() => {
    if (
      !isEditModeItemLoaded &&
      isEditMode &&
      asyncInitialValue &&
      !!value &&
      !!initialLabel
    ) {
      const selectedItem = {
        text: initialLabel,
        value: value.toString()
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache((prevState) => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isUpdateEditModeValue && !!initialLabel && !!value) {
      onResetUpdateEditModeValue(false)
      setSelectedOptionLabel(initialLabel)
      if (!isEditMode) {
        const selectedItem = {
          text: initialLabel,
          value: value.toString()
        }
        setResetCache((prevState) => !prevState)
        handleSetDefaultOptions(selectedItem, value)
      }
    }
  }, [initialLabel, isUpdateEditModeValue, value])

  const handleSelectProject = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [id, onSelect]
  )

  const handleLoadMore = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const params = {
          projectName: search,
          organizationId,
          siteId,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }
        dispatch(
          actions.getProjectListDDLRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response

              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [options, organizationId, siteId]
  )

  const handleCustomAction = useCallback((event) => {
    event.preventDefault()
    onOpenAddForm({
      type: PROJECT_DETAIL_FORM_TYPES.ADD_PROJECT_FORM,
      config: { organizationId }
    })
  }, [organizationId, onOpenAddForm])

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  return (
    <>
      {labelRequired && <CustomLabel title={label || PROJECT_SEARCH.LABEL} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        customActionLabel={customActionLabel || getLocaleMessage(intl, PROJECT_SEARCH.PLUS_ADD_PROJECT)}
        additional={{
          page: 1
        }}
        isShowCustomAction={isShowCustomAction}
        cacheUniqs={[isResetCache]}
        isMulti={isMulti}
        id={id}
        isDisabled={isDisabled}
        name={name}
        options={options}
        hideSelectedOptions={false}
        defaultOptions={options}
        placeholder={placeholder || getLocaleMessage(intl, PROJECT_SEARCH.LABEL)}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        {...tablePropsConfig}
        onSelect={handleSelectProject}
        onLoadOptions={handleLoadMore}
        onClickCustomAction={handleCustomAction}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </>
  )
}

ProjectSearch.propTypes = {
  intl: PropTypes.object,
  asyncInitialValue: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  isUpdateEditModeValue: PropTypes.bool,
  defaultOptions: PropTypes.array,
  errors: PropTypes.object,
  customActionLabel: PropTypes.string,
  organizationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  id: PropTypes.string,
  initialLabel: PropTypes.string,
  isEditMode: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onOpenAddForm: PropTypes.func,
  onSelect: PropTypes.func,
  onResetUpdateEditModeValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ])
}

ProjectSearch.defaultProps = {
  asyncInitialValue: false,
  isShowCustomAction: false,
  organizationId: 0,
  siteId: "",
  defaultOptions: [],
  id: "projectIds",
  initialLabel: "",
  isEditMode: false,
  isMulti: true,
  isUpdateEditModeValue: false,
  isRequired: true,
  labelRequired: true,
  name: "project_ids"
}

export default localeMessageWrapper(ProjectSearch)
