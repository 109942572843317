import jwtDecode from "jwt-decode"
import moment from 'moment'
import { Badge } from 'reactstrap'

import AppSettings from '@app-settings'
import { ROUTES, SEARCH_HISTORY_LIST_PAGE_KEYS, INITIAL_LIST_STATE, BADGE_COLORS } from "@shared/constants"

const { REACT_APP_ENTITY_FEATURE_URL, REACT_APP_CLAIM_ROLES, REACT_APP_FORESITE_TECH_URL } = AppSettings

export const getCompleteAddress = (value, setAddressName) => {
  let countryCode = ''
  let countryName = ''
  let state = ''
  let stateName = ''
  let city = ''
  let postCode = ''
  let county = ''
  let streetAddress = ''
  let latitude = ''
  let longitude = ''
  if (value && value.address_components) {
    for (const addressComp of value?.address_components) {
      if (addressComp.types.includes('country')) {
        countryCode = addressComp.short_name
        countryName = addressComp.long_name
      }
      if (addressComp.types.includes('administrative_area_level_1')) {
        state = addressComp.short_name
        stateName = addressComp.long_name
      }
      if (addressComp.types.includes('locality')) {
        city = addressComp.short_name
      }
      if (addressComp.types.includes('postal_code')) {
        postCode = addressComp.short_name
      }
      if (addressComp.types.includes('administrative_area_level_2')) {
        const countyName = addressComp.long_name ? addressComp.long_name?.replace('County', '')?.trim() : ''
        county = countyName
      }
    }

    streetAddress = value.name

    if (!streetAddress) {
      streetAddress = value.updatedStreetAddress
    }
    if (setAddressName) {
      setAddressName(streetAddress)
    }
    latitude = value.geometry.location.lat()?.toFixed(2)
    longitude = value.geometry.location.lng()?.toFixed(2)
  } else {
    // if the user presses enter
    streetAddress = value.updatedStreetAddress
  }
  return { streetAddress, countryCode, countryName, stateName, state, city, postCode, county, latitude, longitude }
}

export const getDateTimeFormat = (date) => {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : ''
}

/**
 * @method getReactSelectPositionProps
 * Note: Use these props in following cases:-
 *  1). If react select dropdown is not visible properly, 
 *      such as overflow hidden due to table scroll or other parent container,
 *      These props will display react-select menu component above parent view, to show full menu list.
 *   
 *  2). If click on menu is not opening react-select instantly, 
 *      but causes scroll in window when we try to open dropdown,
 *      For fixing this issue so that single click will open dropdown, use menuPosition = 'fixed' property.
 */
export const getReactSelectPositionProps = (params) => {
  const {
    isMenuPostionFixedRequired = true
  } = params || {}

  const positionProps = {
    menuPortalTarget: document.body,
    styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
  }

  if (isMenuPostionFixedRequired) {
    positionProps.menuPosition = 'fixed'
  }

  return positionProps
}

export const getEntityFeatureFromToken = (accessToken) => {
  const decodedAccessToken = jwtDecode(accessToken)
  if (decodedAccessToken) {
    return decodedAccessToken[REACT_APP_ENTITY_FEATURE_URL] || ''
  }
  return ''
}

export const getEntityDisplayNameFromToken = (accessToken) => {
  try {
    if (!accessToken) {
      return ''
    }
    const decodedAccessToken = jwtDecode(accessToken)
    if (decodedAccessToken) {
      return decodedAccessToken[`${REACT_APP_FORESITE_TECH_URL}/EntityDisplayName`] || ''
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const getClaimRolesFromToken = (accessToken) => {
  const decodedAccessToken = jwtDecode(accessToken)
  if (decodedAccessToken) {
    return decodedAccessToken[REACT_APP_CLAIM_ROLES] || ''
  }
  return ''
}

export const getUserTimezoneBasedDateTime = (date) => {
  if (!date) {
    return ""
  }
  // Parse the given date string in UTC timezone
  const utcDateTime = moment.utc(date, "YYYY-MM-DDTHH:mm:ss.SSS")
  const userProfileDateTime = utcDateTime
  // Format the date and time in a readable format
  return userProfileDateTime.format("YYYY-MM-DD h:mmA")
}

/**
 * @method getInitialSearchState : To get initail search state for list page based on page search history and initial search details if no search history
 * @param {object} { initialSearchDetail, searchHistory } 
 * 
 * @returns search form's state
 */
export const getInitialSearchState = ({ initialSearchDetail, searchHistory = {}, overviewFilter = {} }) => {
  if (!!overviewFilter?.search) {
    const searchInfo = {
      ...initialSearchDetail,
      ...overviewFilter.search
    }
    return searchInfo
  } else if (!!searchHistory?.advanceSearch) {
    const searchInfo = searchHistory.advanceSearch
    const dateKeys = new Set(['startDate', 'endDate', 'scheduleDateFrom', 'scheduleDateTo', 'dueDateFrom', 'dueDateTo'])
    Object.entries(searchInfo).forEach(([k, value]) => {
      if (dateKeys.has(k)) {
        searchInfo[k] = value ? new Date(value) : ""
      }
    })
    return searchInfo
  } else {
    return Object.assign({}, initialSearchDetail)
  }
}

/**
 * @method getSearchHistoryKey : To get search history key for list page based on page route
 * @param {object} history
 * 
 * @returns search history
 */
export const getSearchHistoryKey = (history) => {
  if (!history) {
    return undefined
  }

  const pathName = history.location.pathname

  if (pathName === ROUTES.reportsOverview) {
    return SEARCH_HISTORY_LIST_PAGE_KEYS.REPORTS
  }

  return undefined
}

export const getInitialListState = (config) => {
  const { pageSize, dataKey } = config || {}
  const initialListState = Object.assign({}, INITIAL_LIST_STATE)

  if (dataKey === 'items') {
    initialListState[dataKey] = []
    delete initialListState.list
  }

  if (pageSize) {
    initialListState.pageSize = pageSize
  }

  return initialListState
}

export const checkIfContactSearchFiltersApplied = ({
  firstName,
  lastName,
  sitePartyRole,
  sitePartyRoleName,
  organization,
  organizationName,
  site,
  siteName,
  email,
  phone
}) => {
  return (
    !!firstName ||
    !!lastName ||
    !!sitePartyRole ||
    !!sitePartyRoleName ||
    !!organization ||
    !!organizationName ||
    !!site ||
    !!siteName ||
    !!email ||
    !!phone
  )
}

export const getFilterTableColumnConfig = ({ gridData, selectedFilter }) => {
  const columnList = gridData.props.columnDefs || []
  const columnState = gridData.columnApi?.getColumnState() || []

  const defaultColumnConfig = []
  const appliedColumnConfig = []

  columnList.forEach((col, index) => {
    const { colId, field } = col
    const column = columnState.find(c => c.colId === colId)

    if (column) {
      const config = {
        ...column,
        colName: col.name || "",
        sort: null,
        sortIndex: null,
        pinned: null
      }

      defaultColumnConfig.push({ ...config, hide: col.hide })
      if (selectedFilter) {
        appliedColumnConfig.push(config)
      }
    } else {
      const config = {
        aggFunc: null,
        colId: (field === "actions") ? field : (field === "" && index === 0) ? "0" : colId,
        colName: col.name || "",
        flex: null,
        hide: col.hide,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 60
      }
      defaultColumnConfig.push(config)

      if (selectedFilter) {
        appliedColumnConfig.push(config)
      }
    }
  })

  const reorderedAppliedColumnConfig = []
  if (appliedColumnConfig.length) {
    const appliedColumnState = columnState.filter(c => c.colId !== "ag-Grid-AutoColumn")
    appliedColumnState.forEach(({ colId }, index) => {
      const column = appliedColumnConfig.find(col => col.colId === colId)
      reorderedAppliedColumnConfig[index] = column
    })
  }

  return {
    defaultTableColumnConfig: defaultColumnConfig,
    appliedTableColumnConfig: reorderedAppliedColumnConfig
  }
}

export const getTagBadgeComponent = (value) => {
  const colorsLength = BADGE_COLORS.length
  let codeIndex = -1
  return (
    value?.map((x, index) => {
      codeIndex = codeIndex < colorsLength ? codeIndex + 1 : 0
      return (
        <span className="tag-list" key={`tag_${index}`}>
          <Badge tag="div" color={BADGE_COLORS[codeIndex]} pill>
            {x.tagName}
          </Badge>
        </span>
      )
    }) || ""
  )
}

export const openReportInNewTab = (payload) => {
  window.open(`${ROUTES.windwardReportProgressDetails}?data=${encodeURIComponent(JSON.stringify(payload))}`, '_blank')
}

export const getParsedJSONDetails = ({
  customJSONData,
  isShowJSONView
}) => {
  if (isShowJSONView) {
    try {
      if (!customJSONData.length) {
        return {}
      }
      const parsedJSONData = JSON.parse(customJSONData)
      const isObjectFormat = (typeof parsedJSONData === 'object')
      if (isObjectFormat) {
        return { parsedJSONData }
      }
    } catch (error) {
      return { isInvalidJSONData: true }
    }
  }

  return {}
}

export const updateFilterParamsInSearchHistoryRef = ({
  canApplyFilters,
  isResetSortOrder,
  orderColumn,
  orderDirections,
  sortOrderColumn,
  sortOrderDirection,
  size,
  page,
  pageSize,
  pageNumber,
  searchHistoryRef
}) => {
  const sortValues = isResetSortOrder ? {
    sDirection: orderDirections,
    sColumn: orderColumn
  } : {
    sDirection: sortOrderDirection,
    sColumn: sortOrderColumn
  }

  const updatedSortValues = canApplyFilters ? sortValues : ''

  searchHistoryRef.current.sortOrderDirection = updatedSortValues.sDirection
  searchHistoryRef.current.sortOrderColumn = updatedSortValues.sColumn

  const pageSizeValue = canApplyFilters ? !!size ? size : pageSize : ''
  searchHistoryRef.current.pageSize = pageSizeValue

  const pageNumberValue = canApplyFilters ? !!page ? page : pageNumber : ''
  searchHistoryRef.current.pageNumber = pageNumberValue
}

export const updateSearchQueryInURL = ({ queryParamsDetail, history }) => {
  // Extract existing query parameters
  const currentParams = new URLSearchParams(history.location.search)

  const queryParams = Object.entries(queryParamsDetail)
    .filter(([key, value]) => {
      if (value === undefined) {
        return false // Exclude undefined values
      }
      if (Array.isArray(value)) {
        return value.length > 0
      } else if (value instanceof Date) {
        return true
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length > 0
      } else {
        return value !== null && value !== ''
      }
    })
    .map(([key, value]) => {
      if (value instanceof Date) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toISOString())}`
      } else if (typeof value === 'object' && value !== null) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      }
    })
    .join('&')

  // Replace only the query parameters that are in queryParamsDetail
  Object.keys(queryParamsDetail).forEach(key => {
    currentParams.delete(key)
  })

  // Append the new query parameters
  const newParams = currentParams.toString()
    ? `${currentParams.toString()}&${queryParams}`
    : queryParams

  // Update the URL with the merged query parameters
  history.replace(`${history.location.pathname}?${newParams}`, { ...(history.location.state || {}) })

}

export const getFilterParamsFromSearchQueryInURL = (details, queryParams) => {
  const searchData = { ...details }
  const otherConfig = {}
  for (const key of Object.keys(details)) {
    if (queryParams.has(key)) {
      let value = queryParams.get(key)
      if (value && (value.startsWith("{") || value.startsWith("["))) {
        try {
          value = JSON.parse(decodeURIComponent(value))
        } catch (e) {
          console.error(`Failed to parse ${key}:`, e)
          continue
        }
      } else if (value instanceof Date && value && !isNaN(Date.parse(value))) {
        value = JSON.parse(decodeURIComponent(value))
      }
      searchData[key] = value
    }
  }
  for (const [key, value] of queryParams.entries()) {
    if (!Object.keys(details).includes(key)) {
      otherConfig[key] = value
    }
  }
  return { searchData, otherConfig }
}

export const useFilterParamsFromSearchQueryInURLForGridPage = ({
  queryParams,
  searchDetail = {},
  initialListState = {},
  initialFetchParameters = {},
  onSetGenericSearch = () => { },
  onSetSortOrderColumn = () => { },
  onSetSortOrderDirection = () => { },
  onUpdateGridSpecificFilterInState = () => { },
  onUpdateTabSpecificFilterInState = () => { },
  onUpdateAdvanceSearchForSchedulingConfig = () => { },
  onUpdateListState = () => { },
  onSetSearchDetail = () => { },
  onFetchList = () => { }
}) => {
  if (queryParams.toString() && Array.from(queryParams).length > 0) {
    const { searchData, otherConfig } = getFilterParamsFromSearchQueryInURL(searchDetail, queryParams)
    const genericSearchUpdate = {}
    const sortOrderUpdate = {}
    let otherStateUpdates = {}
    const { qS, sOrderC, sOrderD, pageSize, page } = otherConfig || {}

    if (qS) {
      onSetGenericSearch(qS)
      genericSearchUpdate.search = qS
      genericSearchUpdate.isGSFilter = true
    }

    if (sOrderC && sOrderD) {
      onSetSortOrderColumn(sOrderC || '')
      onSetSortOrderDirection(sOrderD || '')
      sortOrderUpdate.orderColumn = sOrderC
      sortOrderUpdate.orderDirections = sOrderD
      sortOrderUpdate.isResetSortOrder = true
    }

    //For grid page specific filter updates
    const value = onUpdateGridSpecificFilterInState(otherConfig)
    if (value) {
      otherStateUpdates = value
    }

    //For tab page specific filter updates
    onUpdateTabSpecificFilterInState(otherConfig)

    onUpdateAdvanceSearchForSchedulingConfig(otherConfig)

    if (pageSize || page) {
      onUpdateListState(() => (
        Object.assign({}, {
          ...initialListState,
          page,
          pageSize
        }
        )))
    }

    const dateKeys = new Set(['startDate', 'endDate', 'scheduleDateFrom', 'scheduleDateTo', 'dueDateFrom', 'dueDateTo', 'requestedStartDate', 'requestedEndDate', 'scheduledStartDate', 'scheduledEndDate', 'completedStartDate', 'completedEndDate'])
    Object.entries(searchData).forEach(([k, value]) => {
      if (dateKeys.has(k)) {
        searchData[k] = value ? new Date(value) : ""
      }
    })

    onSetSearchDetail(searchData) //For advance search filter state
    onFetchList({
      page: page || 1,
      size: pageSize,
      isAutoAdvanceSearch: true,
      advanceSearchDetail: { ...searchData },
      isInitialFetch: true,
      ...genericSearchUpdate,
      ...sortOrderUpdate,
      ...otherStateUpdates
    })
  } else {
    onFetchList({ page: 1, isInitialFetch: true, ...initialFetchParameters })
  }
}

export const formatAndUpdateSearchParamsInURL = ({ filters = {}, filterConfig = {}, advancedFilters = {}, history }) => {
  const qS = filters.genericSearch || null,
    advanceSearch = filters.advanceSearch || null,
    sOrderC = filters.sortOrderColumn || null,
    sOrderD = filters.sortOrderDirection || null,
    pageSize = filters.pageSize || null,
    page = filters.pageNumber || null

  const queryParamsDetail = Object.assign({}, { page, pageSize, qS, sOrderC, sOrderD, ...advancedFilters, ...advanceSearch, ...filterConfig })
  updateSearchQueryInURL({ queryParamsDetail, history })
}

export const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase()
  switch (extension) {
    case 'pdf': return 'application/pdf'
    case 'jpg': case 'jpeg': return 'image/jpeg'
    case 'png': return 'image/png'
    case 'gif': return 'image/gif'
    case 'txt': return 'text/plain'
    case 'html': return 'text/html'
    case 'doc': return 'application/msword'
    case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'xls': return 'application/vnd.ms-excel'
    case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    default: return 'application/octet-stream'
  }
}

/**
 * @method checkIfLoggedInWithRequiredEntity : To check if user is logged in for specific entity or not
 * @param {Object}  
 * {
   userData = {}, //login details object

   entityTypeId = 0 //Entity id to check for
 * }
 * @returns bool
 */
export const checkIfLoggedInWithRequiredEntity = ({
  userData = {},
  entityTypeId = 0
}) => {
  return userData[`${REACT_APP_FORESITE_TECH_URL}/EntityId`] === entityTypeId
}

/**
 * @method getActivitiesListRowDragText
 */
export const getActivitiesListRowDragText = (params = {}) => {
  const { data = {} } = params.rowNode || {}
  return data.checkPointName || data.checkpointName || ""
}

/**
 * @method navigateToBackPage To go back to previous page in router history
 */
export const navigateToBackPage = ({ history }) => {
  if (history.location?.state && history.location.state.hasNoBackHistory) {
    history.replace(ROUTES.home)
  } else {
    history.goBack()
  }
}

export const getOverviewCreatedByContactInfoComponent = (value) => {
  const createdDate = value.createdOn || value.insertedDate
  return <span>{value.createdBy || value.insertedByName || 'N/A'} {createdDate ? moment(createdDate).format('MM/DD/YYYY hh:mm A') : ''}</span>
}

export const getOverviewUpdatedByContactInfoComponent = (value) => {
  return <span>{value.updatedByName || 'N/A'} {value.updatedDate ? moment(value.updatedDate).format('MM/DD/YYYY hh:mm A') : ''}</span>
}

export const handleNavigateOverviewLink = (routeType, routeUrl) => {
  if (routeType === "parentOrganization") window.location.href = routeUrl
}

export const getOverviewNavigationLink = (value, data, routeType) => {
  let route
  if (routeType === "parentOrganization") {
    route = `${ROUTES.overview}/${data.parentOrganizationId}`
  } else if (routeType === "organization") {
    route = `${ROUTES.overview}/${data.organizationId}`
  } else if (routeType === "program") {
    route = `${ROUTES.programsOverview}/${data.programId}`
  } else if (routeType === "sites") {
    route = `${ROUTES.sitesOverview}/${data.siteId}`
  } else if (routeType === "masterCampaigns") {
    route = `${ROUTES.campaignOverview}/${data.campaignMasterId}`
  }
  return <a className="text-underline" href={route} onClick={() => handleNavigateOverviewLink(routeType, route)}>{value}</a>
}

export const setFilterBasedColumns = ({
  columnConfig,
  defaultTableColumnConfig,
  searchHistoryRef,
  onUpdateColumns = () => { }
}) => {
  if (columnConfig?.length) {
    const copyColumnConfig = JSON.parse(columnConfig)
    const reorderedColumns = []

    // Step 1: Add column with colId '0' to the beginning (always visible)
    defaultTableColumnConfig.forEach(item => {
      const colId = item.colId.trim()

      if (colId === '0') {
        reorderedColumns.unshift({ ...item, hide: false })
      }
    })

    // Step 2: Add visible columns
    copyColumnConfig.forEach(colId => {
      const matchingColumn = defaultTableColumnConfig.find(item => item.colId.trim() === colId)

      if (matchingColumn) {
        reorderedColumns.push({
          ...matchingColumn,
          hide: false
        })
      }
    })

    // Step 3: Add remaining hidden columns
    defaultTableColumnConfig.forEach(item => {
      const colId = item.colId.trim()
      if (colId !== '0' && !copyColumnConfig.includes(colId)) {
        reorderedColumns.push({
          ...item,
          hide: true
        })
      }
    })

    searchHistoryRef.current.columns = reorderedColumns
    onUpdateColumns(reorderedColumns)
  }
}
