import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  FormGroup
} from "reactstrap"

import { SCHEDULING_TASK_STATUS_TYPES, SCHEDULING_TASK_STATUS } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import {
  SideModalCloseButton,
  DatePicker,
  CustomLabel,
  ErrorMessage
} from "@views/components"
import { validateCloseTaskDetails } from "./helpers"

const { SUBMIT, CANCEL, OVERVIEW_PAGE_SECTIONS: { TASKS_SECTION: { CLOSED_DATE, CLOSE_TASKS } } } = LANGUAGE_CONSTANTS

const CloseTaskManuallyModal = ({
  intl,
  data,
  onToggleModal,
  onUpdateRowData,
  isAdminTasks
}) => {
  const dispatch = useDispatch()
  const [closedDate, setClosedDate] = useState('')
  const [errors, setErrors] = useState({})

  const handleToggleModal = useCallback(() => {
    onToggleModal()
  }, [onToggleModal])

  const handleUpdateTaskStatus = useCallback(
    (res) => {
      if (res) {
        const updatedRowData = {
          taskStatusId: SCHEDULING_TASK_STATUS_TYPES.CLOSED.toString()
        }
        if (isAdminTasks) {
          updatedRowData['taskStatus'] = SCHEDULING_TASK_STATUS.CLOSED
        } else {
          updatedRowData['status'] = SCHEDULING_TASK_STATUS.CLOSED
        }

        onUpdateRowData({
          observationTaskId: data.observationTaskId,
          updatedValue: updatedRowData
        })
      }

    }, [data.observationTaskId, onUpdateRowData, isAdminTasks])

  const handleSubmit = useCallback(() => {
    const error = validateCloseTaskDetails(closedDate)
    if (!!Object.keys(error).length) {
      setErrors(error)
      return
    }

    if (data.observationTaskId) {
      dispatch(actions.updateCloseTaskManuallyRequest({ observationTaskIds: String(data.observationTaskId), closedDate }, (res) => {
        if (res) {
          handleToggleModal()
          handleUpdateTaskStatus(res)
        }
      }))
    }
  }, [closedDate, data.observationTaskId, handleToggleModal])

  const handleChangeDetails = useCallback((value) => {
    if (!!Object.keys(errors).length) {
      setErrors({})
    }
    setClosedDate(value)
  }, [errors])

  return (
    <Modal
      isOpen
      className={"sidebar-sm"}
      modalClassName={"modal-slide-in note-media-modal schedule-time-modal remove-hold-modal"}
      contentClassName="p-0"
    >
      <ModalHeader
        className="mb-2"
        toggle={onToggleModal}
        close={<SideModalCloseButton onClose={onToggleModal} />}
        tag="div"
      >
        <h5 className="modal-title">
          {getLocaleMessage(intl, CLOSE_TASKS)}
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1">
        <Row>
          <Col className='mb-1' sm={12} md={12} xl={12}>
            <FormGroup>
              <CustomLabel title={CLOSED_DATE} required />
              <div className="closed-date">
                <DatePicker
                  id="closedDate"
                  placeholder={getLocaleMessage(intl, CLOSED_DATE)}
                  value={closedDate}
                  onChange={(value) => handleChangeDetails(value)}
                  isClearable={!!closedDate}
                />
              </div>
            </FormGroup>

            <ErrorMessage
              isShow={!!errors["closedDate"]}
              message={errors["closedDate"]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Button
              outline
              onClick={handleToggleModal}
              className={"delete-button secondary-outlined w-100"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
          <Col md={6}>
            <Button
              className={"primary-solid w-100"}
              color="primary"
              onClick={handleSubmit}
            >
              {getLocaleMessage(intl, SUBMIT)}
            </Button>

          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default localeMessageWrapper(CloseTaskManuallyModal)
